import React, { useEffect, useState } from 'react' 
import { Table } from 'antd';
import axios from 'axios';
import { useFetcher } from 'react-router-dom';

function ActiveCoin() {
   const [viewdata , setViewdata] = useState([])

   const getCoindata = () => {
    const token = localStorage.getItem('token')
    const response = axios({
      method : 'POST',
      headers : {
        "Content-Type": "application/json" 
      },
      url : process.env.REACT_APP_API + 'view_all_coins',
      data : { jwt : token}
    }).then(res => {
      setViewdata(res?.data?.Success)
    }).catch(err => {
    })
   }

   useEffect(() => {
    getCoindata()
   },[])

  const dataSource = []
  if(viewdata.length > 0){
    for(let i = 0; i < viewdata.length; i++){
      dataSource.push({
        title : viewdata[i]?.Title,
        exchange_Type : viewdata[i]?.Exchange_Type,
        exchange : viewdata[i]?.Exchange,
        status : viewdata[i]?.Status,
        symbol : viewdata[i]?.Symbol,
      })
    }
  }
  const columns = [
    {
      title : "Title",
      dataIndex : "title",
      key : 'title'
    },
    {
      title : "Exchange Type",
      dataIndex : "exchange_Type",
      key : 'exchange_Type'
    },
    {
      title : "Exchange",
      dataIndex : "exchange",
      key : 'exchange'
    },
    {
      title : "Status",
      dataIndex : "status",
      key : 'status',
      render : (text, record) => {
          const textColor = record.status === 'ACTIVE' ? 'green' : 'red';
          return( <span style={{ color: textColor }}>{text}</span>)
      }
    },
    {
      title : "Symbol",
      dataIndex : "symbol",
      key : 'symbol'
    },
  ]

  return (
    <div className=' mt-5'>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  )
}

export default ActiveCoin;