import { Switch, Table, message, DatePicker, Input, Select, Button } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
const { RangePicker } = DatePicker;
const { Search } = Input;

const Alluser = () => {
  const [viewuserdata, setViewuserdata] = useState([]);


  const ViewUser = () => {
    const token = localStorage.getItem('token')

    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'view_all_users',
      data: { jwt: token }
    }).then(res => {
      setViewuserdata(res?.data?.Success.User)
    }).catch(err => {
    })
  };

  useEffect(() => {
    ViewUser()
  }, [])

  const dataSource = []

  if (viewuserdata.length > 0) {
    for (let i = 0; i < viewuserdata.length; i++) {
      dataSource.push({
        username: viewuserdata[i]?.Username,
        name: viewuserdata[i]?.Name,
        email: viewuserdata[i]?.Email,
        wallet_Balance: viewuserdata[i]?.Wallet_Balance,
        wallet_Deposit: viewuserdata[i]?.Wallet_Deposit,
        email_Verification: viewuserdata[i]?.Email_Verification,
        subscription_Name: viewuserdata[i]?.Subscription,
        totp_Activation: viewuserdata[i]?.TOTP_Activation,
        telegramID: viewuserdata[i]?.TelegramID,
      })
    }
  }

  const reverseDataSource = [...dataSource].reverse()

  const columns = [
    {
      title: <p className='py-2 px-1 text-center rounded-lg table-cell'>Username</p>,
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      // render : (text) => {
      //   return(<span className='shadow-neumorphicnew'>{text}</span>)
      // }
      className: 'custom-header-class',
      width: 200
    },
    {
      title: <p className='py-2 px-1 text-center rounded-lg table-cell'>Name</p>,
      dataIndex: 'name',
      key: 'name',
      width: 200
    },
    {
      title: <p className='py-2 px-1 text-center rounded-lg table-cell'>Email</p>,
      dataIndex: 'email',
      key: 'email',
      width: 300
    },
    {
      title: <p className='py-2 px-1 text-center rounded-lg table-cell'>Wallet Balance</p>,
      dataIndex: 'wallet_Balance',
      key: 'wallet_Balance',
      width: 200
    },
    {
      title: <p className='py-2 px-1 text-center rounded-lg table-cell'>Wallet Deposit</p>,
      dataIndex: 'wallet_Deposit',
      key: 'wallet_Deposit',
      width: 200
    },
    {
      title: <p className='py-2 px-1 text-center rounded-lg table-cell'>Email status</p>,
      dataIndex: 'email_Verification',
      key: 'email_Verification',
      render: (text) => {
        const displayText = text === true ? "Active" : "Inactive";
        return <span className={`${text ? 'text-green-500 ' : 'text-red-500'} uppercase`}>{displayText}</span>;
      },
      width: 200
    },
    {
      title: <p className='py-2 px-1 text-center rounded-lg table-cell'>Package Name</p>,
      dataIndex: 'subscription_Name',
      key: 'subscription_Name',
      width: 200
    },
    {
      title: < p className='py-2 px-1 text-center rounded-lg table-cell' > Email status</p>,
      dataIndex: 'totp_Activation',
      key: 'totp_Activation',
      render: (text) => {
        const displayText = text === true ? "Active" : "Inactive";
        return <span className={`${text ? 'text-green-500 ' : 'text-red-500'} uppercase`}>{displayText}</span>;
      },
      width: 200
    },
    // {
    //   title: 'Action',
    //   render: (record) => {
    //     return <span className={` bg-green-600 text-white px-4 py-1 rounded-xl uppercase cursor-pointer`} onClick={() => getData(record)}>Change status</span>;
    //   },
    //   width: 300
    // },
    {
      title: <p className='py-2 px-1 text-center rounded-lg table-cell'>Email status</p>,
      render: (tableRecord) => {

        const emailVerificationStatus = tableRecord?.email_Verification;
        return (
          <span
            onClick={() => getData(tableRecord)}
            className=' px-4 pb-2 pt-1  '>
            <Switch checked={emailVerificationStatus} className='top-left-shadow bg-red-300' onChange={onChange} />
          </span>
        );
      },
      width: 200
    },
  ];

  const getData = (data) => {
    const token = localStorage.getItem('token')
    const status = data?.email_Verification
    const postData = {
      jwt: token,
      username: data?.username,
      work: 'Email',
      action: !status,
    }

    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'status_change',
      data: postData
    }).then((res) => {
      ViewUser()
      message.success(res?.data?.Success)
    }).catch(err => {
      message.error(err?.response?.data?.Error)
    })
  }


  const paginationConfig = {
    pageSize: 10,
    total: dataSource.length,
    showSizeChanger: false,
  };

  const onChange = (pagination, filters, sorter, extra) => {
  };

  const RanPickeronChange = (value, dateString) => {
};
const onSearch = (value, _e, info) => {};
const onOk = (value) => {
}

  return (
    <div>
      {/* <p className=' text-xl mb-4 font-semibold  ml-2'>All Users</p> */}
      <div className='rounded-xl mx-auto'>
        <div className='flex justify-between flex-wrap items-center gap-5'>
          <RangePicker
            className='my-2 text-center rounded-lg inner_shadow'
            showTime={{
              format: 'HH:mm',
            }}
            format="YYYY-MM-DD HH:mm"
            onChange={RanPickeronChange}
            onOk={onOk}
          />
          <div>
            <Search
              className='my-2 text-center rounded-lg w-full md:w-[500px] inner_shadow'
              placeholder="input search text"
              onSearch={onSearch}
              // style={{
              //   width: 500,
              // }}
            />
          </div>
          <div className='flex justify-between items-center gap-4'>
            <div className='px-10 py-1 hover:scale-105 transition-all transition-5s cursor-pointer text-center rounded-lg outer_shadow'> Export Csv</div>
          </div>
        </div>
        <Table dataSource={reverseDataSource} columns={columns} pagination={paginationConfig} onChange={onChange}
         scroll={{
          x: 1500,
        }}
        />
      </div>
    </div>
  )
}

export default Alluser
