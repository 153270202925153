import React from 'react'

const DeletedUser = () => {
  return (
    <div className='text-2xl w-[96%] mx-auto'>
       Comming soon...
    </div>
  )
}

export default DeletedUser
