import React, { useState } from 'react'
import { Button, Form, Input, Upload, message } from 'antd'
import axios from 'axios'
import { UploadOutlined } from '@ant-design/icons';


const Addpackage = () => {
    const [fileIds, setFileIds] = useState([]);
    const [form] = Form.useForm();

    const createUser = (data) => {
        const token = localStorage.getItem('token')

        const postData = {
            ...data,
            jwt: token,
            validity: 'year'
        }

        const response = axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + "add_packages",
            // url: "http://192.168.0.247:8000/admin/add_packages",
            data: postData
        }).then(res => {
            message.success(res?.data?.Success)
        })
            .catch(err => {
                message.error(err?.response?.data?.Error)
            })
    }

    const handleUpload = async (file, price) => {
        const token = localStorage.getItem('token')
        // const price = form.getFieldValue('price');
        const formData = new FormData();
        formData.append('jwt', token);
        formData.append('price', price);
        formData.append('image', file);

        try {
            const response = await fetch("http://192.168.0.247:8000/admin/upload_image", {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setFileIds(data);
                message.success("File uploaded successfully");
            } else {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }
        } catch (error) {
            console.error(error);
            message.error(error.message || "File Not Found");
        }
    };

    const uploadProps = {
        beforeUpload: (file) => {
            const price = form.getFieldValue('price');
            handleUpload(file, price);
            return false;
        },
    };



    return (
        <div>
            <div>
                <Form
                    className="mt-4 m-auto grid grid-cols-3 gap-x-8 px-1"
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off"
                    onFinish={createUser}
                >
                    {/* <div className=' col-span-3 sm:col-span-1'>
                        <p className='  text-base  ml-2 mb-3 font-semibold '>Images Upload</p>
                        <Form.Item
                            name="filed"
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input name",
                                    required: true
                                },
                            ]}
                        >
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </div> */}
                    <div className=' col-span-3 sm:col-span-1'>
                        <p className='  text-base  ml-2 mb-3 font-semibold '>Name</p>
                        <Form.Item
                            name="name"
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input name",
                                    required: true
                                },
                            ]}
                        >
                            <Input
                                placeholder="Name"
                                className='focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500  rounded-xl py-3 pl-5 text-sm'
                            />
                        </Form.Item>
                    </div>
                    <div className=' col-span-3 sm:col-span-1'>
                        <p className='text-base  ml-2 mb-3 font-semibold'>price</p>
                        <Form.Item
                            name='price'
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input password",
                                    required: true
                                },
                            ]}
                        >
                            <Input
                                placeholder="Price"
                                className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-base'
                                onKeyPress={e => {
                                    const keyCode = e.which || e.keyCode;
                                    const isValidKey = /^[0-9]+$/.test(String.fromCharCode(keyCode));
                                    if (!isValidKey) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className=' col-span-3 sm:col-span-1'>
                        <p className='text-base  ml-2 mb-3 font-semibold'>Live Api</p>
                        <Form.Item
                            name='live_api'
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input Live Api",
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="Live Api" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-base'
                                onKeyPress={e => {
                                    const keyCode = e.which || e.keyCode;
                                    const isValidKey = /^[0-9]+$/.test(String.fromCharCode(keyCode));
                                    if (!isValidKey) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className=' col-span-3 sm:col-span-1'>
                        <p className='text-base  ml-2 mb-3 font-semibold'>Paper Api</p>
                        <Form.Item
                            name='paper_api'
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input Paper Api",
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="Paper Api" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-base'
                                onKeyPress={e => {
                                    const keyCode = e.which || e.keyCode;
                                    const isValidKey = /^[0-9]+$/.test(String.fromCharCode(keyCode));
                                    if (!isValidKey) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className=' col-span-3 sm:col-span-1'>
                        <p className='text-base  ml-2 mb-3 font-semibold'>Exchange</p>
                        <Form.Item
                            name='exchange'
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input Exchange",
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="Exchange" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-base'
                                onKeyPress={e => {
                                    const keyCode = e.which || e.keyCode;
                                    const isValidKey = /^[0-9]+$/.test(String.fromCharCode(keyCode));
                                    if (!isValidKey) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className=' col-span-3 sm:col-span-1'>
                        <p className='text-base  ml-2 mb-3 font-semibold'>live Bots</p>
                        <Form.Item
                            name='live_bots'
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input live Bots",
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="live Bots" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-base' />
                        </Form.Item>
                    </div>
                    <div className=' col-span-3 sm:col-span-1'>
                        <p className='text-base  ml-2 mb-3 font-semibold'>Paper Bots</p>
                        <Form.Item
                            name='paper_bots'
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input paper bots",
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="Paper Bots" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-base'
                                onKeyPress={e => {
                                    const keyCode = e.which || e.keyCode;
                                    const isValidKey = /^[0-9]+$/.test(String.fromCharCode(keyCode));
                                    if (!isValidKey) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className=' col-span-3 sm:col-span-1'>
                        <p className='text-base  ml-2 mb-3 font-semibold'>Profit Per</p>
                        <Form.Item
                            name='profit_per'
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input profit per",
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="profit per" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-base'
                                onKeyPress={e => {
                                    const keyCode = e.which || e.keyCode;
                                    const isValidKey = /^[0-9]+$/.test(String.fromCharCode(keyCode));
                                    if (!isValidKey) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className=' col-span-3 sm:col-span-1'>
                        <p className='text-base  ml-2 mb-3 font-semibold'>Max Fund Allotted</p>
                        <Form.Item
                            name='max_fund_allotted'
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input max fund allotted",
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="Max Fund Allotted" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-base'
                                onKeyPress={e => {
                                    const keyCode = e.which || e.keyCode;
                                    const isValidKey = /^[0-9]+$/.test(String.fromCharCode(keyCode));
                                    if (!isValidKey) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    {/* <div className=' col-span-3 sm:col-span-1'>
                        <p className='text-base  ml-2 mb-3 font-semibold'>validity</p>
                        <Form.Item
                            name='validity'
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input validity",
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="validity" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-base' />
                        </Form.Item>
                    </div> */}

                    <div className=' col-span-3'>
                        <Form.Item className=" flex justify-center m-0">
                            <button type="primary" htmlType="submit" className=" bg-[#76c3c5] top-left-shadow text-lg py-3 px-16 rounded-lg mx-9 my-7 text-white block">
                                Submit
                            </button>
                        </Form.Item>
                    </div>
                </Form>
                <Form
                    form={form}
                    className="mt-4 m-auto grid grid-cols-3 gap-x-8"
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off"
                    // onFinish={handleUpload}
                    onFinish={(values) => {
                        createUser(values);
                        // handleUpload(values.fileIds);
                    }}
                >
                    {/* <div className=' col-span-3 sm:col-span-1'>
                        <p className='  text-base  ml-2 mb-3 font-semibold '>Images Upload</p>
                        <Form.Item
                            name="fileIds"
                            labelAlign="left"
                            rules={[
                                {
                                    message: "Please input name",
                                    required: true
                                },
                            ]}
                        >
                            <Upload  {...uploadProps}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </div> */}
                </Form>
            </div>
        </div>
    )
}

export default Addpackage
