import { Button, Drawer, Switch, Table, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Input } from 'antd';
import { Select } from 'antd';
const { Search } = Input;
const { Option } = Select;

function CopyBottablecopy({ username }) {
  const [subdata, setSubData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [filter, setFilter] = useState("ALL");
  const [searchText, setSearchText] = useState('');
  const [telegramSwitches, setTelegramSwitches] = useState({});
  const [statusSwitches, setStatusSwitches] = useState({});
  const [editFutureData, setEditFutureData] = useState()
  const [selectedData, setSelectedData] = useState()
  const [defaultValue, setDefaultValue] = useState()
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleEditClick = (record) => {

    const getData = editFutureData.filter(item => item.VarBotID == record.VarBotID)
    setSelectedData(getData[0])
    setDefaultValue(getData[0])

    setDrawerVisible(true);

    // Set the switch status for the current bot
    setTelegramSwitches({
      ...telegramSwitches,
      [record.VarBotID]: record.Telegram_Alert,
    });

    setStatusSwitches({
      ...statusSwitches,
      [record.VarBotID]: record.Status,
    });
  };


  const handlepackage = (page, size) => {
    const token = localStorage.getItem('token');
    const postData = {
      jwt: token,
      username: username,
      page_number: page,
      number_of_data: size,
      exchange_type: filter,
      trade_type: "ALL",
      symbol: [],
      botname: []
    };

    axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'user_copy_bot',
      data: postData
    }).then(res => {
      const responseData = res.data.Success

      setEditFutureData(responseData)

      const updatedSubData = res.data.Success.map(item => {
        item.TelegramSwitch = item.TelegramSwitch === 'ACTIVE';
        item.StatusSwitch = item.StatusSwitch === 'ACTIVE';
        return item;
      });
      setSubData(updatedSubData);
      setTotalDataCount(res.data.TotalPages * size);
    }).catch(err => {
    });
  };

  const handleSwitchChangetele = (record, checked, switchType) => {
    const token = localStorage.getItem('token');
    const newStatus = checked ? true : false;
    let work = '';
    const updatedDataSource1 = subdata.map((item) =>
      item.API_Name === record.API_Name ? { ...item, Status: newStatus } : item
    );
    if (switchType === 'Telegram') {
      setTelegramSwitches({
        ...telegramSwitches,
        [record.VarBotID]: newStatus,
      });
    } else if (switchType === 'Status') {
      setStatusSwitches({
        ...statusSwitches,
        [record.VarBotID]: newStatus,
      });
    }

    const postData = {
      jwt: token,
      username: username,
      work: switchType === 'Telegram' ? 'Tele' : 'Status',
      botid: record.VarBotID,
      apiname: record.API_Name,
      status: newStatus,
    };

    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API + 'change_copy_status',
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res?.Success) {
          message.success(res?.Success);
          handlepackage(currentPage, pageSize);
        } else if (res.Error) {
          message.error(res.Error);
        }
      })
      .catch((err) => {
        message.error(err.response.data.Error);
      });
  };


  useEffect(() => {
    handlepackage(currentPage, pageSize);
  }, [currentPage, pageSize, username, filter]);

  const handleFilterClick = (filterType) => {
    setFilter(filterType);

  };
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const subColumns = [
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Date/Time</p>,
      dataIndex: 'DateTime',
      key: 'DateTime',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Type
      </p>,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => (
        <span
          style={{
            display: "inline-block",
            padding: "4px 8px",
            borderRadius: "4px",
            color: "white",
            fontWeight: "bold",
            ...(text === "PAPER" ? { borderLeft: "4px solid #0c2b44", color: "#000", padding: "4px 4px 4px 8px" } : {}),
            ...(text === "LIVE" ? { borderLeft: "4px solid #69c3c4", color: "#000", padding: "4px 4px 4px 8px" } : {}),
          }}
        >
          {text}
        </span>
      ),
    },

    {
      title: <p className='p-1 text-center rounded-lg table-cell'>BotName
      </p>,
      dataIndex: 'BotName',
      key: 'BotName',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>API Name
      </p>,
      dataIndex: 'API_Name',
      key: 'API_Name',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Exchange</p>,
      dataIndex: 'Exchange',
      key: 'Exchange',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Exchange Type
      </p>,
      dataIndex: 'Exchange_Type',
      key: 'Exchange_Type',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Investment Type
      </p>,
      dataIndex: 'Investment_Risk',
      key: 'Investment_Risk',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Symbol
      </p>,
      dataIndex: 'Symbol',
      key: 'Symbol',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Invest
      </p>,
      dataIndex: 'Total_Investment',
      key: 'Total_Investment',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Telegram</p>,
      dataIndex: 'Telegram_Alert',
      key: 'Telegram_Alert',
      render: (text, record) => (
        <Switch
          className={record.Telegram_Alert ? "off" : "on"}
          checked={record.Telegram_Alert}
          checkedChildren={<div className="">ON</div>}
          unCheckedChildren={<div className="text-white">OFF</div>}
          onChange={(checked) =>
            handleSwitchChangetele(record, checked, 'Telegram')
          }
        />
        // <Switch
        //   checked={switchStatus[record.VarBotID]}
        //   onChange={(checked) => handleswitch(record.VarBotID, record.API_Name, checked, 'Telegram')}
        // />
      ),
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Status</p>,
      dataIndex: 'Status',
      key: 'Status',
      render: (text, record) => (
        <Switch
          className={record.Status ? "off" : "on"}
          checked={record.Status}
          checkedChildren={<div className="">Start</div>}
          unCheckedChildren={<div className="text-white">Pause</div>}
          onChange={(checked) => handleSwitchChangetele(record, checked, 'Status')}
        />
        // <Switch
        //   checked={statusSwitch[record.VarBotID]}
        //   onChange={(checked) => handleswitch(record.VarBotID, record.API_Name, checked, 'Status')}
        // />
      ),
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Action</p>,
      dataIndex: 'key',
      key: 'action',
      render: (text, record) => (
        <span className='flex justify-between m-1'>
          <span className='icon-cell p-1 rounded-md text-center mx-auto' onClick={() => handleEditClick(record)}>
            <FaEdit className='text-2xl opacity-80' />
          </span>
          <span className='icon-cell p-1 rounded-md text-center mx-auto' onClick={() => {}}>
            <MdDelete className='text-2xl opacity-80' />
          </span>
        </span>
      ),
    },

  ]

  const subDatasou = [];
  if (subdata.length > 0) {
    for (let i = 0; i < subdata.length; i++) {
      subDatasou.push({
        key: i, // Assuming 'i' is a unique identifier for each row
        DateTime: subdata[i].DateTime,
        Type: subdata[i].Type,
        BotName: subdata[i].BotName,
        VarBotID: subdata[i].VarBotID,
        Username: subdata[i].Username,
        API_Name: subdata[i].API_Name,
        Exchange: subdata[i].Exchange,
        Exchange_Type: subdata[i].Exchange_Type,
        Investment_Risk: subdata[i].Investment_Risk,
        Symbol: subdata[i].Symbol,
        Total_Investment: subdata[i].Total_Investment,
        ////////////FUTURE///////////////////
        Leverage: subdata[i].Leverage,
        BaseOrderVolumeL: subdata[i].BaseOrderVolumeL,
        BaseOrderVolumeS: subdata[i].BaseOrderVolumeS,
        OrderCountL: subdata[i].OrderCountL,
        OrderCountS: subdata[i].OrderCountS,
        VolumeMultiplierL: subdata[i].VolumeMultiplierL,
        VolumeMultiplierS: subdata[i].VolumeMultiplierS,
        TakeProfitPercentL: subdata[i].TakeProfitPercentL,
        TakeProfitPercentS: subdata[i].TakeProfitPercentS,
        LossPercentL: subdata[i].LossPercentL,
        LossPercentS: subdata[i].LossPercentS,
        LossMultiplierL: subdata[i].LossMultiplierL,
        LossMultiplierS: subdata[i].LossMultiplierS,
        OrderTypeOpenL: subdata[i].OrderTypeOpenL,
        OrderTypeOpenS: subdata[i].OrderTypeOpenS,
        OrderTypeCloseL: subdata[i].OrderTypeCloseL,
        OrderTypeCloseS: subdata[i].OrderTypeCloseS,
        /////////////SPOT/////////////
        BaseOrderVolume: subdata[i].BaseOrderVolume,
        OrderCount: subdata[i].OrderCount,
        VolumeMultiplier: subdata[i].VolumeMultiplier,
        TakeProfitPercent: subdata[i].TakeProfitPercent,
        LossPercent: subdata[i].LossPercent,
        LossMultiplier: subdata[i].LossMultiplier,
        OrderTypeOpen: subdata[i].OrderTypeOpen,
        OrderTypeClose: subdata[i].OrderTypeClose,
        Telegram_Alert: subdata[i].Telegram_Alert,
        Status: subdata[i].Status,

      });
    }
  }

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const onSearchChange = (e) => {
    handleSearch(e.target.value);
  };

  const onClearSearch = () => {
    setSearchText('');
  };

  const filteredDataSource = subDatasou.filter((item) =>
    Object.values(item).some((val) =>
      val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };

  const InputChange = (key, value) => {
    setSelectedData((prevRecord) => ({
      ...prevRecord,
      [key]: value,
    }));
  };

  const handleFutureEdit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const postData = {
      jwt: token,
      username: selectedData?.Username,
      botid: selectedData?.VarBotID,
      apiname: selectedData?.API_Name,
      exchangetype: selectedData?.Exchange_Type,
      apiname: selectedData?.API_Name,
      Leverage: selectedData?.Leverage,
      BaseOrderVolumeL: selectedData?.BaseOrderVolumeL,
      BaseOrderVolumeS: selectedData?.BaseOrderVolumeS,
      OrderCountL: selectedData?.OrderCountL,
      OrderCountS: selectedData?.OrderCountS,
      VolumeMultiplierL: selectedData?.VolumeMultiplierL,
      VolumeMultiplierS: selectedData?.VolumeMultiplierS,
      TakeProfitPercentL: selectedData?.TakeProfitPercentL,
      TakeProfitPercentS: selectedData?.TakeProfitPercentS,
      LossPercentL: selectedData?.LossPercentL,
      LossPercentS: selectedData?.LossPercentS,
      LossMultiplierL: selectedData?.LossMultiplierL,
      LossMultiplierS: selectedData?.LossMultiplierS,
      OrderTypeOpenL: selectedData?.OrderTypeOpenL,
      OrderTypeOpenS: selectedData?.OrderTypeOpenS,
      OrderTypeCloseL: selectedData?.OrderTypeCloseL,
      OrderTypeCloseS: selectedData?.OrderTypeCloseS,
    };


    axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'edit_copy_bot_futures',
      data: postData
    }).then(res => {
      handlepackage(currentPage, pageSize);
      handleDrawerClose();
      message.success(res?.data?.Success)
    }).catch(err => {
      message.error(err?.res?.data?.Error)
    });
  };

  const handleSpotEdit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    const postData = {
      jwt: token,
      username: selectedData?.Username,
      botid: selectedData?.VarBotID,
      apiname: selectedData?.API_Name,
      exchangetype: selectedData?.Exchange_Type,
      apiname: selectedData?.API_Name,
      BaseOrderVolume: selectedData?.BaseOrderVolume,
      OrderCount: selectedData?.OrderCount,
      VolumeMultiplier: selectedData?.VolumeMultiplier,
      TakeProfitPercent: selectedData?.TakeProfitPercent,
      LossPercent: selectedData?.LossPercent,
      LossMultiplier: selectedData?.LossMultiplier,
      OrderTypeOpen: selectedData?.OrderTypeOpen,
      OrderTypeClose: selectedData?.OrderTypeClose,
    };


    axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'edit_copy_bot_spot',
      data: postData
    }).then(res => {
      handlepackage(currentPage, pageSize);
      handleDrawerClose();
      message.success(res?.data?.Success)
    }).catch(err => {
      message.error(err?.res?.data?.Error)
    });
  }

  return (
    <div>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex justify-start flex-col items-start lg:space-x-4 ">

            <div className="p-2 ">
              <div className="flex mb-4 items-center justify-end">
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
                    ? "bg-colorSecondaryDark text-white border-2 border-white "
                    : "bg-white text-colorSecondaryDark"
                    }`}
                  onClick={() => {
                    handleFilterClick("ALL");
                    setFilter("ALL");
                  }}
                >
                  All
                </button>
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "SPOT"
                    ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-colorSecondaryDark"
                    }`}
                  onClick={() => {
                    handleFilterClick("SPOT");
                    setFilter("SPOT");
                  }}
                >
                  Spot
                </button>
                <button
                  className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "FUTURES"
                    ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-colorSecondaryDark"
                    }`}
                  onClick={() => {
                    handleFilterClick("FUTURES");
                    setFilter("FUTURES");
                  }}
                >
                  Future
                </button>
              </div>
            </div>
          </div>

          <Search
            placeholder="Search..."
            allowClear
            onChange={onSearchChange}
            onClear={onClearSearch}
            style={{ marginBottom: 16 }}
            className="w-64"
          />
        </div>
      </div>
      <Table
        columns={subColumns}
        dataSource={filteredDataSource}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalDataCount,
          showSizeChanger: true,
          pageSizeOptions: [],
        }}
        onChange={handleTableChange}
        size='small'
        bordered
        scroll={{
          x: 1800,
        }}
      />

      <Drawer
        classNames=''
        title="Edit Record"
        width={500}
        onClose={handleDrawerClose}
        visible={drawerVisible}
        destroyOnClose={true}
        className='outer_shadow'
      >
        {
          selectedData?.Exchange_Type === "FUTURES" ?
            <div>
              <form onSubmit={handleFutureEdit}>
                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Leverage</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.Leverage} X</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="Leverage"
                    value={selectedData?.Leverage}
                    suffix={'X'}
                    onChange={(e) => InputChange("Leverage", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Base Order Volume Long</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.BaseOrderVolumeL.toFixed(2)} $</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="BaseOrderVolumeL"
                    value={selectedData?.BaseOrderVolumeL.toFixed(2)}
                    suffix={'$'}
                    onChange={(e) => InputChange("BaseOrderVolumeL", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Base Order Volume Short:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.BaseOrderVolumeS.toFixed(2)} $</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="BaseOrderVolumeS"
                    value={selectedData?.BaseOrderVolumeS.toFixed(2)}
                    suffix={'$'}
                    onChange={(e) => InputChange("BaseOrderVolumeS", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Order Count Long:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.CurrentOrderCountL} / {defaultValue.OrderCountL}</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="OrderCountL"
                    suffix={''}
                    value={selectedData?.OrderCountL}
                    onChange={(e) => InputChange("OrderCountL", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Order Count Short:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.CurrentOrderCountS} / {defaultValue.OrderCountS}</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="OrderCountS"
                    value={selectedData?.OrderCountS}
                    suffix={''}
                    onChange={(e) => InputChange("OrderCountS", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Volume Multiplier Long:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.VolumeMultiplierL} X</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="VolumeMultiplierL"
                    value={selectedData?.VolumeMultiplierL}
                    suffix={'X'}
                    onChange={(e) => InputChange("VolumeMultiplierL", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Volume Multiplier Short:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.VolumeMultiplierS} X</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="VolumeMultiplierS"
                    suffix={'X'}
                    value={selectedData?.VolumeMultiplierS}
                    onChange={(e) => InputChange("VolumeMultiplierS", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Take Profit Percent Long:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.TakeProfitPercentL} %</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="TakeProfitPercentL"
                    suffix={'%'}
                    value={selectedData?.TakeProfitPercentL}
                    onChange={(e) => InputChange("TakeProfitPercentL", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Take Profit Percent Short:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.TakeProfitPercentS} %</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="TakeProfitPercentS"
                    suffix={'%'}
                    value={selectedData?.TakeProfitPercentS}
                    onChange={(e) => InputChange("TakeProfitPercentS", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Loss Percent Long:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.LossPercentL} %</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="LossPercentL"
                    suffix={'%'}
                    value={selectedData?.LossPercentL}
                    onChange={(e) => InputChange("LossPercentL", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Loss Percent Short:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.LossPercentS} %</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="LossPercentS"
                    suffix={'%'}
                    value={selectedData?.LossPercentS}
                    onChange={(e) => InputChange("LossPercentS", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Loss Multiplier Long:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.LossMultiplierL} X</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="LossMultiplierL"
                    suffix={'X'}
                    value={selectedData?.LossMultiplierL}
                    onChange={(e) => InputChange("LossMultiplierL", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Loss Multiplier Short:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.LossMultiplierS} X</div>
                  <Input
                    className='col-span-2 outer_shadow m-1'
                    type="text"
                    name="LossMultiplierS"
                    suffix={'X'}
                    value={selectedData?.LossMultiplierS}
                    onChange={(e) => InputChange("LossMultiplierS", e.target.value)}
                  />
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Order Type Open Long:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.OrderTypeOpenL}</div>
                  <Select className='outer_shadow m-1 col-span-2' value={selectedData?.OrderTypeOpenL} onChange={(value) => InputChange("OrderTypeOpenL", value)}>
                    <Option value="MARKET">MARKET</Option>
                    <Option value="LIMIT">LIMIT</Option>
                  </Select>
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Order Type Open Short:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.OrderTypeOpenS}</div>
                  <Select className='outer_shadow m-1 col-span-2' value={selectedData?.OrderTypeOpenS} onChange={(value) => InputChange("OrderTypeOpenS", value)}>
                    <Option value="MARKET">MARKET</Option>
                    <Option value="LIMIT">LIMIT</Option>
                  </Select>
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Order Type Close Long:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.OrderTypeCloseL}</div>
                  <Select className='outer_shadow m-1 col-span-2' value={selectedData?.OrderTypeCloseL} onChange={(value) => InputChange("OrderTypeCloseL", value)}>
                    <Option value="MARKET">MARKET</Option>
                    <Option value="LIMIT">LIMIT</Option>
                  </Select>
                </div>

                <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                  <label className='col-span-3 text-sm'>Order Type Close Short:</label>
                  <div className=' col-span-1 text-center'>{defaultValue?.OrderTypeCloseS}</div>
                  <Select className='outer_shadow m-1 col-span-2' value={selectedData?.OrderTypeCloseS} onChange={(value) => InputChange("OrderTypeCloseS", value)}>
                    <Option value="MARKET">MARKET</Option>
                    <Option value="LIMIT">LIMIT</Option>
                  </Select>
                </div>

                <button type="submit" className='top-left-shadow block m-auto py-2 px-11 rounded-2xl my-4'>
                  Save
                </button>
              </form>
            </div>
            :
            <div><form onSubmit={handleSpotEdit}>
              <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                <label className='col-span-3 text-sm'>Base Order Volume</label>
                <div className=' col-span-1 text-center'>{defaultValue?.BaseOrderVolume} $</div>
                <Input
                  className='col-span-2 outer_shadow m-1'
                  type="text"
                  name="BaseOrderVolume"
                  value={selectedData?.BaseOrderVolume}
                  suffix={'$'}
                  onChange={(e) => InputChange("BaseOrderVolume", e.target.value)}
                />
              </div>
              <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                <label className='col-span-3 text-sm'>Order Count</label>
                <div className=' col-span-1 text-center'>{defaultValue?.CurrentOrderCount} / {defaultValue?.OrderCount} </div>
                <Input
                  className='col-span-2 outer_shadow m-1'
                  type="text"
                  name="OrderCount"
                  value={selectedData?.OrderCount}
                  suffix={''}
                  onChange={(e) => InputChange("OrderCount", e.target.value)}
                />
              </div>
              <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                <label className='col-span-3 text-sm'>Volume Multiplier</label>
                <div className=' col-span-1 text-center'>{defaultValue?.VolumeMultiplier} X</div>
                <Input
                  className='col-span-2 outer_shadow m-1'
                  type="text"
                  name="VolumeMultiplier"
                  value={selectedData?.VolumeMultiplier}
                  suffix={'X'}
                  onChange={(e) => InputChange("VolumeMultiplier", e.target.value)}
                />
              </div>
              <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                <label className='col-span-3 text-sm'>Take Profit Percent</label>
                <div className=' col-span-1 text-center'>{defaultValue?.TakeProfitPercent} %</div>
                <Input
                  className='col-span-2 outer_shadow m-1'
                  type="text"
                  name="TakeProfitPercent"
                  value={selectedData?.TakeProfitPercent}
                  suffix={'%'}
                  onChange={(e) => InputChange("TakeProfitPercent", e.target.value)}
                />
              </div>
              <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                <label className='col-span-3 text-sm'>Loss Percent</label>
                <div className=' col-span-1 text-center'>{defaultValue?.LossPercent} %</div>
                <Input
                  className='col-span-2 outer_shadow m-1'
                  type="text"
                  name="LossPercent"
                  value={selectedData?.LossPercent}
                  suffix={'%'}
                  onChange={(e) => InputChange("LossPercent", e.target.value)}
                />
              </div>
              <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                <label className='col-span-3 text-sm'>Loss Multiplier</label>
                <div className=' col-span-1 text-center'>{defaultValue?.LossMultiplier} X</div>
                <Input
                  className='col-span-2 outer_shadow m-1'
                  type="text"
                  name="LossMultiplier"
                  value={selectedData?.LossMultiplier}
                  suffix={'X'}
                  onChange={(e) => InputChange("LossMultiplier", e.target.value)}
                />
              </div>

              <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                <label className='col-span-3 text-sm'>Order Type Open</label>
                <div className=' col-span-1 text-center'>{defaultValue?.OrderTypeOpen}</div>
                <Select className='outer_shadow m-1 col-span-2' value={selectedData?.OrderTypeOpen} onChange={(value) => InputChange("OrderTypeOpen", value)}>
                  <Option value="MARKET">MARKET</Option>
                  <Option value="LIMIT">LIMIT</Option>
                </Select>
              </div>

              <div className='grid grid-cols-6 gap-0 m-1 mx-6 items-center justify-center rounded-lg'>
                <label className='col-span-3 text-sm'>Order Type Close</label>
                <div className=' col-span-1 text-center'>{defaultValue?.OrderTypeClose}</div>
                <Select className='outer_shadow m-1 col-span-2' value={selectedData?.OrderTypeClose} onChange={(value) => InputChange("OrderTypeClose", value)}>
                  <Option value="MARKET">MARKET</Option>
                  <Option value="LIMIT">LIMIT</Option>
                </Select>
              </div>

              <button type="submit" className='top-left-shadow  block mt-6 m-auto py-2 px-11 rounded-2xl my-4'>
                Save
              </button>
            </form>
            </div>
        }
      </Drawer>


    </div>
  );
}

export default CopyBottablecopy;


