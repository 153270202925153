import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Radio, Tabs } from "antd";
import ActiveApi from "../Component/ActiveApi";
import ActiveBot from "../Component/ActiveBot";
import ActiveCoin from "../Component/ActiveCoin";



function TransactionBot() {
  const onSearch = (value, _e, info) => {}
  const [size, setSize] = useState("small");
  const onChange = (e) => {
    setSize(e.target.value);
  };

  const items = [
    {
      key: "62",
      label: <div className="w-52">Active API</div>,
      children: <ActiveApi />,
    },
    {
      key: "12",
      label: <div className="w-52">Active BOT</div>,
      children: <ActiveBot />,
    },
    {
      key: "32",
      label: <div className="w-52">Active Coin</div>,
      children: <ActiveCoin />,
    },
    
  ];

  return (
    <>
      <div>
        <div className="container mx-auto mt-20  md:mt-10">
          <form className="flex items-center lg:items-end justify-center lg:justify-end xl:-mb-10">
            <div className="flex justify-center">
              <label for="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative justify-center">
                {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div> */}
                <input
                  type="text"
                  id="simple-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full px-5 p-2  dark:bg-colorPrimary shadow-md shadow-colorSecondaryDark dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Username..."
                  required
                />
              </div>
              <button
                type="submit"
                className="p-2 ml-2 text-sm font-medium text-white bg-colorAccent rounded-lg border border-colorAccent hover:bg-colorAccent focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-colorAccent dark:hover:bg-colorAccent dark:focus:ring-colorAccent"
              >
                <AiOutlineSearch size={20} />
                <span className="sr-only">Search</span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="mt-5 ">
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={size}
          items={items}
          className=""
        />
      </div>
    </>
  );
}

export default TransactionBot;
