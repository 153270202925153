import React, { useEffect, useState } from 'react'
import diamond from '../assets/images/diamond.svg'
import user from '../assets/images/user.svg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
const Mainpage = () => {
  const [data, setData] = useState([])

  const navigate = useNavigate()

  const getData = () => {
    const token = localStorage.getItem('token')
    const postData = {
      jwt: token,
    }

    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'dashboard',
      data: postData
    }).then(res => {
      setData(res?.data?.Success[0])
    })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getData()
  }, [])

  const jsonData = [
    {
      "img": user,
      "tittle": "Total Users",
      "value": `${data?.Total_Users}`,
      link: '/dashboard/usermanagement',
    },
    {
      "img": user,
      "tittle": "Total Paid Users",
      "value": `${data?.Total_Paid_Users}`,
    },
    {
      "img": user,
      "tittle": "Total Unpaid Users",
      "value": `${data?.Total_Unpaid_Users}`,
    },
    {
      "img": user,
      "tittle": "Total Active Packages",
      "value": `${data?.Total_Active_Packages}`,
    },
    {
      "img": user,
      "tittle": "Total live Apis",
      "value": `${data?.Total_live_Apis}`,
      link: '/dashboard/allApi',
      type: "LIVE"
    },
    {
      "img": user,
      "tittle": "Total paper Apis",
      "value": `${data?.Total_paper_Apis}`,
      link: '/dashboard/allApi',
      type: "PAPER"
    },
    {
      "img": user,
      "tittle": "Alpha Bot Users",
      "value": `${data?.Alpha_Bot_Users}`,
    },
    {
      "img": user,
      "tittle": "Beta Bot Users",
      "value": `${data?.Beta_Bot_Users}`,
    },
    {
      "img": user,
      "tittle": "Delta Bot Users",
      "value": `${data?.Delta_Bot_Users}`,
    },
  ];

  return (
    <div className='flex bg-colorGreyish gap-64'>
      <div className='w-full md:w-8/12 '>
        <div className='grid sm:grid-cols-2 gap-4  pb-5 p-4 '>
          {jsonData.map((item, index) => (
            <div key={index} className='top-left-shadow bg-colorGreyish rounded-xl cursor-pointer'
              onClick={() => { navigate(`${item?.link ? item?.link : "/dashboard"}`, { state: { type: item?.type } }) }}>
              <div className='flex items-center justify-between p-4'>
                <div className='flex items-center justify-between gap-2'>
                  <img src={item.img} className='w-12' />
                  <div className={`${item.img == diamond && "mb-3"} capitalize font-semibold`}>
                    {item.tittle}
                  </div>
                </div>
                <div className='shadow-neumorphic1 px-4 py-2 rounded-md font-bold'>
                  {item.value}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className='w-4/12 px-4 pt-2'>
        <div className='top-left-shadow bg-colorGreyish rounded-md'>
          right hand side
        </div>
      </div> */}
    </div>
  )
}

export default Mainpage
