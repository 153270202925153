import React, { useEffect, useState } from 'react'
import { Select, Tabs } from 'antd'
import BalanceSpotcopy from '../Component/BalanceSpotcopy'
import Position from '../Component/Position'
import OrderHistorySpot from '../Component/OrderHistorySpot'
import OpenorderFuture from '../Component/OpenorderFuture'
import OpenorderSpot from '../Component/OpenorderSpot'

const Mainspot = (props) => {

  const { username, option } = props?.data
  const [option2, setOption2] = useState(option)
  const [Api, setApi] = useState()

  useEffect(() => {
    setOption2(option)
    const fisrtLabel = option[0]?.value
    setApi(fisrtLabel)
  }, [option])

  const handleChange = (value) => {
    setApi(value)
  }

  const spotItem = [
    {
      key: '1',
      label: <div className='px-4 md:px-10 text-xs md:text-sm'>BALANCE</div>,
      children: <BalanceSpotcopy data={{ username: username, option: option, newOption: Api }} />,
    },
    // {
    //   key: '2',
    //   label: 'OPEN ORDER',
    //   children: <OpenorderSpot data={{ username: username, option: option, newOption: Api }}  />,
    // },
    {
      key: '2',
      label: <div className='px-4 md:px-10 text-xs md:text-sm'>ORDER HISTORY</div>,
      children: <OrderHistorySpot data={{ username: username, option: option, newOption: Api }} />,
    },
  ]
  return (
    <div>
      <div className=' px-5 pb-4'>
        <span className=' text-sm md:text-base font-semibold'>API Name : </span>
        <Select
          value={Api}
          className='focus:border-colorAccent hover:border-colorAccent'
          style={{
            width: 180,
          }}
          placeholder="Select API Name"
          onChange={handleChange}
          options={option2}
        />
      </div>
      <div className='xl:hidden overflow-x-hidden'>
        <Tabs className='min-h-[400px] tabakhil px-2'  defaultActiveKey="1" items={spotItem} />
      </div>
      <div className='hidden xl:block'>
        <Tabs className='min-h-[400px] ' defaultActiveKey="1" items={spotItem} tabPosition={'left'} />
      </div>
    </div>
  )
}

export default Mainspot
