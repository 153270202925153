import React, { useEffect, useState } from 'react'
import { Form, Card, Input, message, Table } from 'antd'
import axios from 'axios'
import { FaCommentsDollar, FaSearch } from "react-icons/fa";
import { GiTargetArrows } from 'react-icons/gi';


const Addfund = () => {
  const [fundData, setFundData] = useState([])
  const [username, setUsername] = useState('')
  const [userdata, setUserdata] = useState()
  const [inputValue, setInputValue] = useState('');

  const required = userdata === null
    && inputValue.length === 0

  const handleAddFund = (data) => {
    const token = localStorage.getItem('token')
    const postData = {
      // ...data,
      jwt: token,
      totp: "",
      username: username,
      fund: inputValue
    }

    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'add_fund',
      data: postData
    }).then((res) => {
      message.success(res?.data?.Success)
      getFundData()
    }).catch(err => {
      message.error(err?.response?.data?.Error)

    })
  }

  const getFundData = () => {
    const token = localStorage.getItem('token')
    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'get_details',
      data: { jwt: token, work: 'fund' }
    }).then(res => {
      setFundData(res.data.Success)
    }).catch(err => {
    })
  }

  useEffect(() => {
    getFundData();
  }, []);

  const dataSource = []

  if (fundData.length > 0) {
    for (let i = 0; i < fundData.length; i++) {
      dataSource.push({
        Action: fundData[i].Action,
        Amount: fundData[i].Amount,
        CreatedAt: fundData[i].CreatedAt,
        TransID: fundData[i].TransID,
        Username: fundData[i].Username,
      })
    }
  }

  const reverseData = [...dataSource].reverse()

  const columns = [
    {
      title: 'Date and time',
      dataIndex: 'CreatedAt',
      key: 'CreatedAt',
      width: '20%'
    },
    {
      title: 'TransID',
      dataIndex: 'TransID',
      key: 'TransID',
      width: '20%'
    },
    {
      title: 'Username',
      dataIndex: 'Username',
      key: 'Username',
      width: '20%',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: '20%'
    },

  ]



  const searchData = () => {
    const token = localStorage.getItem('token')
    const postData = {
      jwt: token,
      username: username.toLowerCase()
    }


    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'get_user',
      data: postData
    }).then(res => {
      setUserdata(res.data.Success)
    }).catch(err => {
      setUserdata()
      message.error(err.response.data.Error)
    })
  }

  const isButtonDisabled = userdata !== null

  const User = userdata && userdata.length > 0 ? userdata[0] : {};
  return (
    <>
      <p className='text-2xl capitalize font-bold text-black w-[96%] mx-auto'>Add Fund</p>
      <div className=' grid grid-cols-3 gap-1 xl:gap-6 pb-10 w-[96%] mx-auto'>
        <div className=' col-span-3 md:col-span-1'>
          <div className='mt-6 top-left-shadow bg-[#ebeff3]   rounded-xl overflow-hidden pt-12 pb-6 px-10 md:px-2 xl:px-14 mx-10  sm:mx-20 md:mx-0'>
            <div className=' mb-6'>
              <p className='  text-base  ml-2 mb-3 font-semibold'>Username</p>
              <div className=' flex gap-2 items-center'>
                <Input placeholder="Username"
                  className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-sm'
                  value={username} onChange={(e) => setUsername(e.target.value)} />
                <button onClick={searchData} className='top-left-shadow bg-colorGreyish rounded-lg h-8'><FaSearch size={20} className=' rounded-xl p-1 text-base h-7 w-7' /></button>
              </div>
              {
                userdata &&
                <div className=' ml-2 my-2'>
                  <p>Joining date : {User?.Created_On}</p>
                  <p>Name : {User?.Name}</p>
                  <p>Email : {User?.Email}</p>
                </div>
              }
            </div>

            <Form
              className=""
              name="basic"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
            >
              {/* <Form.Item
              labelAlign="left"
              name="username"
              rules={[
                {
                  message: "Please input username",
                  required: true
                },
              ]}
            >
              <p className='  text-base  ml-2 mb-3 font-semibold'>Username</p>
              <div className=' flex gap-2 items-center'>
                <Input placeholder="Username"
                  className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-sm'
                  value={username} onChange={(e) => setUsername(e.target.value)} />
                <button onClick={searchData} className='top-left-shadow bg-colorGreyish rounded-lg h-8'><FaSearch size={20} className=' rounded-xl p-1 text-base h-7 w-7' /></button>
              </div>
              {
                userdata &&
                <div>
                  <p>Joining date : {userdata?.Created_On}</p>
                  <p>Name : {userdata?.Name}</p>
                  <p>Email : {userdata?.Email}</p>
                </div>
              }
            </Form.Item> */}

              <Form.Item
                name="fund"
                labelAlign="left"
              // rules={[
              //   {
              //     message: "Please input fund",
              //     required: true
              //   },
              // ]}
              >
                <p className='  text-base  ml-2 mb-3 font-semibold'>Fund</p>
                <Input placeholder="Fund" type="text"
                  className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-sm'
                  value={inputValue}
                  onChange={(e) => { setInputValue(e.target.value) }}
                  maxLength={10}
                  onKeyPress={e => {
                    const keyCode = e.which || e.keyCode;
                    const isValidKey = /^[0-9]+$/.test(String.fromCharCode(keyCode));
                    if (!isValidKey) {
                      e.preventDefault();
                    }
                  }}
                />

              </Form.Item>

              <Form.Item
                name="totp"
                labelAlign="left"
              // rules={[
              //   {
              //     message: "Please input 2FA",
              //   },
              // ]}
              >
                <p className='  text-base  ml-2 mb-3 font-semibold'>2FA</p>
                <Input placeholder="2FA"
                  className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-sm'
                />
              </Form.Item>

              <Form.Item className=" flex justify-center m-0">
                <button type="primary" htmlType="submit"
                  onClick={handleAddFund}
                  className="  bg-colorAccent top-left-shadow text-lg py-2 xl:py-3 px-4 xl:px-16 rounded-lg m-9 text-white block">
                  Submit
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className=' col-span-3 md:col-span-2 top-left-shadow m-6 rounded-xl w-[96%] mx-auto'>
          <Table columns={columns} dataSource={reverseData}
            scroll={{
              x: 1000,
            }}
          />
        </div>
      </div>
    </>

  )
}

export default Addfund
