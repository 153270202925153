import React, { useEffect, useState } from 'react'
import { Switch, Table, message } from 'antd'
import axios from 'axios';
import { configConsumerProps } from 'antd/es/config-provider';

const Emailpending = () => {
    const [viewuserdata, setViewuserdata] = useState([]);

    const ViewUser = () => {
        const token = localStorage.getItem('token')

        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'view_all_users',
            data: { jwt: token }
        }).then(res => {
            const successData = res?.data?.Success.User            
            const getPendingEmail = successData.filter((item) => item?.Email_Verification == false);
            setViewuserdata(getPendingEmail)
        }).catch(err => {
        })
    };

    useEffect(() => {
        ViewUser()
    }, [])


    const dataSource = []

    if (viewuserdata.length > 0) {
        for (let i = 0; i < viewuserdata.length; i++) {
            dataSource.push({
                username: viewuserdata[i]?.Username,
                name: viewuserdata[i]?.Name,
                email: viewuserdata[i]?.Email,
                wallet_Balance: viewuserdata[i]?.Wallet_Balance,
                wallet_Deposit: viewuserdata[i]?.Wallet_Deposit,
                email_Verification: viewuserdata[i]?.Email_Verification,
                subscription_Name: viewuserdata[i]?.Subscription_Name,
                totp_Activation: viewuserdata[i]?.TOTP_Activation,
                telegramID: viewuserdata[i]?.TelegramID,
            })
        }
    }

    const reverseDataSource = [...dataSource].reverse()

    const [data , setData] = useState(true)

    const columns = [
        {
            title:<p className='p-1 text-center rounded-lg table-cell'>Username</p>,
            dataIndex: 'username',
            key: 'username',
            sorter: (a, b) => a.username.localeCompare(b.username),
            // render : (text) => {
            //   return(<span className='shadow-neumorphicnew'>{text}</span>)
            // }
            className: 'custom-header-class',
            width: 200
        },
        {
            title:<p className='p-1 text-center rounded-lg table-cell'>Name</p>,
            dataIndex: 'name',
            key: 'name',
            width: 200
        },
        {
            title:<p className='p-1 text-center rounded-lg table-cell'>Email</p>,
            dataIndex: 'email',
            key: 'email',
            width: 300
        },
        {
            title:<p className='p-1 text-center rounded-lg table-cell'>Wallet Balance</p>,
            dataIndex: 'wallet_Balance',
            key: 'wallet_Balance',
            width: 200
        },
        {
            title:<p className='p-1 text-center rounded-lg table-cell'>Wallet Deposit</p>,
            dataIndex: 'wallet_Deposit',
            key: 'wallet_Deposit',
            width: 200
        },
        {
            title:<p className='p-1 text-center rounded-lg table-cell'>Email status</p>,
            dataIndex: 'email_Verification',
            key: 'email_Verification',
            render: (text) => {
                const displayText = text === true ? "Active" : "Inactive";
                return <span className={`${text ? ' bg-green-400' : 'bg-red-300 ' }  text-white px-4 py-1 top-left-shadow rounded-xl uppercase`}>{displayText}</span>;
            },
            width: 200
        },
        {
            title:<p className='p-1 text-center rounded-lg table-cell'>Verify email</p> ,
            render: (tableRecord) => {
             
                const emailVerificationStatus = tableRecord?.email_Verification;
                return (
                    <span 
                    onClick={() => getData(tableRecord)}
                     className=' px-4 pb-2 pt-1  '>
                        <Switch  checked={emailVerificationStatus} className='top-left-shadow bg-red-300'  onChange={onChange} />
                    </span>
                );
            },
            width: 200
        },
        
    ];

    const getData = (data) => {
         const token = localStorage.getItem('token')
         const status = data?.email_Verification
        const postData = {
            jwt : token,
            username : data?.username,
            work : 'Email',
            action : !status,
        }

        const response = axios({
            method : "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'status_change',
            data  : postData
        }).then((res) => {
        ViewUser()
            message.success(res?.data?.Success)
        }).catch(err => {
            message.error(err?.response?.data?.Error)
        })
    }

    const paginationConfig = {
        pageSize: 10,
        total: dataSource.length, // Set the total number of items in your data source
        showSizeChanger: false, // Optional: Set to false if you don't want to allow changing the page size
    };

    const onChange = (pagination, filters, sorter, extra) => {
    };

    return (
        <div  className='top-left-shadow m-6 rounded-xl w-[96%] mx-auto'>
            <Table dataSource={reverseDataSource} columns={columns} pagination={paginationConfig} onChange={onChange}
             scroll={{
                x: 1500,
              }}
            />
        </div>
    )
}

export default Emailpending
