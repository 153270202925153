import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Viewpackage from '../Component/Viewpackage';
import Addpackage from '../Component/Addpackage';
import EditPackage from '../Component/EditPackage';
import DeletePackage from '../Component/DeletePackage';
import PackageTransaction from '../Component/PackageTransaction';
import Summary from '../Component/Summary';
import AddpackageCopy from '../Component/AddpackageCopy';
import Viewpackagecopy from '../Component/Viewpackagecopy';

const PackagePages = () => {
    const [index, setIndex] = useState(0);

    const handleChangeIndex = (newIndex) => {
        setIndex(newIndex);
    };

    const tabdata = [
        "View Packages",
        // "Edit Packages",
        "Add Packages",
        "Deleted Packages",
        "Pack Transactions",
        "Summary"
    ]

    return (
        <div>
            <p className=' text-2xl capitalize font-bold text-black mx-auto'>Packages</p>
            <div className=' mx-auto my-6 container rounded-md  '>
                <div className=' flex items-center h-full'>

                    <div className=' grid grid-cols-2 xl:grid-cols-6 px-1 gap-y-4 gap-x-10 w-full'>

                        {tabdata.map((tab, tabIndex) => (
                            <div
                                key={tabIndex}
                                className={`col-span-1 text-sm xl:text-base py-3 font-medium rounded-xl top-left-shadow px-3 text-center cursor-pointer ${index === tabIndex
                                    ? "active-tab bg-colorAccent shadow-neumorphicnew text-white rounded-3xl px-5"
                                    : "text-black border-none"
                                    }`}
                                onClick={() => setIndex(tabIndex)}
                            >
                                {tab}
                            </div>
                        ))}
                    </div>

                    {/* <div className=' grid grid-cols-6 gap-x-10 w-full'>
                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 0
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow  text-white rounded-3xl"
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(0)}
                        >View Packages</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 1
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow  text-white rounded-3xl"
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(1)}
                        >Edit Packages</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 2
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow  text-white rounded-3xl"
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(2)}
                        >Add Packages</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 3
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow  text-white rounded-3xl"
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(3)}
                        >Deleted Packages</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 4
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow  text-white rounded-3xl"
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(4)}
                        >Packages Transactions</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 5
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow  text-white rounded-3xl"
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(5)}
                        >Summary</div>
                    </div> */}
                </div>
            </div>

            <div className="">
                <SwipeableViews
                    index={index}
                    onChangeIndex={handleChangeIndex}
                    className=""
                >
                    <div className='container mx-auto'>
                        <Viewpackage />
                        {/* <Viewpackagecopy/> */}
                    </div>
                    {/* <div className='container mx-auto'>
                        <EditPackage />     
                    </div> */}
                    <div className='container mx-auto'>
                        <Addpackage />
                        {/* <AddpackageCopy /> */}
                    </div>
                    <div className='container mx-auto'>
                        <DeletePackage />
                    </div>
                    <div className='container mx-auto'>
                        <PackageTransaction />
                    </div>
                    <div className='container mx-auto'>
                        <Summary />
                    </div>
                </SwipeableViews>
            </div>

            {/* <AddpackageCopy /> */}

        </div>
    )
}

export default PackagePages
