import { Space, Button, message, Drawer, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import { AiOutlineBars } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { BsFillChatDotsFill, BsFillFuelPumpDieselFill, BsFilterLeft } from "react-icons/bs";
import logo from '../assets/images/logo.png'
import { IoMenu } from "react-icons/io5";
import { useLocation, Link } from "react-router-dom";
import { BiDollar } from "react-icons/bi";
import { MdDashboard } from "react-icons/md";
import { FaCircleDollarToSlot } from "react-icons/fa6";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { FaUserPlus } from "react-icons/fa";
import { RiUserSearchFill } from "react-icons/ri";
import { GiOrbit } from "react-icons/gi";
import { BiSolidMessageRoundedDots } from "react-icons/bi";
import { RiFileList3Fill } from "react-icons/ri";
import { AiFillApi } from "react-icons/ai";
import axios from "axios";


const Navbar = ({ updated, initial }) => {
  const [open, setOpen] = useState(false);
  const [openSide, setOpenSide] = useState(true)
  const [openSubMenu, setOpenSubMenu] = useState(null);


  const location = useLocation()

  const navigate = useNavigate()
  const redirect = () => {
    navigate("/");
  };

  const logout = () => {
    localStorage.clear();
    message.success("Logout Successfully.");
    window.location.href = "/";
    // Navigate('/')
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const sidebarItems = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <MdDashboard size={16} />,
    },
    {
      title: "Add fund",
      path: "/dashboard/addfund",
      icon: <FaCircleDollarToSlot size={16} />,
    },
    {
      title: "Subscription",
      path: "/dashboard/subscription",
      icon: <FaMoneyCheckDollar size={16} />,
    },
    {
      title: "Management",
      path: "/dashboard/usermanagement",
      icon: <FaUserPlus size={16} />,
    },
    {
      title: "Package",
      path: "/dashboard/packagePage",
      icon: <BiDollar size={16} />,
    },
    // {
    //   title: "Create user",
    //   path: "/dashboard/createusers",
    //   icon: <FaUserPlus size={16} />,
    // },

    {
      title: "Search",
      path: "/dashboard/activeuser",
      icon: <RiUserSearchFill size={16} />,
    },
    // {
    //   title: "Active Bot",
    //   path: "/dashboard/active",
    //   icon: <GoDependabot size={16} />,
    // },
    // {
    //   title: "All users",
    //   path: "/dashboard/alluser",
    //   icon: <FaUsers size={16} />,
    // },
    {
      title: "Livedata",
      path: "/dashboard/livedata",
      icon: <GiOrbit size={16} />,
    },
    {
      title: "Support",
      path: "/dashboard/support",
      icon: <BiSolidMessageRoundedDots size={16} />,
    },
    // {
    //   title: "Email Templates",
    //   path: "/dashboard/emailTemplates",
    //   icon: <AiFillWindows size={16} />,
    // },
    {
      title: "Logs",
      path: "/dashboard/logs",
      icon: <RiFileList3Fill size={16} />,
    },
    // {
    //   title: "Active Bot",
    //   icon: <AiFillSignal size={16} />,
    //   //  path: "/dashboard/admin",
    //   subItems: [
    //     {
    //       title: "Add API",

    //       // icon: <AiFillRedditCircle size={16} />,
    //       path: "/dashboard/",
    //     },
    //     { title: "View API", path: "/dashboard" },
    //   ],
    // },
  ];

  const [submenuOpen, setSubmenuOpen] = useState(
    new Array(sidebarItems.length).fill(false)
  );

  const handleSubmenuClick = (index) => {
    const updatedSubmenuOpen = [...submenuOpen];
    updatedSubmenuOpen[index] = !updatedSubmenuOpen[index];
    setSubmenuOpen(updatedSubmenuOpen);
  };

  // const getData = () => {
  //   const token = localStorage.getItem('token')
  //   const postData = {
  //     jwt: token,
  //   }
  //   const response = axios({
  //     method: 'POST',
  //     headers: { "Content-Type": "application/json" },
  //     url: process.env.REACT_APP_API + 'logs',
  //     data: postData
  //   }).then(res => {
  //     console.log("naverror" , res)
  //   }).catch(err => {
  //       console.log( "naverror" , err)
  //       // setTimeout(() => {
  //       //   localStorage.clear();
  //       //   window.location.href = "/";
  //       //   Navigate("/");
  //       // }, 5000); 
  //       if (err?.response?.data?.Error === "Session Expired") {
  //         setTimeout(() => {
  //           localStorage.clear();
  //           window.location.href = "/";
  //           navigate("/");
  //           message.error(err?.response?.data?.Error);
  //         }, 100);
  //       }
  //     })
  // }

  // useEffect(() => {
  //   getData()
  // }, [])

  return (
    <>
      <nav>
        <div className={`p-4 hidden md:flex justify-between items-center  ml-auto ${initial ? "md:w-[calc(100%-210px)]" : "md:w-[calc(100%-75px)]"}`}>
          <div className=" flex gap-16 items-center">
            <div className="">
              <BsFilterLeft size={45}
                className={`absolute cursor-pointer top-1 w-100 mt-3  text-black rounded-full ${!initial ? "-scale-y-100 -rotate-180" : ""
                  }`}
                onClick={() => updated(!initial)} />
            </div>
            <div className=" text-lg md:text-2xl capitalize font-medium ">Hello, Admin!</div>
          </div>

          <button
            className=" bg-[#122b45] text-white  px-6 py-2 rounded-md"
            onClick={logout}
          >
            Logout
          </button>
        </div>

        <div className="bg-white py-1 px-1 flex justify-between items-center md:hidden">
          <div className=" w-[30%] bg-colorSecondaryDark px-2 ">
            <img src={logo} alt="" className=" py-3 " />
          </div>
          {/* <div className=" text-lg md:text-2xl capitalize font-medium ">Hello, Admin!</div> */}
          <div>
            <div className="flex items-center">
              <div className="px-3"><IoMenu size={30} onClick={showDrawer} /></div>
            </div>
          </div>
        </div>
      </nav>

      <Drawer title="Hello, Admin!" placement="right" onClose={onClose} open={open} width={250} className="akhil-darwer">
        {/* <Menu
          selectedKeys={[location?.pathname]}
          mode="vertical"
          className="w-full bg-colorPrimary p-0 h-screen"
        > */}
        <div className=" mt-4">
          <ul className=" text-black">
            {
              sidebarItems.map((item , index) => {
                return (
                  <>
                  <Link
                      key={index}
                      to={item.path}
                      onClick={() => setOpen(false)}
                      // className={`uppercase ${!isDrawerOpen && "hidden"}`}
                      className=" text-black"
                    >
                    <li className=" w-36 mx-auto bg-white top-left-shadow py-2 pl-4 flex gap-2 rounded-lg my-5 text-sm">
                      <span>{item?.icon}</span>
                      <span className=" text-xs font-semibold">{item?.title}</span>
                    </li>
                    </Link>
                  </>
                )
              })
            }
          </ul>
        </div>
        {/* </Menu> */}
      </Drawer>


      {/* <nav className= " md:hidden bg-gray-900 text-white p-4 flex justify-between items-center z-40 ">
        <div className="flex items-center">
          <span className="text-2xl font-bold ml-2">Your Logo</span>
        </div>

        <div className="flex-1  hidden md:flex justify-center">
          <input
            type="text"
            className="w-1/2 bg-gray-800 text-white rounded-full px-4 py-2"
            placeholder="Search..."
          />
        </div>

        <div className="flex items-center">
          <div className="mr-1">
            <BsPersonCircle size={35} />
          </div>
          <button className="bg-red-500 hidden md:block text-white py-2 px-4 rounded-lg">
            Logout
          </button>
        </div>

        <Space>
          <Button
            type="primary"
            onClick={showDrawer}
            className="block md:hidden"
          >
            <AiOutlineBars size={25}/>
          </Button>
        </Space>
      </nav> */}
    </>
  );
};

export default Navbar;
