import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from '../assets/images/logo.png'
import { message } from "antd";

function Login() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const getUserFunc = (e) => {
    e.preventDefault();
    const postData = {
      username: loginData.username,
      password: loginData.password,
      totp : ""
      };
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url : process.env.REACT_APP_API + 'login',
      data: postData,
    })
      .then((response) => {
        localStorage.setItem("token" , response?.data?.Jwt)
        navigate("/dashboard");
      })
      .catch((err) => {
        message.error(err?.response?.data?.Error)
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };
  return (
    <div>
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="  bg-colorSecondaryDark w-[30vh] block mx-auto mb-20 p-5 rounded-md">
          <img src={logo} alt="" />
        </div>
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-colorSecondaryDark to-colorAccent shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <div className="max-w-md mx-auto">
              <div>
                <h1 className="text-2xl font-semibold text-center">Login </h1>
              </div>
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <form onSubmit={getUserFunc}>
                    <div className="">
                      <label
                        htmlFor="username"
                        className=" text-center left-0 -top-3.5 mt-2  text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Username
                      </label>
                      <input
                        autoComplete="off"
                        value={loginData.username}
                        onChange={handleInputChange}
                        name="username"
                        type="text"
                        className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 rounded-full p-4 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="Email address"
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="password"
                        className=" text-center mt-2  left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Password
                      </label>
                      <input
                        autoComplete="off"
                        value={loginData.password}
                        onChange={handleInputChange}
                        name="password"
                        type="password"
                        className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 p-4 text-gray-900 focus:outline-none focus:borer-rose-600 rounded-full"
                        placeholder="Password"
                      />
                    </div>
                    {/* <div class="">
                      <label for="password" class=" text-center mt-2  left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">2FA</label>
                      <input   value={loginData.twofa} onChange={handleInputChange} name="password" type="text" class="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 p-4 text-gray-900 focus:outline-none focus:borer-rose-600 rounded-full" placeholder="Password" />
                    </div> */}
                    <div className="flex justify-center mt-3">
                      <input
                        type="checkbox"
                        className="appearance-none indeterminate:bg-gray-300 ..."
                      />
                    </div>

                    <div className="flex justify-center mt-3">
                      <button
                        className=" bg-colorAccent text-white rounded-full px-8 py-1"
                        onClick={getUserFunc}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
