import React, { useState, useEffect } from "react";
import { Table, Button, Form, Input, Select, Typography, Card, message } from "antd";
import axios from "axios";
import { FaSearch } from "react-icons/fa";

const { Paragraph } = Typography;

const Subscription = () => {
    const [viewdata, setViewdata] = useState([]);
    const [packageList, setPackageList] = useState([]);
    const [username, setUsername] = useState("")
    const [Userdata, setUserdata] = useState("")
    const [inputValue, setInputValue] = useState('');
    const [subcrip, setSubscrip] = useState('')
    const [twofa, setTwoFa] = useState('')


    const { Option } = Select;
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    const [form] = Form.useForm();
    // const onGenderChange = (value) => {
    //     switch (value) {
    //         case "male":
    //             form.setFieldsValue({
    //                 note: "Hi, man!",
    //             });
    //             break;
    //         case "female":
    //             form.setFieldsValue({
    //                 note: "Hi, lady!",
    //             });
    //             break;
    //         case "other":
    //             form.setFieldsValue({
    //                 note: "Hi there!",
    //             });
    //             break;
    //         default:
    //     }
    // };

    const submitSubscription = (values) => {
        const token = localStorage.getItem('token')
        const postData = {
            ...values,
            jwt: token,
            username: Userdata?.Username
        }
        const response = axios({
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API + 'add_subscription',
            data: postData
        }).then((res) => {
            message.success(res.data.Success)
            // console.log(res.data.Success)
        }).catch(err => {
            message.error(err?.response?.data?.Error)
        })
    };

    const getCoindata = () => {
        const token = localStorage.getItem("token");
        const response = axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API + "view_subscription",
            data: { jwt: token },
        })
            .then((res) => {
                setViewdata(res?.data?.Success);
            })
            .catch((err) => {
            });
    };

    const dataSource = [];

    if (viewdata.length > 0) {
        for (let i = 0; i < viewdata.length; i++) {
            dataSource.push({
                dateTime: viewdata[i]?.DateTime,
                price: viewdata[i]?.Price,
                project: viewdata[i]?.Project,
                status: viewdata[i]?.Status,
                subscription_Name: viewdata[i]?.Subscription_Name,
                transID: viewdata[i]?.TransID,
                username: viewdata[i]?.Username,
            });
        }
    }

    const reverseDataSource = [...dataSource].reverse();

    const columns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "DateTime",
            dataIndex: "dateTime",
            key: "dateTime",
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "project",
            dataIndex: "project",
            key: "project",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => {
                const textColor = record.status === "SUCCESS" ? "green" : "red";
                return <span style={{ color: textColor }}>{text}</span>;
            },
        },
        {
            title: "Subscription_Name",
            dataIndex: "subscription_Name",
            key: "subscription_Name",
        },
        {
            title: "TransID",
            dataIndex: "transID",
            key: "transID",
        }
    ];

    const subscriptionPrice = () => {
        const token = localStorage.getItem("token");
        const response = axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API + "subscription_names",
            data: { jwt: token },
        })
            .then((res) => {
                const responseData = res?.data?.Success;
                const updatedResponse = responseData.map((item, index) => {
                    return {
                        ...item,
                        priceAndData: `$${item.price} ${item.name}`,
                        id: index,
                    };
                });

                setPackageList(updatedResponse);
            })
            .catch((err) => {
            });
    };

    useEffect(() => {
        getCoindata();
        subscriptionPrice();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token')
        const postData = {
            jwt: token,
            username: username?.toLowerCase()
        }

        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'get_user',
            data: postData
        }).then(res => {
            setUserdata(res.data.Success[0])
        }).catch(err => {
            message.error(err?.response?.data?.Error)
        })
    };

    const handleAddFund = () => {
        const token = localStorage.getItem('token')
        const postData = {
            jwt: token,
            username: Userdata?.Username || '',
            subscription_name: subcrip,
            totp: twofa
        }
        console.log("postData", postData)

        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'add_subscription',
            data: postData
        }).then(res => {
            console.log(res)
            message.success(res.data.Success)
            getCoindata()
        })
            .catch(err => {
                message.error(err?.response?.data?.Error)
            })
    }

    return (
        <div className="">
            <div className=' grid md:grid-cols-2 px-1 py-2'>
                <form className='flex items-center gap-4' onSubmit={handleSubmit}>
                    <Input
                        type="text"
                        placeholder="Username"
                        className='w-[90%] focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-sm'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <button type="submit" className="bg-colorSecondaryDark py-3 px-4 text-white rounded-xl text-2xl">
                        <FaSearch />
                    </button>
                </form>
            </div>
            <div className=''>
                <div className=' flex flex-col sm:flex-row md:gap-32'>
                    <div className=' sm:w-6/12 md:w-4/12 mx-2'>
                        <div className=" mt-4 bg-white rounded-xl shadow-md overflow-hidden ">
                            <p className='py-4 font-semibold text-xl text-white text-center bg-colorAccent'>User details</p>
                            <div className="p-8 pt-0">
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Name: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Name}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Username: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Username}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Joining date: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Created_On}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Email: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Email}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Telegram ID: </div>
                                    <div className="font-semibold">
                                        <Paragraph copyable>{Userdata?.TelegramID}</Paragraph>

                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Email Verification: </div>
                                    <div className={`${Userdata?.Email_Verification ? "bg-green-500  border-green-500 border-solid text-white" : "bg-red-400 border-dashed text-white"} border   rounded-lg text-black px-4 `}>
                                        {Userdata?.Email_Verification ? "Verified" : "Not Verified"}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>TOTP Activation: </div>
                                    <div className={`${Userdata?.TOTP_Activation ? "bg-green-400 " : "text-red-400 border-dashed border-red-400 hover:bg-red-400 hover:text-white transition-all transition-5s"} border rounded-lg text-black px-4 `}>
                                        {Userdata?.TOTP_Activation ? "Activated" : "Not Activated"}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Wallet Balance: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Wallet_Balance}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Wallet Deposit: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Wallet_Deposit}
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" sm:w-6/12 md:w-4/12 mx-2 col-span-1 top-left-shadow rounded-xl mt-4">
                        <div className=" text-lg font-semibold py-4 pl-2">
                            Add Subscription
                        </div>
                        <Form
                            className=" pr-6"
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            autoComplete="off"
                            onFinish={handleAddFund}
                        >
                            {/* <div className=" text-xl font-semibold pl-4  py-4 mx-4 mb-4">Add Subscription</div> */}

                            <div className=" grid grid-cols-4 py-3">
                                <div className='flex items-center col-span-2 2xl:col-span-1 text-base  ml-2 mb-3 font-semibold'>Subscription :</div>
                                <Form.Item
                                    name="Subscription"
                                    className=" col-span-2 2xl:col-span-3 mb-4"
                                    labelAlign="left">
                                    <Select placeholder="Select subscription " value={subcrip} onChange={(value) => setSubscrip(value)}
                                        className=" focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl  text-sm">
                                        {
                                            packageList.map(item => {
                                                return (
                                                    <>
                                                        <Option value={item?.name}>{item?.priceAndData}</Option>
                                                    </>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className=" grid grid-cols-4 py-3">
                                <div className=' col-span-2 2xl:col-span-1 flex items-center text-base ml-2 mb-3 font-semibold'>Username :</div>
                                <Form.Item
                                    className="col-span-2 2xl:col-span-3 mb-4"
                                    labelAlign="left"
                                >
                                    <Input placeholder="Username"
                                        value={`${Userdata?.Username || ''}`}
                                        className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-lg py-1.5 pl-3 text-sm'
                                    />
                                </Form.Item>
                            </div>

                            <div className=" grid grid-cols-4 pt-3">
                                <div className='flex items-center text-base ml-2 mb-3 font-semibold col-span-2 2xl:col-span-1'>2FA :</div>
                                <Form.Item
                                    name="totp"
                                    labelAlign="left"
                                    className="col-span-2 2xl:col-span-3 mb-4"
                                >
                                    <Input placeholder="2FA"
                                        value={twofa}
                                        onChange={(e) => setTwoFa(e.target.value)}
                                        className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-lg py-1.5 pl-3 text-sm'
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item className=" flex justify-center m-0">
                                <button type="primary" htmlType="submit"
                                    className="  bg-colorAccent top-left-shadow text-lg py-2 px-16 rounded-lg m-9 text-white block">
                                    Submit
                                </button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>

            {/* <div>
                <Form
                    className="mt-4 w-1/2 m-auto bg-white px-4 rounded-xl overflow-hidden"
                    name="basic"
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    // className=" w-2/4 m-auto mt-5"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={submitSubscription}
                    autoComplete="off"
                >
                    <div className=" text-xl font-semibold text-center  py-4 -mx-4 mb-7 bg-colorSecondaryDark/90 text-white">Add Subscription</div>
                    <Form.Item
                        name="username"
                        labelAlign="left"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'please input Username'
                            },
                        ]}
                    >
                        <Input placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="subscription_name"
                        label="Subscription"
                        labelAlign="left"
                        rules={[
                            {
                                required: true,
                                message: 'please select subscription'
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select subscription "
                        >
                            {
                                packageList.map(item => {
                                    return (
                                        <>
                                            <Option value={item?.name}>{item?.priceAndData}</Option>
                                        </>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    <button type="primary" htmlType="submit" className=" bg-colorAccent text-lg px-12 py-1 mt-2 mx-auto rounded-lg text-white block mb-7 ">
                        Submit
                    </button>
                </Form>
            </div> */}
            <div>
                <Table dataSource={reverseDataSource} columns={columns} className="mt-10 texxxxxz"
                scroll={{
                    x: 1200,
                  }}
                />
            </div>
        </div>
    );
};

export default Subscription;
