import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import ActiveApi from '../Component/ActiveApi';
import ActiveCoin from '../Component/ActiveCoin';
import Alluser from './Alluser';
import Createuser from '../Component/Createuser';
import Emailpending from '../Component/Emailpending';
import SuspendUser from '../Component/SuspendUser';
import DeletedUser from '../Component/DeletedUser';
import DeleteRequest from '../Component/DeleteRequest';

const UserManagement = () => {
    const [index, setIndex] = useState(0);

    const handleChangeIndex = (newIndex) => {
        setIndex(newIndex);
    };

    const item = [
        "User List",
        "Add User",
        "Suspended User",
        "Deleted User",
        "Delete Request",
        "Email Pending"
    ]

    return (
        <div>
            <p className=' text-2xl capitalize font-bold container  text-black mx-auto'>User Management</p>
            {/* <div className=' mx-auto my-5 rounded-md'>
                <div className=' flex items-center h-full'>
                    <div className=' grid grid-cols-6 gap-y-5 gap-x-5 container mx-auto w-full'>
                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 0
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow  text-white rounded-3xl px-5  "
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(0)}
                        >User List</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 1
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow text-white rounded-3xl px-5  "
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(1)}
                        >Add User</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 2
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow text-white rounded-3xl px-5  "
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(2)}
                        >Suspended User</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 3
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow text-white rounded-3xl px-5  "
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(3)}
                        >Deleted User</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 4
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow text-white rounded-3xl px-5  "
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(4)}
                        >Delete Request</div>

                        <div className={`col-span-1 py-3 font-medium rounded-xl  px-3 text-center cursor-pointer ${index === 5
                            ? "active-tab bg-colorAccent shadow-neumorphicnew top-left-shadow text-white rounded-3xl px-5  "
                            : "text-black border-solid border-black border "
                            }`}
                            onClick={() => setIndex(5)}
                        >Email Pending</div>
                    </div>
                </div>
            </div> */}

            <div className=' grid grid-cols-2 xl:grid-cols-6 gap-x-4 gap-y-4 w-full py-6 px-5 mb-16 md:mb-0 rounded-lg'>
                {item.map((tab, tabIndex) => (
                    <div
                        key={tabIndex}
                        className={`col-span-1 text-sm py-3 font-medium rounded-xl top-left-shadow px-3 text-center cursor-pointer ${index === tabIndex
                            ? "active-tab bg-colorAccent shadow-neumorphicnew text-white rounded-3xl "
                            : "text-black border-none"
                            }`}
                        onClick={() => setIndex(tabIndex)}
                    >{tab}</div>
                ))}
            </div>

            <div className="">
                <SwipeableViews
                    index={index}
                    onChangeIndex={handleChangeIndex}
                    className=""
                >
                    <div className='container mx-auto border border-solid border-gray-300 rounded-md p-2'>
                        <Alluser />
                    </div>
                    <div className='container mx-auto border border-solid border-gray-300 rounded-md p-2'>
                        <Createuser />
                    </div>
                    <div className='container mx-auto border border-solid border-gray-300 rounded-md p-2'>
                        <SuspendUser />
                    </div>
                    <div className='container mx-auto border border-solid border-gray-300 rounded-md p-2'>
                        <DeletedUser />
                    </div>
                    <div className='container mx-auto border border-solid border-gray-300 rounded-md p-2'>
                        <DeleteRequest />
                    </div>
                    <div className='container mx-auto border border-solid border-gray-300 rounded-md p-2'>
                        <Emailpending />
                    </div>
                </SwipeableViews>
            </div>
        </div>
    )
}

export default UserManagement
