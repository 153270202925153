import axios from 'axios'
import React, { useEffect, useState } from 'react'

const EmailTemplates = () => {
    const [emailData, setEmialData] = useState([])

    const getEmailData = () => {
        const token = localStorage.getItem('token')

        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'email_templates',
            data: { jwt: token }
        }).then(res => {
            setEmialData(res.data.Success)
        }).catch(err => {

        })
    }

    useEffect(() => {
        getEmailData()
    }, [])

    return (
        <div className=' container mx-auto'>
            <p className=' text-xl font-semibold mb-4 ml-2'>Email Templates</p>

            {
                emailData.map((item) => {
                    return (
                        <>
                            <div className=' bg-white py-3 px-5 rounded-2xl mb-8' >
                                <p className=' mb-1'> <span className=' font-semibold'>Subject :</span> {item.Subject}</p>
                                <p className=' mb-1'><span className=' font-semibold'>Type :</span>  {item.Type}</p>
                                <p className=' mb-1'><span className=' font-semibold'>Message :</span> {item.Message}</p>
                            </div>
                        </>
                    )
                })
            }
        </div>
    )
}

export default EmailTemplates
