import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table } from 'antd';


const Logs = () => {
    const [logs, setLogs] = useState([]);
    const handleAddFund = () => {
        const token = localStorage.getItem('token')
        const postData = {
            jwt: token
        }

        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'logs',
            data: postData
        }).then((res) => {
            setLogs(res.data.Success)
        }).catch(err => {
        })
    }
    useEffect(() => {
        handleAddFund()
    }, [])

    const dataSource = []
    if (logs.length > 0) {
        for (let i = 0; i < logs.length; i++) {
            dataSource.push({
                action : logs[i].Action,
                admin : logs[i].Admin,
                createdat : logs[i].Createdat,
            })
        }
    }
    const columns = [
        {
            title : "Created At",
            dataIndex : 'createdat',
            key : 'createdat',
            width : 200
        },
        {
            title : "Action",
            dataIndex : 'action',
            key : 'action',
            width : 200
        },
        {
            title : "Admin",
            dataIndex : 'admin',
            key : 'admin',
            width : 200
        },
    ]

    const paginationConfig = {
        pageSize: 20,
        total: dataSource.length, 
        showSizeChanger: false,
      };


    return (
        <div>
            <p className=' text-xl mb-4 font-semibold  ml-2'>Logs</p>
            <div  className='top-left-shadow m-6 rounded-xl w-[96%] mx-auto'>        
            <Table columns={columns} dataSource={dataSource} pagination={paginationConfig}
             scroll={{
                x: 1200,
              }}
            />
            </div>
        </div>
    )
}

export default Logs
