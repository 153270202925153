import './App.css';
import Routerpage from './Router/RouterPage';

function App() {
  return (
    <div className="App">
     <Routerpage/>
    </div>
  );
}

export default App;
