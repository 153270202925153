// import { Table } from 'antd';
// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { Input } from 'antd';
// const { Search } = Input;

// function DepositTable({ username }) {
//   const [subdata, setSubData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(20);
//   const [totalDataCount, setTotalDataCount] = useState(0);
//   const [filter, setFilter] = useState("ALL");
//   const [searchText, setSearchText] = useState('');

//   const handlepackage = (page, size) => {
//     const token = localStorage.getItem('token');
//     const postData = {
//       jwt: token,
//       username: username,
//       page_number: page,
      
//     };

//     axios({
//       method: 'POST',
//       headers: { "Content-Type": "application/json" },
//       url: 'http://192.168.0.248:8000/admin/user_deposit',
//       data: postData
//     }).then(res => {
//       setSubData(res.data.Success);
//       setTotalDataCount(res.data.TotalPages * size);
//       console.log(res, "opdifodif");
//     }).catch(err => {
//       console.log(err);
//     });
//   };

//   useEffect(() => {
//     handlepackage(currentPage, pageSize);
//   }, [currentPage, pageSize, username, filter]);

//   const handleFilterClick = (filterType) => {
//     setFilter(filterType);

//   };
//   const handleTableChange = (pagination) => {
//     const { current, pageSize } = pagination;
//     setCurrentPage(current);
//     setPageSize(pageSize);
//   };

//       const subColumns = [
//             {
//                 title: <p className='p-1 text-center rounded-lg table-cell'>Entry Price</p>,
//                 dataIndex: 'Entry_Price',
//                 key: 'Entry_Price',
//                 width: '20%'
//             },
//             {
//                 title: <p className='p-1 text-center rounded-lg table-cell'>Entry Time
//                 </p>,
//                 dataIndex: 'Entry_Time',
//                 key: 'Entry_Time',
//                 width: '20%'
//             },
//             {
//                 title: <p className='p-1 text-center rounded-lg table-cell'>Exit Time
//                 </p>,
//                 dataIndex: 'Exit_Time',
//                 key: 'Exit_Time',
//                 width: '20%'
//             },
//             {
//                 title: <p className='p-1 text-center rounded-lg table-cell'>Margin</p>,
//                 dataIndex: 'Margin',
//                 key: 'Margin',
//                 width: '20%'
//             },
//             {
//                 title: <p className='p-1 text-center rounded-lg table-cell'>PnL Percentage
//                 </p>,
//                 dataIndex: 'TransID',
//                 key: 'PnL_Percentage',
//                 width: '20%'
//             },
//             {
//                 title: <p className='p-1 text-center rounded-lg table-cell'>PnL USDT

//                 </p>,
//                 dataIndex: 'PnL_USDT',
//                 key: 'PnL_USDT',
//                 width: '20%'
//             },
//             {
//                 title: <p className='p-1 text-center rounded-lg table-cell'>Size
//                 </p>,
//                 dataIndex: 'Size',
//                 key: 'Size',
//                 width: '20%'
//             },
//             {
//                 title: <p className='p-1 text-center rounded-lg table-cell'>Symbol
//                 </p>,
//                 dataIndex: 'Symbol',
//                 key: 'Symbol',
//                 width: '20%'
//             },
    
//         ]
    
//         const subDatasou = []
//         if (subdata.length > 0) {
//             for (let i = 0; i < subdata.length; i++) {
//                 subDatasou.push({
//                     Entry_Price: <p className='p-1 rounded-lg text-center'>{subdata[i].Entry_Price}</p>,
//                     Entry_Time: <p className='p-1 rounded-lg text-center'>{subdata[i].Entry_Time}</p>,
//                     Exit_Time: <p className='p-1 rounded-lg text-center'>{subdata[i].Exit_Time}</p>,
//                     Margin: <p className='p-1 rounded-lg text-center'>{subdata[i].Margin}</p>,
//                     PnL_USDT: <p className='p-1 rounded-lg text-center'>{subdata[i].PnL_USDT}</p>,
//                     PnL_Percentage: <p className='p-1 rounded-lg text-center'>{subdata[i].PnL_Percentage}</p>,
//                     PnL_USDT: <p className='p-1 rounded-lg text-center'>{subdata[i].PnL_USDT}</p>,
//                     Size: <p className='p-1 rounded-lg text-center'>{subdata[i].Size}</p>,
//                     Symbol: <p className='p-1 rounded-lg text-center'>{subdata[i].Symbol}</p>,
//                 })
//             }
//         }

//         const handleSearch = (value) => {
//             setSearchText(value);
//           };
        
//           const onSearchChange = (e) => {
//             handleSearch(e.target.value);
//           };
        
//           const onClearSearch = () => {
//             setSearchText('');
//           };
        
//           const filteredDataSource = subDatasou.filter((item) =>
//             Object.values(item).some((val) =>
//               val.toString().toLowerCase().includes(searchText.toLowerCase())
//             )
//           );

//   return (
//     <div>
//          <div>
//           <div className="flex justify-between items-center">
//             <div className="flex justify-start flex-col items-start lg:space-x-4 ">
//               {/* <label
//                 htmlFor="selectOption3"
//                 className="text-colorSecondaryDark font-semibold text-center ml-10"
//               >
//                 Exchange
//               </label> */}
//               <div className="p-2 ">
//                 <div className="flex mb-4 items-center justify-end">
//                   <button
//                     className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
//                       ? "bg-colorSecondaryDark text-white border-2 border-white "
//                       : "bg-white text-colorSecondaryDark"
//                       }`}
//                     // onClick={() => handleFilterClick("ALL")}
//                     onClick={() => {
//                       handleFilterClick("ALL");
//                       setFilter("ALL");
//                     }}
//                   >
//                     All
//                   </button>
//                   <button
//                     className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "SPOT"
//                       ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
//                       : "bg-white text-colorSecondaryDark"
//                       }`}
//                     // onClick={() => handleFilterClick("SPOT")}
//                     onClick={() => {
//                       handleFilterClick("SPOT");
//                       setFilter("SPOT");
//                     }}
//                   >
//                     Spot
//                   </button>
//                   <button
//                     className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "FUTURES"
//                       ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
//                       : "bg-white text-colorSecondaryDark"
//                       }`}
//                     // onClick={() => handleFilterClick("FUTURES")}
//                     onClick={() => {
//                       handleFilterClick("FUTURES");
//                       setFilter("FUTURES");
//                     }}
//                   >
//                     Future
//                   </button>
//                 </div>
//               </div>
//             </div>

//             <Search
//               placeholder="Search..."
//               allowClear
//               onChange={onSearchChange}
//               onClear={onClearSearch}
//               style={{ marginBottom: 16 }}
//               className="w-64"
//             />
//           </div>
//         </div>
//        <Table
//         columns={subColumns}
//         dataSource={filteredDataSource}
//         pagination={{
//           current: currentPage,
//           pageSize: pageSize,
//           total: totalDataCount,
//           showSizeChanger: true,
//           pageSizeOptions: [],
//           // showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items | Page ${Math.ceil(range[1] / pageSize)}`,
//         }}
//         onChange={handleTableChange}
//         size='small'
//         bordered
//       />     
    
//     </div>
//   );
// }

// export default DepositTable;

import React from 'react'

function DepositTable() {
  return (
    <div>
      Coming Soon...
    </div>
  )
}

export default DepositTable
