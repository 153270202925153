import { Button, Card, Form, Input, Upload, message } from 'antd';
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';

function Image() {
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [fileIds, setFileIds] = useState([]);

  const getData = () => {
    const postData = {
      file_id: name,
    };

    fetch("http://192.168.0.247:8000/admin/viewfile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.Error);
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        message.success("Image loaded successfully");
      })
      .catch((error) => {
        console.error(error);
        message.error(error.message || "File Not Found");
      });
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append('files', file);

    try {
      const response = await fetch("http://192.168.0.247:8000/admin/uploadfile", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setFileIds(data.file_ids);
        message.success("File uploaded successfully");
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      console.error(error);
      message.error(error.message || "File Not Found");
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      handleUpload(file);
      return false; 
    },
  };

  return (
    <div>
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
    >
      <Form.Item
        label="File Id"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input value={name} onChange={(e) => setName(e.target.value)} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
        <button onClick={getData}>Fetch Image</button>
      </Form.Item>
    </Form>

    <Card title="Image Response" style={{ width: 300 }}>
      <div>
        {imageUrl && typeof imageUrl === 'string' && (
          <img
            src={imageUrl}
            alt="Image"
            style={{ maxWidth: '100%', maxHeight: '300px' }}
          />
        )}
      </div>
    </Card>

    <Upload {...uploadProps}>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>

    <div>
      <h3>Uploaded File IDs:</h3>
      <ul>
        {fileIds.map((fileId) => (
          <li key={fileId}>{fileId}</li>
        ))}
      </ul>
    </div>
  </div>
  );
}

export default Image;
