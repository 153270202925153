import { Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
const { Search } = Input;

function TradesTable({ username }) {
  const [subdata, setSubData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(10);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [filter, setFilter] = useState("ALL");
  const [searchText, setSearchText] = useState('');


  const handlepackage = (page, size) => {
    const token = localStorage.getItem('token');
    const postData = {
      jwt: token,
      username: username,
      page_number: page,
      number_of_data: size,
      exchange_type: filter,
      trade_type: "ALL",
      symbol: [],
      botname: []
    };

    axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'user_trade_hist',
      // url: 'http://192.168.0.248:8000/admin/user_trade_hist',
      data: postData
    }).then(res => {
      const resposneData = res.data.Success
      const formattedData = resposneData.map(item => ({
        ...item,
        DateTime: new Date(item?.DateTime).toLocaleDateString('en-GB') + ' ' + new Date(1701540808180).toLocaleTimeString()
      }))
      setSubData(formattedData);
      setTotalDataCount(res.data.TotalPages * size);
    }).catch(err => {
    });
  };

  useEffect(() => {
    handlepackage(currentPage, pageSize);
  }, [currentPage, pageSize, username, filter]);

  const handleFilterClick = (filterType) => {
    setFilter(filterType);

  };
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const subColumns = [
    
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Type
      </p>,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => (
        <span>
          {text}
        </span>
      ),
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>DateTime
      </p>,
      dataIndex: 'DateTime',
      key: 'DateTime',
      render: (text) => (
        <span>
          {text}
        </span>
      ),
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>BotName
      </p>,
      dataIndex: 'BotName',
      key: 'BotName',
      render: (text) => (
        <span>
          {text}
        </span>
      ),
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>OrderID
      </p>,
      dataIndex: 'OrderID',
      key: 'OrderID',
      // width: 300
    },
    // {
    //   title: <p className='p-1 text-center rounded-lg table-cell'>Username</p>,
    //   dataIndex: 'Username',
    //   key: 'Username',
    //   width: '20%'
    // },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>API Name</p>,
      dataIndex: 'API_Name',
      key: 'API_Name',
      // width: '20%'
    },
    
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Symbol
      </p>,
      dataIndex: 'Symbol',
      key: 'Symbol',
      // width: '20%'
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Order Type
      </p>,
      dataIndex: 'Order_Type',
      key: 'Order_Type',
      // width: '20%'
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Price</p>,
      dataIndex: 'Price',
      key: 'Price',
      // width: '20%'
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Quantity
      </p>,
      dataIndex: 'Quantity',
      key: 'Quantity',
      // width: '20%'
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Side
      </p>,
      dataIndex: 'Side',
      key: 'Side',
      // width: '20%'
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Trade Direction
      </p>,
      dataIndex: 'Trade_Direction',
      key: 'Trade_Direction',
      // width: '20%',
      render: (Trade_Direction) => {
        return <span className={`${Trade_Direction == "LONG" ? " bg-green-500" : " bg-red-500"}`}>{Trade_Direction}</span>;
      }
    },
  ]

  const subDatasou = []
  if (subdata.length > 0) {
    for (let i = 0; i < subdata.length; i++) {
      subDatasou.push({
        OrderID: <p className='p-1 rounded-lg text-center'>{subdata[i]?.OrderID}</p>,
        Username: <p className='p-1 rounded-lg text-center'>{subdata[i]?.Username}</p>,
        API_Name: <p className='p-1 rounded-lg text-center'>{subdata[i]?.API_Name}</p>,
        Order_Type: <p className='p-1 rounded-lg text-center'>{subdata[i]?.Order_Type}</p>,
        Price: <p className='p-1 rounded-lg text-center'>{subdata[i]?.Price}</p>,
        Quantity: <p className='p-1 rounded-lg text-center'>{subdata[i]?.Quantity}</p>,
        Side: <p className={`p-1 rounded-lg text-center ${subdata[i]?.Side == "BUY" ? " text-green-500" : " text-red-500"}`}>{subdata[i]?.Side}</p>,
        Type: <p className={`p-1 rounded border-l-4 text-left font-bold pl-2
         ${subdata[i]?.Type == "PAPER" ? " border-[#122b45] " : " border-[#76c3c5] "}
        `}>{subdata[i]?.Type}</p>,
        Size: <p className='p-1 rounded-lg text-center'>{subdata[i]?.Size}</p>,
        Trade_Direction: <p className={`p-1 rounded-lg text-center ${subdata[i]?.Trade_Direction == "LONG" ? " text-green-500" : " text-red-500"}`}>{subdata[i]?.Trade_Direction}</p>,
        Symbol: <p className='p-1 rounded-lg text-center'>{subdata[i]?.Symbol}</p>,
        BotName: <p className='p-1 rounded-lg text-center'>{subdata[i]?.BotName}</p>,
        DateTime: <p className='p-1 rounded-lg text-center'>{subdata[i]?.DateTime}</p>,
      })
    }
  }

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const onSearchChange = (e) => {
    handleSearch(e.target.value);
  };

  const onClearSearch = () => {
    setSearchText('');
  };

  const filteredDataSource = subDatasou.filter((item) =>
    Object.values(item).some((val) =>
      val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <div>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex justify-start flex-col items-start lg:space-x-4 ">
            {/* <label
                htmlFor="selectOption3"
                className="text-colorSecondaryDark font-semibold text-center ml-10"
              >
                Exchange
              </label> */}
            <div className="p-2 ">
              <div className="flex mb-4 items-center justify-end">
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
                    ? "bg-colorSecondaryDark text-white border-2 border-white "
                    : "bg-white text-colorSecondaryDark"
                    }`}
                  // onClick={() => handleFilterClick("ALL")}
                  onClick={() => {
                    handleFilterClick("ALL");
                    setFilter("ALL");
                  }}
                >
                  All
                </button>
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "SPOT"
                    ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-colorSecondaryDark"
                    }`}
                  // onClick={() => handleFilterClick("SPOT")}
                  onClick={() => {
                    handleFilterClick("SPOT");
                    setFilter("SPOT");
                  }}
                >
                  Spot
                </button>
                <button
                  className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "FUTURES"
                    ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-colorSecondaryDark"
                    }`}
                  // onClick={() => handleFilterClick("FUTURES")}
                  onClick={() => {
                    handleFilterClick("FUTURES");
                    setFilter("FUTURES");
                  }}
                >
                  Future
                </button>
              </div>
            </div>
          </div>

          <Search
            placeholder="Search..."
            allowClear
            onChange={onSearchChange}
            onClear={onClearSearch}
            style={{ marginBottom: 16 }}
            className="w-64"
          />
        </div>
      </div>
      <Table
        columns={subColumns}
        dataSource={filteredDataSource}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalDataCount,
          showSizeChanger: true,
          pageSizeOptions: [],
          // showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items | Page ${Math.ceil(range[1] / pageSize)}`,
        }}
        onChange={handleTableChange}
        size='small'
        bordered
        scroll={{
          x: 1500,
        }}
      />

    </div>
  );
}

export default TradesTable;
