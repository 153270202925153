import React, { useEffect, useState } from 'react'
import { Input, message, Select, Tabs } from 'antd'
import { FaSearch } from "react-icons/fa";
import axios from 'axios';
import LiveTransactioncopy from '../Component/LiveTransactioncopy';
import MainFuture from './MainFuture';
import Mainspot from './Mainspot';

const Binancecopy = () => {
    const [username, setUsername] = useState()
    const [userdata, setUserdata] = useState("")
    const [option, setOption] = useState([])
    const [spotData, setSpotData] = useState([])
    const [futureData, setFutureData] = useState([])

    const getData = () => {
        const token = localStorage.getItem('token');
        const postData = {
            jwt: token,
            username: username?.toLowerCase(),
        };
        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'user_api_list',
            data: postData
        }).then(res => {
            const responseData = res?.data?.Success
            const transformedOptions = responseData?.map(item => ({ ...item, label: item?.API_Name?.split('_').join(' '), value: item?.API_Name }));
            const future = transformedOptions.filter(item => item?.Exchange_Type == "FUTURES")
            const spot = transformedOptions.filter(item => item?.Exchange_Type == "SPOT")
            setOption(transformedOptions)
            setSpotData(spot)
            setFutureData(future)
        }).catch(err => {})
    };

    useEffect(() => {
        getData()
    }, [userdata])

    useEffect(() => {
        const container = document.getElementById('scrollContainer');
        if (container) {
            container.style.scrollBehavior = 'smooth';
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token')
        const postData = {
            jwt: token,
            username: username?.toLowerCase()
        }

        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'get_user',
            data: postData
        }).then(res => {
            setUserdata(res.data.Success[0])
        }).catch(err => {
            message.error(err?.response?.data?.Error)
        })
    };

    const items = [
        {
            key: '1',
            label: <div className='px-4 md:px-10 text-xs md:text-sm'>SPOT</div>,
            children: <Mainspot data={{ username: userdata?.Username?.toLowerCase(), option: spotData }} />,
        },
        {
            key: '2',
            label: <div className='px-4 md:px-10 text-xs md:text-sm'>FUTURES</div>,
            children: <MainFuture data={{ username: userdata?.Username?.toLowerCase(), option: futureData }} />,
        },
        {
            key: '3',
            label: <div className='px-4 md:px-10 text-xs md:text-sm'>TRANSACTION</div>,
            children: <LiveTransactioncopy data={{ username: userdata?.Username?.toLowerCase(), option: option }} />,
        },
    ];

    return (
        <>
            <div className=' grid grid-cols-1 md:grid-cols-2 px-2 py-2'>
                <form className='flex items-center gap-4' onSubmit={handleSubmit}>
                    <Input
                        type="text"
                        placeholder="Username"
                        className='w-[90%] focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-sm'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <button type="submit" className="bg-colorSecondaryDark py-3 px-4 text-white rounded-xl text-2xl">
                        <FaSearch />
                    </button>
                </form>
            </div>
            <div>
                <Tabs className='tabakhil' defaultActiveKey="1" items={items}  />
            </div>
        </>
    )
}
export default Binancecopy
