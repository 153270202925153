import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table } from 'antd'
import { useLocation } from 'react-router-dom'

const AllApi = () => {
    const [dataSource, setDataSource] = useState([])
    const [filter, setFilter] = useState("ALL");
    const location = useLocation()
    const apiType = location.state?.type

    useEffect(() => {
        if(apiType?.length > 0){
            setFilter(apiType)
        }
    },[apiType])

    console.log('dataSource', dataSource.slice(0 , 5))

    const getApi = () => {
        const token = localStorage.getItem('token')
       const postData = {
         jwt : token
       }
        const response = axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API + 'view_all_apis',
            data: postData
        }).then((res) => {
            const successData = res?.data?.Success
            const updatedData = successData.map((item, index) => ({
                ...item,
                // isActive: item.Status === "ACTIVE",
                key: index,
            }))
            setDataSource(updatedData)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        getApi();
    }, []);

    const expandedRowRender = (record) => {
        console.log("record", record?.CoyBot)

        const recordData = record?.CoyBot
        const dataSource = []

        if (recordData) {
            for (let i = 0; i < recordData.length; i++) {
                // console.log(recordData[i])
                dataSource.push({
                    BotName: recordData[i]?.BotName,
                    DateTime: recordData[i]?.DateTime,
                    Investment_Risk: recordData[i]?.Investment_Risk,
                    Leverage: recordData[i]?.Leverage,
                    Symbol: recordData[i]?.Symbol,
                    Total_Investment: recordData[i]?.Total_Investment,
                })
            }
        }

        console.log("dataSource", dataSource)


        // const columns = [
        //     {
        //         title: "Asset",
        //         dataIndex: "asset",
        //         key: "asset",
        //     },
        //     ...Object.keys(record.Initial).map((asset) => ({
        //         title: asset,
        //         dataIndex: asset,
        //         key: asset,
        //         // render: (value, record) => {
        //         //   if (asset === 'USDT') {
        //         //   }
        //         //   return value;
        //         // },
        //     })),

        //     {
        //         title: (
        //             <div className='bg-colorAccent text-white text-center py-1 px-3 rounded' >USDT</div>
        //             ),
        //         dataIndex: 'USDT',
        //         key: 'USDT',
        //         render: (text) => {
        //             return <div className=' bg-colorAccent text-white text-center py-1 px-3 rounded'>{text}</div>;
        //         },
        //     },
        // ];

        // const dataSource = [
        //     {
        //         key: "Initial ",
        //         asset: "Initial (Qty)",
        //         ...record.Initial,
        //     },
        //     {
        //         key: "Invested",
        //         asset: "Bot Allotted Fund (Qty)",
        //         ...record.Invested,
        //     },
        //     {
        //         key: "Balance",
        //         asset: "Balance (Qty)",
        //         ...record.Balance,
        //     },
        // ];

        const columns = [
            {
                title: "BotName",
                dataIndex: "BotName",
                key: "BotName",
            },
            {
                title: "DateTime",
                dataIndex: "DateTime",
                key: "DateTime",
            },
            {
                title: "Investment_Risk",
                dataIndex: "Investment_Risk",
                key: "Investment_Risk",
            },
            {
                title: "Leverage",
                dataIndex: "Leverage",
                key: "Leverage",
            },
            {
                title: "Symbol",
                dataIndex: "Symbol",
                key: "Symbol",
            },
            {
                title: "Total_Investment",
                dataIndex: "Total_Investment",
                key: "Total_Investment",
            },
        ]

        return (
            <Table
                size="medium"
                columns={columns}
                className="newtable py-4 px-2"
                dataSource={dataSource}
                pagination={false}
            />
        );
    };

    const generateUniqueKeys = (data) => {
        return data.map((item, index) => `${item.APIID}_${index}`);
    };

    const defaultColumns = [
        {
            title: "Type",
            dataIndex: "Type",
            key: "Type",
            width: "10%",
            editable: true,
            render: (text) => {
                return (<div className={` border-l-4 rounded pl-2 ${text == "PAPER" ? " border-[#122b45] " : " border-[#76c3c5] "}`} >{text}</div>)
            }
        },
        {
            title: "Username",
            dataIndex: "Username",
            key: "Username",
            editable: true,
            width: "20%",
            render: (text) => {
                return (<span>{text}</span>)
            }
        },
        {
            title: "Created On",
            dataIndex: "Created_On",
            key: "Created_On",
            editable: true,
            width: "20%",
        },
        {
            title: "Type",
            dataIndex: "Type",
            key: "Type",
            editable: true,
            width: "20%",
        },
        {
            title: "Exchange Type",
            dataIndex: "Exchange_Type",
            key: "Exchange_Type",
            editable: true,
            width: "20%",
        },
        {
            title: "API Name",
            dataIndex: "API_Name",
            key: "API_Name",
            editable: true,
            width: "20%",
        },
    ];

    const allColumns = [...defaultColumns];

    const columns = allColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                width: 10,
            }),
        };
    });
    
    const filteredData = dataSource.filter(item => {
        if (filter === "ALL") {
          return true; 
        } else {
          return item.Type === filter; 
        }
      });

    return (
        <div>

            <div className="p-2 ">
                <div className="flex mb-4 items-center justify-end">
                    <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
                            ? "bg-colorSecondaryDark text-white border-2 border-white "
                            : "bg-white text-colorSecondaryDark"
                            }`}
                        onClick={() => {
                            setFilter("ALL");
                        }}
                    >
                        All
                    </button>
                    <button
                        className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "LIVE"
                            ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                            : "bg-white text-colorSecondaryDark"
                            }`}
                        onClick={() => {
                            setFilter("LIVE");
                        }}
                    >
                        LIVE
                    </button>
                    <button
                        className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "PAPER"
                            ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                            : "bg-white text-colorSecondaryDark"
                            }`}
                        onClick={() => {
                            setFilter("PAPER");
                        }}
                    >
                        PAPER
                    </button>
                </div>
            </div>


            <Table dataSource={filteredData} columns={columns}
                pagination={{
                    pageSize: 20,
                }}
                // onChange={handleTableChange}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: generateUniqueKeys(dataSource),
                }}
                scroll={{
                    x: 1000,
                }}
            />
        </div>
    )
}

export default AllApi
