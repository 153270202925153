import React, { useState } from 'react';

function CheckBalance() {
  // State to manage the selected option
  const [selectedOption, setSelectedOption] = useState('');

  // Handler function to handle option selection
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      Comming soon...
      {/* <h1>API NAMES</h1>
      <label htmlFor="balanceType">Select API NAME: </label>
      <select id="balanceType" value={selectedOption} onChange={handleOptionChange}>
        <option value="">Select One</option>
        <option value="savings">Savings</option>
        <option value="checking">Checking</option>
        <option value="credit">Credit</option>
      </select>

      {selectedOption && (
        <div>
          <h2>Balance for {selectedOption} account</h2>
        </div>
      )} */}
    </div>
  );
}

export default CheckBalance;
