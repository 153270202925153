import React, { useEffect, useState } from 'react'
import { Input, Typography, message, Tabs } from 'antd'
import {FaSearch } from "react-icons/fa";
import axios from 'axios';
import SwipeableViews from 'react-swipeable-views';
import Packagetable from '../Component/Packagetable';
import TradesTable from '../Component/TradesTable';
import DepositTable from '../Component/DepositTable';
import CheckBalance from '../Component/CheckBalance';
import { ReportTable } from '../Component/ReportTable';
import Api from '../Component/Api';
import TodaysTrades from '../Component/TodaysTrades';
import CopyBottablecopy from '../Component/CopyBottablecopy';
import SearchDeposit from '../Component/SearchDeposit';

const { Paragraph } = Typography;

const Activeusercopy = () => {
    const [username, setUsername] = useState("")
    const [Userdata, setUserdata] = useState("")
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const container = document.getElementById('scrollContainer');
        if (container) {
            container.style.scrollBehavior = 'smooth';
        }
    }, []);


    const handleChangeIndex = (newIndex) => {
        setIndex(newIndex);
    };

    const tabs = [
        'PACKAGE',
        'API',
        'COPY BOT',
        'TRADES',
        'REPORT',
        'FUEL',
        'Deposit',
        'Balance',
        'Open Position',
        'Position History',
        'Today\'s Trade',
        'Coming Soon...',
    ];


    const handleSubmit = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token')
        const postData = {
            jwt: token,
            username: username?.toLowerCase()
        }

        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'get_user',
            data: postData
        }).then(res => {
            setUserdata(res.data.Success[0])
        }).catch(err => {
            message.error(err?.response?.data?.Error)
        })
    };

    return (
        <>
              <div className=' grid md:grid-cols-2 p-1'>
                <form className='flex items-center gap-4' onSubmit={handleSubmit}>
                    <Input
                        type="text"
                        placeholder="Username"
                        className='w-[90%] focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-gray-500 rounded-xl py-3 pl-5 text-sm'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <button type="submit" className="bg-colorSecondaryDark py-3 px-4 text-white rounded-xl text-2xl">
                        <FaSearch />
                    </button>
                </form>
            </div>
            <div className=''>
                <div className=' grid grid-cols-3 gap-4 px-1'>
                    <div className=' col-span-3 sm:col-span-2 md:col-span-1 '>
                        <div className=" mt-4 bg-white rounded-xl shadow-md overflow-hidden ">
                            <p className='py-4 font-semibold text-xl text-white text-center bg-colorAccent'>User details</p>
                            <div className="p-8 pt-0">
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Name: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Name}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Username: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Username}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Joining date: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Created_On}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Email: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Email}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Telegram ID: </div>
                                    <div className="font-semibold">
                                        <Paragraph copyable>{Userdata?.TelegramID}</Paragraph>

                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Email Verification: </div>
                                    <div className={`${Userdata?.Email_Verification ? "bg-green-500  border-green-500 border-solid text-white" : "bg-red-400 border-dashed text-white"} border   rounded-lg text-black px-4 `}>
                                        {Userdata?.Email_Verification ? "Verified" : "Not Verified"}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>TOTP Activation: </div>
                                    <div className={`${Userdata?.TOTP_Activation ? "bg-green-400 " : "text-red-400 border-dashed border-red-400 hover:bg-red-400 hover:text-white transition-all transition-5s"} border rounded-lg text-black px-4 `}>
                                        {Userdata?.TOTP_Activation ? "Activated" : "Not Activated"}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Wallet Balance: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Wallet_Balance}
                                    </div>
                                </p>
                                <p className="mt-2 text-gray-800  flex justify-between items-center">
                                    <div>Wallet Deposit: </div>
                                    <div className="font-semibold">
                                        {Userdata?.Wallet_Deposit}
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=' col-span-3 md:col-span-2 md:mt-5 w-full  rounded-xl shadow-md bg-white'>
                        <div>
                            <p className='text-xl capitalize font-semibold mt-2 text-black w-[96%] mx-auto'>User Management</p>
                            <div className='w-[96%] mx-auto my-6 rounded-md'>
                                <div className='flex items-center h-full transform translate-x-0 translate-y-0'>
                                    <div className='grid grid-cols-2 sm:grid-cols-3 gap-4 xl:gap-x-10 xl:gap-y-5  xl:px-20 w-full'>
                                        {tabs.map((tab, tabIndex) => (
                                            <div
                                                key={tabIndex}
                                                className={`col-span-1 py-3 font-medium rounded-xl top-left-shadow px-3 text-center cursor-pointer ${index === tabIndex
                                                    ? "active-tab bg-colorAccent shadow-neumorphicnew text-white rounded-3xl px-5"
                                                    : "text-black border-none"
                                                    }`}
                                                onClick={() => setIndex(tabIndex)}
                                            >
                                                {tab}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {(
                <div className='mt-8'>
                    <div className="">
                        <SwipeableViews
                            index={index}
                            onChangeIndex={handleChangeIndex}
                            className="mt-5 mx-4 "
                        >
                            <div>
                                <Packagetable username={Userdata?.Username?.toLowerCase()}  />
                            </div>
                            <div>
                                <Api username={Userdata?.Username?.toLowerCase()} />
                            </div>
                            <div>
                                {/* <CopyBottable username={username} /> */}
                                <CopyBottablecopy username={Userdata?.Username?.toLowerCase()} />
                            </div>
                            <div>
                                <TradesTable username={Userdata?.Username?.toLowerCase()} />
                            </div>
                            <div>
                                <ReportTable username={Userdata?.Username?.toLowerCase()} />
                                {/* <ReportTablecopy username={username} /> */}
                            </div>      
                            <div>
                                <DepositTable username={Userdata?.Username?.toLowerCase()} />
                            </div>
                            <div>
                                <SearchDeposit username={Userdata?.Username?.toLowerCase()} />
                            </div>
                            <div>
                                <CheckBalance />
                            </div>
                            <div>
                                <DepositTable />
                            </div>
                            <div>
                                <DepositTable />
                            </div>
                            <div>
                                <TodaysTrades username={Userdata?.Username?.toLowerCase()} />
                            </div>
                            <div>
                                <DepositTable />
                            </div>
                        </SwipeableViews>
                    </div>
                </div>
            )}

        </>
    )
}

export default Activeusercopy
