import React, { useState, useEffect, useSyncExternalStore } from "react";
import { Button, Card, Checkbox, Form, Input, InputNumber, Table, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const User = () => {
  const [createEmail, setCreateEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [viewuserdata, setViewuserdata] = useState([]);

  const navigate = useNavigate();

  const redirect = () => {
    navigate("/");
  };

  useEffect(() => {
    // ViewUser();
  }, []);


  const createUser = (value) => {
    const token = localStorage.getItem('token')
    const postData = {
      ...value,
      totp: value.totp == undefined ? "" : value.totp,
      jwt: token
    }

    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'add_user',
      data: postData
    }).then((res) => {
      message.success(res?.data?.Success)
      ViewUser()
    }).catch(err => {
      message.error(err?.response?.data?.Error)
    })
  }

  const ViewUser = () => {
    const token = localStorage.getItem('token')

    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'view_all_users',
      data: { jwt: token }
    }).then(res => {
      setViewuserdata(res?.data?.Success.User)
    }).catch(err => {
    })
  };

  useEffect(() => {
    ViewUser()
  }, [])

  const dataSource = []

  if (viewuserdata.length > 0) {
    for (let i = 0; i < viewuserdata.length; i++) {
      dataSource.push({
        username: viewuserdata[i]?.Username,
        name: viewuserdata[i]?.Name,
        email: viewuserdata[i]?.Email,
        wallet_Balance: viewuserdata[i]?.Wallet_Balance,
        wallet_Deposit: viewuserdata[i]?.Wallet_Deposit,
        email_Verification: viewuserdata[i]?.Email_Verification,
        subscription_Name: viewuserdata[i]?.Subscription_Name,
        totp_Activation: viewuserdata[i]?.TOTP_Activation,
        telegramID: viewuserdata[i]?.TelegramID,
      })
    }
  }

  const reverseDataSource = [...dataSource].reverse()
  const sliceData = reverseDataSource.slice(0, 8)

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Wallet Balance',
      dataIndex: 'wallet_Balance',
      key: 'wallet_Balance',
    },
    {
      title: 'Wallet Deposit',
      dataIndex: 'wallet_Deposit',
      key: 'wallet_Deposit',
    },
    {
      title: 'Email Verification',
      dataIndex: 'email_Verification',
      key: 'email_Verification',
      render: (text) => {
        const displayText = text === true ? "Active" : "Inactive";
        return <span className={`${text ? 'text-green-500 ' : 'text-red-500'} uppercase`}>{displayText}</span>;
      }
    },
    {
      title: 'Subscription Name',
      dataIndex: 'subscription_Name',
      key: 'subscription_Name',
    },
    {
      title: 'Totp Activation',
      dataIndex: 'totp_Activation',
      key: 'totp_Activation',
      render: (text) => {
        const displayText = text === true ? "Active" : "Inactive";
        return <span className={`${text ? 'text-green-500 ' : 'text-red-500'} uppercase`}>{displayText}</span>;
      }
    },
  ];

  const paginationConfig = {
    pageSize: 10,
    total: dataSource.length, // Set the total number of items in your data source
    showSizeChanger: false, // Optional: Set to false if you don't want to allow changing the page size
  };

  const handleAddFund = (data) => {
    const token = localStorage.getItem('token')
    const postData = {
      jwt: token,
      ...data
    }

    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'add_fund',
      data: postData
    }).then((res) => {
      message.success(res?.data?.Success)
      // ViewUser()
    }).catch(err => {
      message.error(err?.response?.data?.Error)
    })
  }

  const onChange = (pagination, filters, sorter, extra) => {
  };


  return (
    <>
      <div className="container mx-auto">
        {/* <div className="py-4 flex justify-end">
          <button
            className=" bg-[#122b45] text-white  px-6 py-2 rounded-md"
            onClick={redirect}
          >
            Logout
          </button>
        </div> */}
        <div className="flex items-center justify-between gap-10">
          <div className="w-5/12">
            <Form
              className="mt-4 m-auto bg-white px-4 rounded-xl overflow-hidden"
              name="basic"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFinish={createUser}
            >
              <div className=" text-xl font-semibold text-center  py-4 -mx-4 mb-7 bg-colorSecondaryDark/90 text-white">Create user</div>
              <Form.Item
                label="Username"
                name="username"
                labelAlign="left"
                rules={[
                  {
                    message: "Please input username",
                    required: true
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>

              <Form.Item
                label="Password"
                name='password'
                labelAlign="left"
                rules={[
                  {
                    message: "Please input password",
                    required: true
                  },
                ]}
              >
                <Input placeholder="Password" />
              </Form.Item>

              <Form.Item
                label="Name"
                name='name'
                labelAlign="left"
                rules={[
                  {
                    message: "Please input name",
                    required: true
                  },
                ]}
              >
                <Input placeholder="name" />
              </Form.Item>

              <Form.Item
                label="Email"
                name='email'
                labelAlign="left"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    message: "Please input email",
                    required: true
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="2FA"
                name='totp'
                labelAlign="left"
                rules={[
                  {
                    message: "Please input 2FA",
                    required: true
                  },
                ]}
              >
                <Input placeholder="2FA" />
              </Form.Item>

              <Form.Item className=" flex justify-center m-0">
                <button type="primary" htmlType="submit" className=" bg-lime-600 text-lg px-12 py-1 rounded-lg mb-7 text-white block">
                  Submit
                </button>
              </Form.Item>
            </Form>
          </div>

          <div className="w-7/12 px-2 ">
            <div className="">
              <p className=" text-xl mb-4 font-semibold  ml-2">Recent added 10 Users</p>
              <Table dataSource={sliceData} columns={columns} onChange={onChange} pagination={false} />
            </div>
          </div>
        </div>

      </div >
    </>
  );
};

export default User;
