import React, { useEffect, useState } from "react";
import { AiFillWindows, AiFillSignal } from "react-icons/ai";
import { Drawer, Menu, message } from "antd";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png'
import logo1 from '../assets/images/short.png'
import { FaUserPlus } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { GoDependabot } from "react-icons/go";
import { FaCircleDollarToSlot } from "react-icons/fa6";
import { RiFileList3Fill } from "react-icons/ri";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { RiUserSearchFill } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";
import { BiSolidMessageRoundedDots } from "react-icons/bi";
import { BiDollar } from "react-icons/bi";
import axios from "axios";
import { GiOrbit } from "react-icons/gi";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FaBars } from "react-icons/fa";
import { AiFillApi } from "react-icons/ai";
import { FaDatabase } from "react-icons/fa6";

const { SubMenu } = Menu;

const Sidebar = ({ placement, onDrawerClose, isDrawerOpen, }) => {

  const location = useLocation()
  const Navigate = useNavigate();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);

  const handleMenuItemClick1 = (path) => {
    navigate(path);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    onDrawerClose();
  };

  const sidebarItems = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <MdDashboard size={20} />,
    },
    {
      title: "Add fund",
      path: "/dashboard/addfund",
      icon: <FaCircleDollarToSlot size={20} />,
    },
    {
      title: "Subscription",
      path: "/dashboard/subscription",
      icon: <FaMoneyCheckDollar size={20} />,
    },
    {
      title: "Management",
      path: "/dashboard/usermanagement",
      icon: <FaUserPlus size={20} />,
    },
    {
      title: "Package",
      path: "/dashboard/packagePage",
      icon: <BiDollar size={20} />,
    },
    // {
    //   title: "Create user",
    //   path: "/dashboard/createusers",
    //   icon: <FaUserPlus size={20} />,
    // },

    {
      title: "Search",
      path: "/dashboard/activeuser",
      icon: <RiUserSearchFill size={20} />,
    },
    // {
    //   title: "Active Bot",
    //   path: "/dashboard/active",
    //   icon: <GoDependabot size={20} />,
    // },
    // {
    //   title: "All users",
    //   path: "/dashboard/alluser",
    //   icon: <FaUsers size={20} />,
    // },
    {
      title: "Livedata",
      path: "/dashboard/livedata",
      icon: <FaDatabase size={16} />,
    },
    {
      title: "All Api",
      path: "/dashboard/allApi",
      icon: <AiFillApi size={20} />,
    },
    {
      title: "Support",
      path: "/dashboard/support",
      icon: <BiSolidMessageRoundedDots size={20} />,
    },
    // {
    //   title: "Email Templates",
    //   path: "/dashboard/emailTemplates",
    //   icon: <AiFillWindows size={20} />,
    // },
    {
      title: "Logs",
      path: "/dashboard/logs",
      icon: <RiFileList3Fill size={20} />,
    },
    // {
    //   title: "Active Bot",
    //   icon: <AiFillSignal size={20} />,
    //   //  path: "/dashboard/admin",
    //   subItems: [
    //     {
    //       title: "Add API",

    //       // icon: <AiFillRedditCircle size={20} />,
    //       path: "/dashboard/",
    //     },
    //     { title: "View API", path: "/dashboard" },
    //   ],
    // },
  ];

  const handleSubMenuClick = (key) => {
    if (openKeys.includes(key)) {
      setOpenKeys(openKeys.filter((k) => k !== key));
    } else {
      setOpenKeys([...openKeys, key]);
    }
  };


  const [submenuOpen, setSubmenuOpen] = useState(
    new Array(sidebarItems.length).fill(false)
  );

  const handleSubmenuClick = (index) => {
    const updatedSubmenuOpen = [...submenuOpen];
    updatedSubmenuOpen[index] = !updatedSubmenuOpen[index];
    setSubmenuOpen(updatedSubmenuOpen);
  };

  // const sessionOut = () => {
  //   const token = localStorage.getItem('token')
  //   const postData = {
  //     jwt: token
  //   }

  //   const response = axios({
  //     method: 'POST',
  //     headers: { "Content-Type": "application/json" },
  //     url: process.env.REACT_APP_API + 'logs',
  //     data: postData
  //   }).then((res) => {
  //   }).catch(err => {
  //     if (err?.response?.data?.Error === "Session Expired") {
  //       setTimeout(() => {
  //         localStorage.clear();
  //         window.location.href = "/";
  //         Navigate("/");
  //         message.error(err?.response?.data?.Error);
  //       }, 100);
  //     }
  //   })
  // }

  // const refreshInterval = 3600000;

  // useEffect(() => {
  //   sessionOut()
  //   const intervalId = setInterval(() => {
  //     sessionOut()
  //   }, refreshInterval);

  //   return () => clearInterval(intervalId);
  // }, []);

  // useEffect(() => {
  //   sessionOut()
  // }, [])

  const getData = () => {
    const token = localStorage.getItem('token')
    const postData = {
      jwt: token,
    }
    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'logs',
      data: postData
    }).then(res => {
      console.log("naverror", res)
    }).catch(err => {
      // console.log( "naverror" , err)
      // setTimeout(() => {
      //   localStorage.clear();
      //   window.location.href = "/";
      //   Navigate("/");
      // }, 5000); 
      if (err?.response?.data?.Error === "Session Expired") {
        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/";
          navigate("/");
          message.error(err?.response?.data?.Error);
        }, 100);
      }
    })
  }

  useEffect(() => {
    getData()
  }, [location])

  return (
    <>
      {/* <div className="sidebar-wrapper">
        <Menu
          theme="dark"
          mode="inline"
          openKeys={openKeys} // Set openKeys here
          onOpenChange={(keys) => setOpenKeys(keys)}
          className={`md:flex items-start md:flex-col justify-start flex-row fixed mt-10 left-0 right-0 bottom-0 
           md:h-auto md:min-h-screen hidden border-[#ccc] ${ isDrawerOpen ?  "w-[210px]" : "w-[75px]"}` }
        >
          <div>
            {
              isDrawerOpen ?
                <img src={logo} alt="" className=" p-4" /> : <img src={logo1} alt="" className=" w-10  ml-0 m-3 " />
            }
          </div>
          {sidebarItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.subItems ? (
                <SubMenu
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  className="handleSubMenuClick"
                  onTitleClick={() => handleSubMenuClick(item.title)}
                >
                  {item.subItems.map((subItem) => (
                    <Menu.Item
                      key={subItem.title}
                      onClick={() => handleMenuItemClick1(subItem.path)}
                    >
                           {subItem.title}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={item.title}
                  icon={item.icon}
                  onClick={() => handleMenuItemClick1(item.path)}
                >
                  {isDrawerOpen && item.title}
                </Menu.Item>
              )}
            </React.Fragment>
          ))}
        </Menu>
      </div> */}

      <div>
        {/* <ul className="md:space-y-3 p-3 md:pl-0 pt-20 border-[#ccc] border-opacity-70 border-r-2 md:my-3 text-sm md:h-screen 
        h-[75px] md:block md:flex-col items-center justify-center cursor-pointer flex-row w-full gap-3 md:pr-2 md:mt-5"> */}
        <ul className={`md:flex items-start top-left-shadow md:flex-col justify-start flex-row fixed mt-10 left-0 right-0 bottom-0 
           md:h-auto md:min-h-screen hidden border-[#ccc] ${isDrawerOpen ? "w-[210px]" : "w-[75px]"}`}>
          <div className=" bg-colorSecondaryDark w-full flex justify-center items-center">
            {
              isDrawerOpen ?
                <img src={logo} alt="" className=" p-4" /> : <img src={logo1} alt="" className=" p-4" />
            }
          </div>
          {sidebarItems.map((item, index) => (
            <li
              key={index}
              className={`menu-item font-semibold   rounded-2xl my-4 mx-auto  py-3  ${location.pathname === item.path ? "top-left-activeShadow" : "top-left-shadow"} 
               ${isDrawerOpen ? "w-[80%]" : "w-[60%]"}
              `}
              // onClick={() => handleSubmenuClick(index)}
              onClick={() => {
                if (!item.subItems) {
                  handleSubmenuClick(index);
                }
              }}
            >
              <Link
                to={item.path}
                target={
                  item.path === "/dashboard/guide" ? "_blank" : "_self"
                }>
                <div
                  className={`items-center  flex w-full justify-center  ${location.pathname === item.path ? `` : ""}`}
                  onClick={() => handleSubmenuClick(index)}
                >
                  <div className="flex items-center justify-between  px-3 w-full">
                    <div
                      className={`flex items-center text-colorSecondaryDark justify-evenly `}
                    >
                      <span className={`mr-3 ${location.pathname === item.path
                        ? "text-colorActive "
                        : " "
                        }`}>
                        {item.icon}
                      </span>
                      <span
                        className={`uppercase item-title text-xs font-bold ${!isDrawerOpen && "hidden"} origin-left duration-200`}>
                        {item.title}
                      </span>
                    </div>
                    <div>
                      {item.subItems && isDrawerOpen && (
                        <span
                          className={`arrow-icon text-colorSecondaryDark ${submenuOpen[index] ? "open" : ""
                            }`}
                        >
                          {submenuOpen[index] ? "\u25B2" : "\u25BC"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
              {item.subItems && isDrawerOpen && submenuOpen[index] && (
                <ul
                  className={`sub-menu ml-11 bg-colorPrimary/20   mt-3 border-l-2 border-t-0 border-r-0 border-b-0 border-dashed border-colorPrimary ${submenuOpen[index] ? "open" : ""
                    }  transition-transform delay-100 duration-200 `}
                >
                  {item.subItems.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subItem.path}
                      className={`uppercase ${!isDrawerOpen && "hidden"}`}
                    >
                      <li
                        key={subIndex}
                        className={`flex items-start justify-start pt-2 pb-2 hover:text-colorSecondaryDark hover:border-colorAccent  cursor-pointer ${location.pathname === subItem.path
                          ? "bg-colorAccent text-colorSecondaryDark  rounded-r-full py-1"
                          : ""
                          }`}
                      >
                        <div className="flex items-center ">
                          <span
                            className={`item-icon text-colorPrimary mb-1 mr-3 ${location.pathname === subItem.path
                              ? "text-colorSecondaryDark"
                              : ""
                              }`}
                          >
                            {subItem.icon}
                          </span>
                          <span
                            className={`uppercase item-title ${!isDrawerOpen && "hidden"
                              } origin-left duration-200`}
                          >
                            {subItem.title}
                          </span>
                        </div>
                      </li>
                    </Link>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Sidebar