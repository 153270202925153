import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Collapse, Tag, Switch, Modal, Form, Drawer, message } from "antd";
import axios from "axios";
import { IoMdSend } from "react-icons/io";
import logo from '../assets/images/shortnew.png'
import logo1 from '../assets/images/shortnew.png'

const Support = () => {
    const [getSupp, setGetSupp] = useState([]);
    const [form] = Form.useForm();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [chatData, setChatData] = useState(null);
    const [profile, setProfile] = useState();
    const [suppLimit, setSuppLimit] = useState();
    const [openTicketDrawer, setOpenTicketDrawer] = useState(false);
    const [Message, setMessage] = useState('');
    const [TotalOPen, setTotalOPen] = useState([])
    const [TotalClose, setTotalClose] = useState([])
    const [profileImg, setProfileImg] = useState()
    const [currentChat, setCurrentChat] = useState()

    const showTicketDrawer = () => {
        setOpenTicketDrawer(true);
    };

    const onCloseTicketDrawer = () => {
        setOpenTicketDrawer(false);
    };

    useEffect(() => {
        const getOpendata = getSupp.filter(item => item?.Status == "OPEN")
        if (currentChat) {
            setChatData(getSupp[currentChat])
        } else {
            setChatData(getOpendata[0]);
        }
    }, [getSupp]);

    // const items = [
    //     {
    //         key: "1",
    //         label: "WHAT IS A Fingenius (beta)?",
    //         children:
    //             "Fingenius known as a software tool that automates strategy and helps you take advantage of the cryptocurrency market. By employing a bot, you can protect and increase your capital while saving time as you don't need to monitor trades all day. Even while you're sleeping, algorithms are actively trading bitcoin or altcoin for you. ",
    //     },
    //     {
    //         key: "2",
    //         label: "How do I get started?",
    //         children:
    //             "To get started, sign up on Fingenius and connect your live exchange API. You can also create a paper API for paper trading. Once connected, browse through the list of available bots and select the one that suits your preferences.",
    //     },
    //     {
    //         key: "3",
    //         label: "What is a paper API?",
    //         children:
    //             "A paper API is a simulated trading interface that allows you to test bots without using real funds. It's a risk-free way to evaluate a bot's performance before deciding to invest real money.",
    //     },
    //     {
    //         key: "4",
    //         label: "How do I copy a bot?",
    //         children:
    //             "After selecting a bot, choose the APIs you want to use for copying (live or paper). Follow the simple instructions provided on the Fingenius platform to initiate the copying process.",
    //     },
    //     {
    //         key: "5",
    //         label: "What is the risk parameter for spot?",
    //         children: (
    //             <div>
    //                 <ul>
    //                     <li>
    //                         <div>
    //                             <img alt="" className="my-2" />
    //                         </div>
    //                         <b>Low: </b>To get started, just copy the bot and invest a minimum
    //                         of 1270 USDT for low risk. Our algorithm strategies are set to
    //                         protect liquidation till the market collapses up to 127%.<br></br>{" "}
    //                         <b>Medium:</b>
    //                         To get started, just copy the bot and invest a minimum of 1135
    //                         USDT for medium risk. Our algorithm strategies are set to protect
    //                         liquidation till the market collapses up to 113.5%. <br></br>
    //                         <b>High:</b> To get started, just copy the bot and invest a
    //                         minimum of 250 USDT for high risk. Our algorithm strategies are
    //                         set to protect liquidation till the market collapses up to 25%.
    //                     </li>
    //                 </ul>
    //             </div>
    //         ),
    //     },
    //     {
    //         key: "6",
    //         label: "What is the risk parameter for future?",
    //         children: (
    //             <div>
    //                 <ul>
    //                     <li>
    //                         <div>
    //                             <img alt="" className="my-2" />
    //                         </div>
    //                         <b>Low:</b> To get started, just copy the bot and invest a minimum
    //                         of 127 USDT for low risk. Our algorithm strategies are set to
    //                         protect liquidation till the market collapses up to 127%.<br></br>
    //                         <b>Medium:</b>
    //                         To get started, just copy the bot and invest a minimum of 113.5
    //                         USDT for medium risk. Our algorithm strategies are set to protect
    //                         liquidation till the market collapses up to 113.5%.<br></br>
    //                         <b>High:</b> To get started, just copy the bot and invest a
    //                         minimum of 25 USDT for high risk. Our algorithm strategies are set
    //                         to protect liquidation till the market collapses up to 25%.
    //                     </li>
    //                 </ul>
    //             </div>
    //         ),
    //     },
    //     {
    //         key: "7",
    //         label: "How does the risk parameter affect my investment?",
    //         children:
    //             "The risk parameter determines the aggressiveness of the trading strategy employed by the bot. Low risk may have a more conservative approach, while high risk may involve more dynamic and potentially lucrative strategies. Adjusting the risk parameter also affects the minimum investment required to start copying a bot.",
    //     },
    //     {
    //         key: "8",
    //         label: "Can I change the risk parameter after starting a bot?",
    //         children:
    //             "No, once a bot is activated, the risk parameter cannot be changed. It's essential to carefully choose the risk level based on your risk tolerance and investment goals before initiating the copying process.",
    //     },
    //     {
    //         key: "9",
    //         label: "Is my investment safe on Fingenius?",
    //         children:
    //             "While we implement stringent security measures, all investments carry inherent risks. It's crucial to conduct your own research and choose bots and risk parameters that align with your risk tolerance and financial goals.",
    //     },
    //     {
    //         key: "10",
    //         label: "How often are bots updated?",
    //         children:
    //             "Bot performance and strategies may be updated periodically. Users will be notified of any changes to ensure transparency and allow them to make informed decisions.",
    //     },
    //     {
    //         key: "11",
    //         label: "How can I track the performance of my copied bots?",
    //         children:
    //             "You can monitor the performance of your copied bots in real-time through the Fingenius trade Page & Report page. Detailed performance metrics, including profit and loss, are provided for each bot.",
    //     },
    //     {
    //         key: "12",
    //         label:
    //             "Why is my bot not consistently meeting the company performance guidelines?",
    //         children:
    //             "Bot performance is contingent upon various factors, primarily the dynamic nature of the market and its behavior. The market is inherently volatile, and the bot's performance can fluctuate based on real-time changes in market conditions.",
    //     },
    //     {
    //         key: "13",
    //         label: "How does market volatility impact my bot's performance?",
    //         children:
    //             "Market volatility directly influences the performance of your bot. Sudden market shifts, fluctuations in prices, and unexpected events can significantly affect trading outcomes. The bot's algorithms are designed to adapt, but the inherent unpredictability of the market poses challenges in consistently meeting performance benchmarks.",
    //     },
    //     {
    //         key: "14",
    //         label: "How does market volatility impact my bot's performance?",
    //         children:
    //             "Market volatility directly influences the performance of your bot. Sudden market shifts, fluctuations in prices, and unexpected events can significantly affect trading outcomes. The bot's algorithms are designed to adapt, but the inherent unpredictability of the market poses challenges in consistently meeting performance benchmarks.",
    //     },
    //     {
    //         key: "15",
    //         label:
    //             "Why does the bot's performance vary in different market situations?",
    //         children:
    //             "The bot's performance is subject to the unique characteristics of each market situation. Market volume, liquidity, and participant behavior play crucial roles. The bot's algorithms analyze these factors to make informed decisions, but outcomes may differ based on the specific dynamics of each scenario.",
    //     },
    //     {
    //         key: "16",
    //         label:
    //             "Can the bot's performance be affected by changes in market behavior?",
    //         children:
    //             "Yes, market behavior evolves over time, and the bot's performance is sensitive to these changes. Shifts in trends, sentiment, or trading patterns can impact how the bot operates. Regular updates to the bot's algorithms are made to adapt to evolving market conditions.",
    //     },
    //     {
    //         key: "17",
    //         label: "How does data input affect the bot's performance?",
    //         children:
    //             " The quality and timeliness of data input are critical factors. If the data available to the bot is delayed, incomplete, or inaccurate, it can impact decision-making. Ensuring a reliable data feed is essential to optimize the bot's performance.",
    //     },
    //     {
    //         key: "18",
    //         label:
    //             "Is there a guarantee that the bot will always meet performance guidelines?",
    //         children:
    //             " Due to the inherent uncertainties in financial markets, no bot can provide an absolute guarantee of performance. While efforts are made to optimize algorithms and adapt to market changes, the dynamic nature of the financial landscape means that performance can vary.",
    //     },
    //     {
    //         key: "19",
    //         label:
    //             "How can I mitigate risks associated with fluctuations in bot performance?",
    //         children:
    //             " Diversification, risk management strategies, and staying informed about market developments are crucial. Establish clear risk tolerance levels, regularly assess your bot's parameters, and be prepared to make adjustments based on changing market conditions.",
    //     },
    //     {
    //         key: "20",
    //         label:
    //             "Is customer support available for assistance with bot performance issues?",
    //         children:
    //             " Yes, our customer support team is available to assist with any issues or concerns related to bot performance. Feel free to reach out with specific questions or for guidance on optimizing your bot for prevailing market conditions",
    //     },
    // ];

    const onFinish = (values) => {
        form.resetFields();
        const token = localStorage.getItem("jwt");
        const postData = {
            ...values,
            jwt: token,
            project: "FinGenius",
            subject: values.subject == undefined ? "" : values.subject,
            message: values.message == undefined ? "" : values.message,
        };
        const response = axios({
            method: "POST",
            headers: {
                accept: "application/json",
            },
            url: process.env.REACT_APP_API_PATH_SUPPORT + "create_support",
            data: postData,
        })
            .then((res) => {
                // toast.success(res?.data?.Success);
                viewSupport();
            })
            .catch((err) => {
                // toast.error(err?.response?.data?.Error);
            });
    };

    const viewSupport = () => {
        const token = localStorage.getItem("token");
        const postdata = {
            // project: "FinGenius",
            jwt: token,
        };

        const response = axios({
            method: "POST",
            headers: {
                accept: "application/json",
            },
            url: process.env.REACT_APP_API + "view_support",
            data: postdata,
        })
            .then((res) => {
                const responseData = res.data.Success;
                const ReveresresponseData = responseData.reverse();
                const filterOpen = ReveresresponseData.filter((item) => item.Status === "OPEN");
                setTotalOPen(filterOpen)
                const filterClose = ReveresresponseData.filter((item) => item.Status === "CLOSE");
                setTotalClose(filterClose)
                setGetSupp(ReveresresponseData);
                // setGetSupp(ReveresresponseData)
            })
            .catch((err) => { });
    };

    const ViewProfile = async () => {
        const jwt = localStorage.getItem("jwt");
        const postdata = {
            project: "FinGenius",
            jwt: jwt,
        };

        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PATH_USER + "view_profile",
            data: postdata,
        })
            .then((res) => {
                setProfile(res.data.Success);
            })
            .catch((err) => { });
    };

    const maxSupport = async () => {
        const jwt = localStorage.getItem("jwt");
        const postdata = {
            project: "FinGenius",
        };

        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API_PATH_USER_NEW + "max_support_tickets",
            data: postdata,
        })
            .then((res) => {
                setSuppLimit(res.data.Success);
            })
            .catch((err) => { });
    };

    useEffect(() => {
        viewSupport();
        ViewProfile();
        maxSupport();
    }, []);

    const handleMessage = (e) => {
        e.preventDefault();
        setMessage()
        const token = localStorage.getItem('token')

        const postData = {
            jwt: token,
            supportid: chatData?.SupportID,
            message: Message
        }
        const response = axios({
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API + 'message_support',
            data: postData
        }).then(res => {
            viewSupport()
            message.success(res?.data?.Success)
        }).catch(err => {
            message.error(err.response.data.Error)
        })
    }

    const changeSupport = () => {
        const token = localStorage.getItem('token')
        const postData = {
            jwt: token,
            supportid: chatData?.SupportID,
        }
        const response = axios({
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API + 'change_support_status',
            data: postData
        }).then(res => {
            viewSupport()
            message.success(res?.data?.Success)
        }).catch(err => {
            message.error(err.response.data.Error)
        })
    }

    const getImage = () => {
        const token = localStorage.getItem('token')

        const postData = {
            jwt: token,
            username: chatData?.Username
        }
        const response = axios({
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            url: process.env.REACT_APP_API + 'profile_image',
            data: postData
        }).then(res => {
            setProfileImg(res.data.Success.Profile_Image)
        }).catch(err => {

        })
    }

    useEffect(() => {
        getImage()
    }, [chatData])

    const messagesEndRef = useRef(null);

    useEffect(() => {
        scrollToBottom();
    }, [chatData?.Message]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className=" mt-2 h-[91vh]  bg-colorSecondaryDark ">
            <div className="md:flex  justify-between items-start w-full h-full">
                <div className="xl:block hidden  bg-colorSecondaryDark/90 p-2 xl:w-[21%] 2xl:w-[15%]">
                    {/* <div className="text-center mb-3">
                        <div className="flex items-center border border-solid  w-full mx-auto text-white border-black rounded-md  px-3 py-2 bg-colorAccent">
                            <button
                                className=""
                                onClick={showModal}
                                disabled={TotalOPen.length >= 5}
                            >
                                Raise your querry
                            </button>
                        </div>
                        <Modal
                            title="Raise your querry"
                            open={isModalOpen}
                            footer={null}
                            onCancel={handleCancel}
                        >
                            <Form form={form} name="basic" onFinish={onFinish}>
                                <Form.Item name="subject" className="mb-0">
                                    <input
                                        type="text"
                                        className="mt-2 w-full mb-4 px-4 py-3 border rounded-lg shadow-lg focus:outline-none focus:ring focus:border-blue-300"
                                        placeholder="Subject (maximum 50 characters)"
                                        maxlength="50"
                                    />
                                </Form.Item>
                                <Form.Item className="mb-0" name="message">
                                    <textarea
                                        className="w-full h-48 max-w-full px-4 py-3 border rounded-lg shadow-lg focus:outline-none focus:ring focus:border-blue-300"
                                        placeholder="Message (maximum 500 characters)"
                                        maxlength="500"
                                    ></textarea>
                                </Form.Item>
                                <Form.Item className="mb-0">
                                    <button className="mt-4 text-md mx-auto border border-solid  rounded-md block px-10 py-2 bg-[#77c8c9] text-white text-md  cursor-pointer">
                                        Enter Your Querry
                                    </button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div> */}

                    {/* <hr className="mb-2 text-colorPrimary" /> */}
                    <div className="text-white flex justify-between items-center">
                        <div className="text-green-400 ">Open</div>
                        <div className="italic text-sm">
                            No of tickets : {TotalOPen.length}
                        </div>
                    </div>
                    <div>
                        {TotalOPen.map((item, index) => (
                            <div
                                key={index}
                                className={`border border-solid border-r-0 border-t-0 border-b-0 border-l-4 bg-colorPrimary  rounded-md p-2 my-2 
                                ${index === selectedIndex ? "border-colorPrimaryDark" : "border-green-400"}`}
                                onClick={() => {
                                    setChatData(TotalOPen[index])
                                    setCurrentChat(index)
                                }}
                            >
                                <div className="font-bold text-sm mb-2">{item.Username}</div>
                                <div className="flex justify-between items-center text-xs">
                                    <div>{item.Created_At}</div>
                                    <div>{item.SupportID}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className=" flex justify-between items-center">
                        <div className="text-colorPrimaryDark">Closed </div>
                        <div className="text-white italic ">
                            No of tickets : {TotalClose.length}
                        </div>
                    </div>
                    <div>
                        {TotalClose.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => setChatData(TotalClose[index])}
                                    className="border border-solid border-r-0 border-t-0 border-b-0 border-l-4 bg-colorPrimary border-green-400 rounded-md p-2 my-2"
                                >
                                    <div className="font-bold text-sm mb-2">{item.Username}</div>
                                    <div className="flex justify-between items-center text-xs">
                                        <div>{item.Created_At}</div>
                                        <div>{item.SupportID}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="h-full bg-slate-100 relative w-100 xl:w-[79%] 2xl:w-[85%] w-full">
                    <div className="bg-colorAccent/20 p-1 sm:p-4 shadow-md md:mt-10 xl:mt-0">
                        <div className="">
                            <button onClick={showTicketDrawer} className="xl:hidden block bg-colorAccent mb-2 text-white rounded-lg shadow-sm shadow-black px-2 py-1">
                                See all Queries
                            </button>
                        </div>
                        <Drawer
                            title={<div className="text-xl text-black font-bold text-center ">
                                All Querry
                            </div>}
                            placement="left"
                            onClose={onCloseTicketDrawer}
                            open={openTicketDrawer}
                            width={300}
                        >
                            <div className="h-[91vh] bg-colorSecondaryDark/90 p-2">
                                <div className="text-center mb-3">
                                </div>
                                <hr className="mb-2 text-colorPrimary" />
                                <div className="text-white flex justify-between items-center">
                                    <div className="text-green-400 ">Open</div>
                                    <div className="italic text-sm">
                                        No of tickets : {TotalOPen.length}
                                    </div>
                                </div>
                                <div>
                                    {TotalOPen.map((item, index) => (
                                        <div
                                            key={index}
                                            className={`border border-solid border-r-0 border-t-0 border-b-0 border-l-4 bg-colorPrimary  rounded-md p-2 my-2 
                                ${index === selectedIndex ? "border-colorPrimaryDark" : "border-green-400"}`}
                                            onClick={() => {
                                                setChatData(TotalOPen[index])
                                                setCurrentChat(index)
                                                onCloseTicketDrawer()
                                            }}
                                        >
                                            <div className="font-bold text-sm mb-2">{item.Username}</div>
                                            <div className="flex justify-between items-center text-xs">
                                                <div>{item.Created_At}</div>
                                                <div>{item.SupportID}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className=" flex justify-between items-center">
                                    <div className="text-colorPrimaryDark">Closed </div>
                                    <div className="text-white italic ">
                                        No of tickets : {TotalClose.length}
                                    </div>
                                </div>
                                <div>
                                    {TotalClose.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => {
                                                    setChatData(TotalClose[index])
                                                    onCloseTicketDrawer()
                                                }}
                                                className="border border-solid border-r-0 border-t-0 border-b-0 border-l-4 bg-colorPrimary border-green-400 rounded-md p-2 my-2"
                                            >
                                                <div className="font-bold text-sm mb-2">{item.Username}</div>
                                                <div className="flex justify-between items-center text-xs">
                                                    <div>{item.Created_At}</div>
                                                    <div>{item.SupportID}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Drawer>

                        <div className="">
                            <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-5  md:gap-10">
                                <div className=" italic text-sm sm:text-base">{chatData?.Created_At}</div>
                                {/* <div className="text-center space-y-2 flex justify-center items-center gap-2"> */}
                                <div className=" ">
                                    <span className=" capitalize text-sm sm:text-lg font-semibold">Username</span> : {chatData?.Username}
                                </div>
                                <div>
                                    <span className=" capitalize text-sm sm:text-lg font-semibold">Subject</span> : {chatData?.Subject}
                                </div>
                                <div>
                                    <span className=" capitalize text-sm sm:text-lg font-semibold">Status</span> :  <Switch checkedChildren="Open" unCheckedChildren="Close" checked={chatData?.Status == "OPEN"} onClick={changeSupport} className="switchbutton mt-0" />
                                </div>
                                {/* <Tag
                                        color="green"
                                        className="m-0 text-sm font-semibold"
                                        style={{ margin: "0" }}
                                    >
                                        <div>
                                            {chatData?.Status == "OPEN" ? (
                                                <span>Active</span>
                                            ) : (
                                                <span>Inactive</span>
                                            )}
                                        </div>
                                    </Tag> */}

                                {/* </div> */}
                                <div className="text-gray-900 font-semibold lg:text-end text-start text-base sm:text-lg">
                                    {chatData?.SupportID}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 h-[84vh] flex justify-between flex-col overflow-y-auto">
                        <div class="w-full px-5 flex flex-col justify-between h-90 overflow-y-scroll">
                            <div class="flex flex-col mt-5">
                                {chatData?.Message.map((item, index) => {

                                    const img = item.Sender == "Admin" ? logo : logo1
                                    return (
                                        // <div class={`flex mb-4 gap-3 ${item.Sender == "Admin" ? "" : 'flex-row-reverse '}`} key={index}>
                                        <div class={`flex mb-4 ${item.Sender == "Admin" ? " flex-row-reverse gap-2" : 'justify-start'}`} key={index}>
                                            <img
                                                src={img}
                                                class="object-cover h-8 w-8 rounded-full"
                                                alt=""
                                            />
                                            <div>
                                                <div class="ml-2 py-3 px-4 top-left-shadow  rounded-bl-2xl rounded-br-2xl rounded-tr-2xl rounded-tl-md text-sm text-black">
                                                    {item?.Message}
                                                </div>
                                                <div className="text-end text-xs font-medium text-gray-700 mr-3 mt-3">
                                                    {item?.Sender} <span>{" "}</span>
                                                    {item?.DateTime}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div ref={messagesEndRef} />
                            </div>
                        </div>
                        {chatData?.Status === "OPEN" && (
                            <form className="flex items-center justify-center gap-10 " onSubmit={handleMessage}>
                                <Input
                                    type="text"
                                    placeholder="send message"
                                    className="w-[70%] rounded-xl h-full pl-4 text-base"
                                    value={Message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <button
                                    type="submit"
                                    className="bg-colorSecondaryDark py-3 px-4 text-white rounded-xl text-2xl"
                                >
                                    <IoMdSend />
                                </button>
                            </form>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;
