import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
import User from "../Pages/User";
// import Admin from "../Pages/Admin";
import Dashboard from "../Layout/Dashboard";
import TransactionBot from "../Pages/TransactionBot";
import ProtectedRoute from "./ProtectRoutes";
import Subscription from "../Pages/Subscription";
import Support from "../Pages/Support";
import EmailTemplates from "../Pages/EmailTemplates";
import Logs from "../Pages/Logs";
import Alluser from "../Pages/Alluser";
import Addfund from "../Pages/Addfund";
import Activeuser from "../Pages/Activeuser";
import Mainpage from "../Pages/Mainpage";
import UserManagement from "../Pages/UserManagement";
import PackagePages from "../Pages/PackagePage";
import Binance from "../Pages/Binance";
import Image from "../Pages/Image";
import Binancecopy from "../Pages/Binancecopy";
import Activeusercopy from "../Pages/Activeusercopy";
import AllApi from "../Pages/AllApi";


const Routerpage = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<ProtectedRoute Component={Dashboard}/>}> 
            {/* <Route index element={<ProtectedRoute Component={Addfund}/>} /> */}
            <Route index element={<ProtectedRoute Component={Mainpage}/>} />
            <Route path="/dashboard/createusers"  element={<ProtectedRoute Component={User}/>} />
            <Route path="/dashboard/active" element={<ProtectedRoute Component={TransactionBot}/>} />
            <Route path="/dashboard/subscription" element={<ProtectedRoute Component={Subscription}/>} />
            <Route path="/dashboard/support" element={<ProtectedRoute Component={Support}/>} />
            <Route path="/dashboard/emailTemplates" element={<ProtectedRoute Component={EmailTemplates}/>} />
            <Route path="/dashboard/logs" element={<ProtectedRoute Component={Logs}/>} />
            <Route path="/dashboard/alluser" element={<ProtectedRoute Component={Alluser}/>} />
            <Route path="/dashboard/livedata" element={<ProtectedRoute Component={Binancecopy}/>} />
            <Route path="/dashboard/addfund" element={<ProtectedRoute Component={Addfund}/>} />
            <Route path="/dashboard/activeuser" element={<ProtectedRoute Component={Activeusercopy}/>} />
            <Route path="/dashboard/mainpage" element={<ProtectedRoute Component={Mainpage}/>} />
            <Route path="/dashboard/usermanagement" element={<ProtectedRoute Component={UserManagement}/>} />
            <Route path="/dashboard/packagePage" element={<ProtectedRoute Component={PackagePages}/>} />
            {/* <Route path="/dashboard/image" element={<ProtectedRoute Component={Image}/>} /> */}
            <Route path="/dashboard/allApi" element={<ProtectedRoute Component={AllApi}/>} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default Routerpage;
