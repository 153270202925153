import React, { useState, useEffect } from 'react'
import { AutoComplete, Select, Table, Tooltip, Spin, message } from 'antd'
import axios from 'axios';
import { TfiReload } from "react-icons/tfi";

const Apitable = (props) => {
  const { username, option, newOption } = props?.data

  const [userAcount, setUserAcount] = useState([])
  const [loading, setLoading] = useState(false);
  const [option2, setOption2] = useState(option)
  const [Api, setApi] = useState(option[0]?.label)

  useEffect(() => {
    setUserAcount([])
    setOption2(option)
    const fisrtLabel = option[0]?.label
    setApi(fisrtLabel)
  }, [option])

  const getUserAcountInfo = () => {
    setUserAcount([])
    setLoading(true)
    const token = localStorage.getItem('token');
    const postData = {
      jwt: token,
      username: username,
      api_name: newOption
    }

    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // url: process.env.REACT_APP_API + 'user_account_info',
      url: process.env.REACT_APP_API + 'balance_futures',
      data: postData
    }).then(res => {
      setLoading(false)
      setUserAcount(res.data.Success)
    }).catch(err => {
      setLoading(false)
      // message.error(err?.response?.data?.Error)
    })
  };

  useEffect(() => {
    getUserAcountInfo()
  }, [newOption])

  // useEffect(() => {
  //   if (Api) {
  //     getUserAcountInfo()
  //   }
  // }, [Api])

  const handleChange = (value) => {
    setApi(value)
  };

  const columns = [
    //     {
    //   title: <p className='p-1 text-center rounded-lg table-cell'>Update Time</p>,
    //   dataIndex: 'Update_Time',
    //   key: 'Update_Time',
    //   width: 200,
    //   fixed: 'left',
    // },
    {
      title: <p className='p-1 text-center rounded-lg table-cell ' style={{ width: 'auto !important' }}>Asset</p>,
      dataIndex: 'Asset',
      key: 'Asset',
      width: 75,
      fixed: 'left',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Wallet Balance</p>,
      dataIndex: 'Wallet_Balance',
      key: 'Wallet_Balance',
      width: 150,
      fixed: 'left',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Unrealized Pnl</p>,
      dataIndex: 'Unrealized_Profit',
      key: 'Unrealized_Profit',
      width: 150,
      fixed: 'left',
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Margin Balance</p>,
      dataIndex: 'Margin_Balance',
      key: 'Margin_Balance',
      width: 150
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Maint Margin</p>,
      dataIndex: 'Maint_Margin',
      key: 'Maint_Margin',
      width: 150
    },
    {
      title: <Tooltip title="Position Initial Margin" placement="top"><p className='p-1 text-center rounded-lg table-cell'>PIM</p></Tooltip>,
      dataIndex: 'Position_Initial_Margin',
      key: 'Position_Initial_Margin',
      width: 150
    },
    {
      title: <Tooltip title="Open Order Initial Margin" placement="top"><p className='p-1 text-center rounded-lg table-cell'>OOIM</p></Tooltip>,
      dataIndex: 'Open_Order_Initial_Margin',
      key: 'Open_Order_Initial_Margin',
      width: 150
    },
    {
      title: <Tooltip title="Cross Wallet Balance" placement="top"><p className='p-1 text-center rounded-lg table-cell'>CWB</p></Tooltip>,
      dataIndex: 'Cross_Wallet_Balance',
      key: 'Cross_Wallet_Balance',
      width: 150
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Cross UnPnl</p>,
      dataIndex: 'Cross_UnPnl',
      key: 'Cross_UnPnl',
      width: 150
    },
    {
      title: <p className='p-1 text-center rounded-lg table-cell'>Available Bal</p>,
      dataIndex: 'Available_Balance',
      key: 'Available_Balance',
      width: 150
    },
    {
      title: <Tooltip title="Max Withdraw Amount" placement="top"><p className='p-1 text-center rounded-lg table-cell'>MWA</p></Tooltip>,
      dataIndex: 'Max_Withdraw_Amount',
      key: 'Max_Withdraw_Amount',
      width: 150
    },
    // {
    //   title: <p className='p-1 text-center rounded-lg table-cell'>Margin Available</p>,
    //   dataIndex: 'Margin_Available',
    //   key: 'Margin_Available',
    //   width: 150
    // },
  ]
  const dataSource = [{
    Asset: <p className=' text-center'>{userAcount[0]?.Asset?.Asset}</p>,
    Available_Balance: <p className=' text-center'>{userAcount[0]?.Asset?.Available_Balance}</p>,
    Cross_UnPnl: <p className=' text-center'>{userAcount[0]?.Asset?.Cross_UnPnl}</p>,
    Cross_Wallet_Balance: <p className=' text-center'>{userAcount[0]?.Asset?.Cross_Wallet_Balance}</p>,
    Initial_Margin: <p className=' text-center'>{userAcount[0]?.Asset?.Initial_Margin}</p>,
    Maint_Margin: <p className=' text-center'>{userAcount[0]?.Asset?.Maint_Margin}</p>,
    Margin_Available: <p className=' text-center'>{userAcount[0]?.Asset?.Margin_Available == true ? "Active" : "Inactive"}</p>,
    Margin_Balance: <p className=' text-center'>{userAcount[0]?.Asset?.Margin_Balance}</p>,
    Max_Withdraw_Amount: <p className=' text-center'>{userAcount[0]?.Asset?.Max_Withdraw_Amount}</p>,
    Open_Order_Initial_Margin: <p className=' text-center'>{userAcount[0]?.Asset?.Open_Order_Initial_Margin}</p>,
    Position_Initial_Margin: <p className=' text-center'>{userAcount[0]?.Asset?.Position_Initial_Margin}</p>,
    Unrealized_Profit: <p className=' text-center'>{userAcount[0]?.Asset?.Unrealized_Profit}</p>,
    Update_Time: <p className=' text-center'>{new Date(userAcount[0]?.Asset?.Update_Time).toLocaleDateString('en-GB') + ' ' + new Date(1701540808180).toLocaleTimeString()}</p>,
    Wallet_Balance: <p className=' text-center'>{userAcount[0]?.Asset?.Wallet_Balance}</p>,
  }]


  return (
    <div>
      {/* <Select
        value={Api}
        className='focus:border-colorAccent hover:border-colorAccent'
        style={{
          width: 180,
        }}
        placeholder="Select API Name"
        onChange={handleChange}
        options={option2}
      /> */}
      <div>
        <div className='my-3 flex flex-col md:flex-row md:items-center gap-2 px-2'>
          <p>Data may be displayed with a delay of 1-2 minutes, please click Refresh to update the data </p>
          <button className=' flex items-center gap-2 text-colorSecondaryDark font-medium' onClick={getUserAcountInfo}><TfiReload /> Refresh</button>
        </div>
      </div>

      <div className=' mt-4'>
        <span className=' font-bold text-sm border-dashed border-b border-black pb-1'>Margin Balance</span>
        <div className={` text-base md:text-2xl font-medium my-2 ${userAcount[0]?.Total_Margin_Balance > 0 ? ' text-green-500' : 'text-red-500'}`}>{userAcount[0]?.Total_Margin_Balance}</div>
        <div>
          {/* <p className=' text-xs font-semibold'>Today's Realized Pnl <span></span></p> */}
        </div>
        <hr className=' border-black my-2 w-2/4' />
        <div className=' flex w-2/4 gap-20 md:gap-72 mt-4'>
          <div>
            <span className=' font-bold text-xs md:text-sm  border-black pb-1'>Wallet Balance</span>
            <p className='text-sm md:text-xl font-medium my-2'>{userAcount[0]?.Total_Wallet_Balance}</p>
          </div>

          <div>
            <span className=' font-bold text-xs md:text-sm border-dashed border-b border-black pb-1'>Unrealized Pnl</span>
            <p className={`text-sm md:text-xl font-medium my-2 ${userAcount[0]?.Total_Unrealized_Profit > 0 ? ' text-green-500' : 'text-red-500'}`}>{userAcount[0]?.Total_Unrealized_Profit}</p>
          </div>
        </div>

        <div >
          <p className='font-bold text-sm md:text-base border-black pt-10 pb-4'>Multi-Asset Info</p>

          <div className=' flex justify-between md:w-4/5'>
            <div>
              <span className='text-xs font-bold md:text-sm border-dashed border-b border-black pb-1'>Available Balance</span>
              <p className=' text-sm md:text-xl font-medium my-2'>{userAcount[0]?.Available_Balance}</p>
            </div>

            <div>
              <span className='text-xs font-bold md:text-sm border-dashed border-b border-black pb-1'>Total Maintenance Margin</span>
              <p className='text-sm md:text-xl font-medium my-2'>{userAcount[0]?.Total_Maint_Margin}</p>
            </div>

            <div>
              <span className='text-xs font-bold md:text-sm border-dashed border-b border-black pb-1'>Total Initial Margin</span>
              <p className='text-sm md:text-xl font-medium my-2'>{userAcount[0]?.Total_Initial_Margin}</p>
            </div>
          </div>
        </div>
      </div>

      <Spin spinning={loading} >
        <Table columns={columns} dataSource={dataSource} 
          scroll={{
            x: 2000,
          }}
        />
      </Spin>
    </div>
  )
}

export default Apitable
