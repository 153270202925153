
import React, { useEffect, useState } from 'react'
import { Input, Space, Table } from 'antd'
import axios from 'axios'
const { Search } = Input;


const Api = ({ username }) => {
  const [dataSource, setDataSource] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [filter, setFilter] = useState("ALL");
  const [searchText, setSearchText] = useState('');
  console.log('dataSource', dataSource)

  const getApi = () => {
    const token = localStorage.getItem('token')
    const postData = {
      jwt: token,
      username: username,
      page_number: currentPage,
      number_of_data: pageSize,
      exchange_type: filter,
      trade_type: "ALL"
    }
    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      url: process.env.REACT_APP_API + 'user_api',
      data: postData
    }).then((res) => {
      const successData = res?.data?.Success
      const updatedData = successData.map((item, index) => ({
        ...item,
        isActive: item.Status === "ACTIVE",
        key: `${item.APIID}_${index}`,
      }))
      setDataSource(updatedData)
      setTotalDataCount(res?.data?.TotalPages * pageSize);
    }).catch((err) => {
    })
  }

  useEffect(() => {
    getApi();
  }, [currentPage, pageSize, username, filter]);


  const expandedRowRender = (record) => {

    const columns = [
      {
        title: "Asset",
        dataIndex: "asset",
        key: "asset",
      },
      ...Object.keys(record.Initial).map((asset) => ({
        title: asset,
        dataIndex: asset,
        key: asset,
        // render: (value, record) => {
        //   if (asset === 'USDT') {
        //   }
        //   return value;
        // },
      })),

      {
        title: (
          <div className='bg-colorAccent text-white text-center py-1 px-3 rounded' >USDT</div>
        ),
        dataIndex: 'USDT',
        key: 'USDT',
        render: (text) => {
          return <div className=' bg-colorAccent text-white text-center py-1 px-3 rounded'>{text}</div>;
        },
      },
    ];

    const dataSource = [
      {
        key: "Initial ",
        asset: "Initial (Qty)",
        ...record.Initial,
      },
      {
        key: "Invested",
        asset: "Bot Allotted Fund (Qty)",
        ...record.Invested,
      },
      {
        key: "Balance",
        asset: "Balance (Qty)",
        ...record.Balance,
      },
    ];

    return (
      <Table
        size="medium"
        columns={columns}
        className="newtable py-4 px-2"
        dataSource={dataSource}
        pagination={false}
      />
    );
  };


  const generateUniqueKeys = (data) => {
    return data.map((item, index) => `${item.APIID}_${index}`);
  };

  const defaultColumns = [
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      width: "10%",
      editable: true,
      render: (text) => {
        return (<div className={` border-l-4 rounded pl-2 ${text == "PAPER" ? " border-[#122b45] " : " border-[#76c3c5] "}`} >{text}</div>)
      }
    },
    {
      title: "Username",
      dataIndex: "Username",
      key: "Username",
      editable: true,
      width: "20%",
      render: (text) => {
        return (<span>{text}</span>)
      }
    },
    {
      title: "API ID",
      dataIndex: "APIID",
      key: "APIID",
      editable: true,
      width: "20%",
    },
    {
      title: "Exchange",
      dataIndex: "Exchange",
      key: "Exchange",
      editable: true,
      width: "20%",
    },
    {
      title: "Exchange Type",
      dataIndex: "Exchange_Type",
      key: "Exchange_Type",
      editable: true,
      width: "20%",
    },
    {
      title: "API Name",
      dataIndex: "API_Name",
      key: "API_Name",
      editable: true,
      width: "20%",
    },
  ];

  const allColumns = [...defaultColumns];

  const columns = allColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        width: 10,
        handleSave,
      }),
    };
  });

  const handleSave = () => {

  }

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const handleFilterClick = (filterType) => {
    setFilter(filterType);
  };

  const onSearchChange = (e) => {
    handleSearch(e.target.value);
  };

  const onClearSearch = () => {
    setSearchText('');
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  return (
    <div className='mt-5'>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex justify-start flex-col items-start lg:space-x-4 ">
            <div className="p-2 ">
              <div className="flex mb-4 items-center justify-end">
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md drop-shadow-xl ${filter === "ALL"
                    ? "bg-colorSecondaryDark text-white border-2 border-white "
                    : "bg-white text-colorSecondaryDark"
                    }`}
                  onClick={() => {
                    handleFilterClick("ALL");
                    setFilter("ALL");
                  }}
                >
                  All
                </button>
                <button
                  className={`mr-2 2xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "SPOT"
                    ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-colorSecondaryDark"
                    }`}
                  onClick={() => {
                    handleFilterClick("SPOT");
                    setFilter("SPOT");
                  }}
                >
                  Spot
                </button>
                <button
                  className={`mr-2 xl:px-4 py-1 rounded-full w-20 2xl:w-24 shadow-md ${filter === "FUTURES"
                    ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                    : "bg-white text-colorSecondaryDark"
                    }`}
                  onClick={() => {
                    handleFilterClick("FUTURES");
                    setFilter("FUTURES");
                  }}
                >
                  Futures
                </button>
              </div>
            </div>
          </div>

          <Search
            placeholder="Search..."
            allowClear
            onChange={onSearchChange}
            onClear={onClearSearch}
            style={{ marginBottom: 16 }}
            className="w-64"
          />
        </div>
      </div>

      <Table dataSource={dataSource} columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalDataCount,
          showSizeChanger: true,
          pageSizeOptions: [],
        }}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: generateUniqueKeys(dataSource),
        }}
        scroll={{
          x: 1000,
        }}
      />
    </div>
  )
}

export default Api
