import { Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const ActiveApi = () => {
  const [dataSource, setDataSource] = useState([])

  const getApi = () => {
    const token = localStorage.getItem('token')
    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      url: process.env.REACT_APP_API + 'view_all_bots',
      data: { jwt: token }
    }).then((res) => {
      const successData = res?.data?.Success
      const updatedData = successData.map((item, index) => ({
        ...item,
        isActive: item.Status === "ACTIVE",
        key: `${item.APIID}_${index}`,
      }))
      setDataSource(updatedData)
    }).catch((err) => {
    })
  }

  useEffect(() => {
    getApi()
  }, [])


  const expandedRowRender = (record) => {
    const dataSource = [
      {
        week : `$ ${record?.Avg_Profit_Percent["1W"]}`,
        month : `$ ${record?.Avg_Profit_Percent["1M"]}`,
        quatarly : `$ ${record?.Avg_Profit_Percent["3M"]}` ,
        year : `$ ${record?.Avg_Profit_Percent["1Y"]}` ,
      }
    ]
    const columns = [
      {
        title : "Avg Profit Percent / 1W",
        dataIndex : 'week',
        key : 'week'
      },
      {
        title : "Avg Profit Percent / 1M",
        dataIndex : 'month',
        key : 'month'
      },
      {
        title : "Avg Profit Percent / 3M",
        dataIndex : 'quatarly',
        key : 'quatarly'
      },
      {
        title : "Avg Profit Percent / 1Y",
        dataIndex : 'year',
        key : 'year'
      },
    ]

    return (
      <Table
        size="medium"
        columns={columns}
        className="newtable py-4 px-2"
        dataSource={dataSource}
        pagination={false}
      />
    );
  };


  const generateUniqueKeys = (data) => {
    return data.map((item, index) => `${item.APIID}_${index}`);
  };
  
  const defaultColumns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      editable: true,
      width: "20%",
      render : (text) => {
         return( <span >{text}</span>)
      }
    },
    {
      title: "BotID",
      dataIndex: "BotID",
      key: "BotID",
      editable: true,
      width: "20%",
    },
    {
      title: "Symbol",
      dataIndex: "Symbol",
      key: "Symbol",
      editable: true,
      width: "20%",
    },
    {
      title: "Trade_Type",
      dataIndex: "Trade_Type",
      key: "Trade_Type",
      width: "20%",
      editable: true,
    },
    {
      title: "VarBotID",
      dataIndex: "VarBotID",
      key: "VarBotID",
      editable: true,
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      editable: true,
      width: "20%",
      render : (text) => {
        return( <span className={`${text == "ACTIVE"? " text-green-500" : ' text-red-500'}`}>{text}</span>)
     }
    },
  ];

  const allColumns = [...defaultColumns];

  const columns = allColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        width: 10 ,
        handleSave,
      }),
    };
  });

  const handleSave = () => {}

  return (
    <div className='mt-5'>
      <Table dataSource={dataSource} columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: generateUniqueKeys(dataSource),
        }}
      />
    </div>
  )
}

export default ActiveApi
