import { Popconfirm, Switch, message, Drawer } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { Button, Checkbox, Form, Input, Modal } from 'antd';


const Viewpackage = () => {
  const [viewPackage, setviewPackage] = useState([])
  const [open, setOpen] = useState(false);
  const [packedit, setPackedit] = useState()
  const [selectedData, setSelectedData] = useState()
  console.log("selectedData", selectedData)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const getViewPackage = () => {
    const token = localStorage.getItem('token')
    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'view_packages',
      data: { jwt: token, work: "" }
    }).then(res => {
      setviewPackage(res.data.Success)
    })
      .catch(err => { })
  }

  useEffect(() => {
    getViewPackage()
  }, [])

  // const mapCardPara = [
  //   { para: `Activate ${Live_Bots} live bots` },
  //   { para: `Activate ${Paper_Bots} paper bots` },
  //   { para: `Earn ${100 - Profit_Percent}% of the profits` },
  //   { para: ` Test on ${Paper_API} Paper APIs` },
  //   { para: `Rest Assured for 1 ${Validity}.` },
  //   { para: `Connect ${Live_API} Live API` },
  //   { para: `Trade on ${Exchange} Exchange ` },
  //   { para: `Max fund Allotted  $${formattedAllotted}` },
  // ];


  const changeStatus = (item) => {
    const status = item.Status == "ACTIVE"
    const token = localStorage.getItem('token')
    const postData = {
      jwt: token,
      action: !status,
      ids: item.ID
      // ids: "riu"
    }
    const response = axios({
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      url: process.env.REACT_APP_API + "change_pack_status",
      data: postData
    }).then(res => {
      message.success(res.data.Success)
      getViewPackage()
    }).catch(err => {
      message.error(err?.response?.data?.Error)
    })
  }

  const Deletepackage = (item) => {
    const status = item.Status == "ACTIVE"
    const token = localStorage.getItem('token')
    const postData = {
      jwt: token,
      work: item
      // ids: "riu"
    }
    const response = axios({
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      url: process.env.REACT_APP_API + "delete_pack",
      data: postData
    }).then(res => {
      getViewPackage()
      message.success(res.data.Success)
    }).catch(err => {
      // message.error(err?.response?.data?.Error)
    })
  }

  const onFinish = (values) => {
    console.log(values)
    const sanitizedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value || ""])
    );

    console.log("sanitizedValues", sanitizedValues);
  };

  const InputChange = (key, value) => {
    setSelectedData((prevRecord) => ({
      ...prevRecord,
      [key]: value,
    }));
  };

  const handleEditPAckage = () => {
    const token = localStorage.getItem("token")
    const postdata = {
      jwt: token,
      name: selectedData?.Name + "",
      price: selectedData?.Price + "",
      live_api: selectedData?.Live_API + "",
      paper_api: selectedData?.Paper_API + "",
      exchange: selectedData?.Exchange + "",
      live_bots: selectedData?.Live_Bots + "",
      paper_bots: selectedData?.Paper_Bots + "",
      profit_per: selectedData?.Profit_Percent + "",
      max_fund_allotted: selectedData?.Max_Fund_Allotted + "",
      validity: selectedData?.Validity + ""
    }
    console.log( "postdata" , postdata)
    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "edit_pack",
      data: postdata
    }).then(res => {
      console.log(res)
      setOpen(false)
      setIsModalOpen(false)
      message.success(res.data.Success)
      getViewPackage()
    })
      .catch(err => {
        console.log(err)
        message.error(err?.response?.data?.Error)
      })
  }

  return (
    <>
      <div className=' grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 mx-auto'>
        {
          viewPackage.map((item, index) => {
            const formattedAllotted = `${item?.Max_Fund_Allotted.toLocaleString(
              "en-US",
              {
                maximumFractionDigits: 3,
                minimumFractionDigits: 0,
              }
            ).replace(/(\.[0-9]*[1-9])0*$/, "$1")}`;


            const mapCardPara = [
              { para: `Activate ${item?.Live_Bots} live bots` },
              { para: `Activate ${item?.Paper_Bots} paper bots` },
              { para: `Earn ${100 - item?.Profit_Percent}% of the profits` },
              { para: ` Test on ${item?.Paper_API} Paper APIs` },
              { para: `Rest Assured for 1 ${item?.Validity}.` },
              { para: `Connect ${item?.Live_API} Live API` },
              { para: `Trade on ${item?.Exchange} Exchange ` },
              { para: `Max fund Allotted  $${formattedAllotted}` },
            ];

            const packageStatus = item?.Status == "ACTIVE"
              ;
            const cancel = (e) => {
            };
            const confirm = (e) => {

              const token = localStorage.getItem('token')

              const postData = {
                jwt: token,
                work: e
                // ids: "riu"
              }

              const response = axios({
                method: 'POST',
                headers: {
                  "Content-Type": "application/json"
                },
                url: process.env.REACT_APP_API + "delete_pack",
                data: postData
              }).then(res => {
                getViewPackage()
                message.success(res.data.Success)
              }).catch(err => {
                // message.error(err?.response?.data?.Error)
              })
            };

            return (
              <>
                <div key={index} className="md:mt-0 md:mb-3 mb-2 px-2 sm:px-2 lg:px-2 xl:px-0 w-full  ">
                  <section className="text-gray-600 body-font overflow-hidden">
                    <div className="container py-4 px-4 mx-auto">
                      <div className=" flex justify-end "></div>
                      <div className={`-m-4 items-center justify-center `}>

                        <div className="p-4  w-full md:h-2/3  xl:h-1/2  transform  transition-transform duration-300 hover:-translate-y-2">
                          <div className={`h-full p-6 rounded-lg border-2 shadow-neumorphic bg-colorGreyish  flex flex-col relative overflow-hidden shadow-xl `}>
                            <div className=' flex justify-between'>
                              <div className="text-sm  items-center gap-4 tracking-widest title-font mb-1 font-medium flex">
                                {item?.Name}
                                <Popconfirm
                                  className=' text-red-500'
                                  title="Delete the task"
                                  description="Are you sure to delete this task?"
                                  onConfirm={() => confirm(item.ID)}
                                  onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <MdDelete className="cursor-pointer hover:text-red-500 hover:scale-125 transition-all transition-5s" size={23} />
                                </Popconfirm>
                                <BiEdit size={22} className=' cursor-pointer hover:scale-105'
                                  onClick={() => {
                                    setOpen(true)
                                    setSelectedData(item)
                                  }} />
                                <Drawer title="Edit Package"
                                  className='packEdit top-left-shadow '
                                  placement="right"
                                  onClose={() => { setOpen(false) }}
                                  open={open}>
                                  <Form
                                    name="basic"
                                    style={{ maxWidth: 600, }}
                                    initialValues={{
                                      remember: true,
                                    }}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                  >
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>name :</div>
                                      <Input
                                        value={selectedData?.Name}
                                        // onChange={(e) => InputChange("Name", e.target.value)}
                                        placeholder='name' className='focus:border-colorAccent hover:border-colorAccent col-span-1 ' />
                                    </div>
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>price :</div>
                                      <Input
                                        value={selectedData?.Price}
                                        onChange={(e) => InputChange("Price", e.target.value)}
                                        placeholder='price' className='focus:border-colorAccent hover:border-colorAccent' />
                                    </div>
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>live api :</div>
                                      <Input
                                        value={selectedData?.Live_API}
                                        onChange={(e) => InputChange("Live_API", e.target.value)}
                                        placeholder='live api' className='focus:border-colorAccent hover:border-colorAccent' />
                                    </div>
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>paper api :</div>
                                      <Input
                                        value={selectedData?.Paper_API}
                                        onChange={(e) => InputChange("Paper_API", e.target.value)}
                                        placeholder='paper api' className='focus:border-colorAccent hover:border-colorAccent' />
                                    </div>
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>exchange :</div>
                                      <Input
                                        value={selectedData?.Exchange}
                                        onChange={(e) => InputChange("Exchange", e.target.value)}
                                        placeholder='exchange' className='focus:border-colorAccent hover:border-colorAccent' />
                                    </div>
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>live bots :</div>
                                      <Input
                                        value={selectedData?.Live_Bots}
                                        onChange={(e) => InputChange("Live_Bots", e.target.value)}
                                        placeholder='live bots' className='focus:border-colorAccent hover:border-colorAccent' />
                                    </div>
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>paper bots :</div>
                                      <Input
                                        value={selectedData?.Paper_Bots}
                                        onChange={(e) => InputChange("Paper_Bots", e.target.value)}
                                        placeholder='paper bots' className='focus:border-colorAccent hover:border-colorAccent' />
                                    </div>
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>profit per :</div>
                                      <Input
                                        value={selectedData?.Profit_Percent}
                                        onChange={(e) => InputChange("Profit_Percent", e.target.value)}
                                        placeholder='profit per' className='focus:border-colorAccent hover:border-colorAccent' />
                                    </div>
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>max fund allotted :</div>
                                      <Input
                                        value={selectedData?.Max_Fund_Allotted}
                                        onChange={(e) => InputChange("Max_Fund_Allotted", e.target.value)}
                                        placeholder='max fund allotted' className='focus:border-colorAccent hover:border-colorAccent' />
                                    </div>
                                    <div className=' grid grid-cols-2 m-2'>
                                      <div className='col-span-1 capitalize font-medium'>validity :</div>
                                      <Input
                                        value={selectedData?.Validity}
                                        onChange={(e) => InputChange("Validity", e.target.value)}
                                        placeholder='validity' className='focus:border-colorAccent hover:border-colorAccent' />
                                    </div>

                                    <button type="primary" htmlType="submit" onClick={showModal} className=' bg-colorAccent block mx-auto my-6 px-6 py-2 rounded-xl text-white'>
                                      Submit
                                    </button>
                                  </Form>
                                </Drawer>
                                {/* <Modal title="Confirm edit package" open={isModalOpen} onClose={() => console.log("click")} footer={false}>
                                  <p>Are you sure you want to edit package ${selectedData?.Name}?</p>
                                  <div className=' flex justify-end gap-4'>
                                    <button className='top-left-shadow bg-colorAccent py-1 capitalize  mt-6 px-6 rounded-lg text-white' onClick={handleEditPAckage}>edit</button>
                                    <button className='top-left-shadow bg-colorAccent py-1 capitalize mt-6 px-6 rounded-lg text-white' onClick={() => setIsModalOpen(false)}>cancel</button>
                                  </div>
                                </Modal> */}
                                <Modal title="Confirm edit package" open={isModalOpen} onCancel={handleCancel} footer={false}>
                                <p>Are you sure you want to edit package ${selectedData?.Name}?</p>
                                  <div className=' flex justify-end gap-4'>
                                    <button className='top-left-shadow bg-colorAccent py-1 capitalize  mt-6 px-6 rounded-lg text-white' onClick={handleEditPAckage}>edit</button>
                                    <button className='top-left-shadow bg-colorAccent py-1 capitalize mt-6 px-6 rounded-lg text-white' onClick={() => setIsModalOpen(false)}>cancel</button>
                                  </div>
                                </Modal>
                              </div>
                              <p>
                                <div className="flex">
                                  <Switch checkedChildren="ACTIVE" unCheckedChildren="INACTIVE"
                                    checked={packageStatus}
                                    onChange={() => { changeStatus(item) }} className='top-left-shadow bg-red-300 text-black' />

                                  {/* <MdDelete onClick={() => Deletepackage(item.ID)} size={23} /> */}

                                </div>

                              </p>
                            </div>
                            <h1 className="lg:text-4xl text-5xl 2xl:text-5xl text-gray-900 leading-none flex items-center pb-4  ">
                              <span className="text-3xl">$ {item?.Price}</span>
                              <span className="text-lg ml-1 font-normal text-gray-500">
                                /{item?.Validity}
                              </span>
                            </h1>
                            <div
                              className={`w-full h-[2px] mb-2 ${true ? "bg-colorSecondaryDark/30 " : "bg-colorAccent/30 "}`}
                            ></div>
                            {
                              mapCardPara.map((subitem, index) => {
                                return (
                                  <>
                                    <p className="flex items-center text-md xl:text-md text-black my-1 " key={index}>
                                      <span className="w-4 h-4 mr-2 inline-flex items-center  justify-center bg-green-700 text-white rounded-full flex-shrink-0">
                                        <svg
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2.5"
                                          className="w-3 h-3"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                      </span>
                                      {subitem?.para}
                                    </p>
                                  </>
                                )
                              })
                            }
                            {/* <button >

                            </button> */}

                            {/* <div className=" flex gap-4 mt-auto">
                              <button
                                className={`flex items-center shadow-neumorphic mt-4 justify-center text-white  border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded-full
                       ${(true
                                    ? "bg-colorSecondaryDark "
                                    : "bg-colorAccent cursor-pointer") +
                                  (true
                                    ? "opacity-0 cursor-not-allowed "
                                    : "cursor-not-allowed")
                                  }`}
                              >
                                BUY
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </>
            )
          })
        }
      </div>
    </>
  )
}

export default Viewpackage
