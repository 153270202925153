import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table } from 'antd'

const SearchDeposit = ({ username }) => {
    const [depositData, setDepositData] = useState([])

    const getDeposite = () => {
        const token = localStorage.getItem('token')
        const postData = {
            jwt: token,
            username: username,
            by: "",
            status: ""
        }
        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'user_deposit',
            data: postData
        }).then(res => {
            const responseData = res?.data?.Success
            setDepositData(responseData)
        })
            .catch(err => {})
    }

    useEffect(() => {
        getDeposite()
    }, [username])

    const dataSource = []

    if (depositData) {
        for (let i = 0; i < depositData.length; i++) {
            const formattedWalletAddress = `${depositData[i]?.Receiver_Wallet_Address.substring(
                0,
                6
              )}...${depositData[i]?.Receiver_Wallet_Address.slice(-8)}`;
            dataSource.push({
                DateTime : depositData[i]?.DateTime,
                Receiver_Wallet_Address : formattedWalletAddress,
                TransID : depositData[i]?.TransID,
                Asset : depositData[i]?.Asset,
                Price : depositData[i]?.Price,
                Status : depositData[i]?.Status,
                Amount : depositData[i]?.Amount?.USDT,
                By : depositData[i]?.By,
            })
        }
    }

    const revDataSource = [...dataSource].reverse()

    const columns = [
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>DateTime</p>,
            dataIndex: 'DateTime',
            key: 'DateTime',
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Wallet Address</p>,
            dataIndex: 'Receiver_Wallet_Address',
            key: 'Receiver_Wallet_Address',
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>TransID</p>,
            dataIndex: 'TransID',
            key: 'TransID',
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Asset</p>,
            dataIndex: 'Asset',
            key: 'Asset',
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Price</p>,
            dataIndex: 'Price',
            key: 'Price',
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Status</p>,
            dataIndex: 'Status',
            key: 'Status',
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>Amount</p>,
            dataIndex: 'Amount',
            key: 'Amount',
        },
        {
            title: <p className='p-1 text-center rounded-lg table-cell'>By</p>,
            dataIndex: 'By',
            key: 'By',
        },
    ]

    return (
        <div>
            <Table columns={columns} dataSource={revDataSource}
             scroll={{
                x: 1000,
              }}
            />
        </div>
    )
}

export default SearchDeposit
