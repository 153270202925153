import React from 'react'
import { Form, Input, message } from 'antd'
import axios from 'axios'

const Createuser = () => {

    const createUser = (value) => {
        const token = localStorage.getItem('token')
        const postData = {
            ...value,
            totp: value.totp == undefined ? "" : value.totp,
            jwt: token
        }


        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + 'add_user',
            data: postData
        }).then((res) => {
            message.success(res?.data?.Success)
            //   ViewUser()
        }).catch(err => {
            message.error(err?.response?.data?.Error)
        })
    }



    return (
        <div className='  mx-auto  mt-5  rounded-xl'>
            <Form
                className="mt-4 m-auto  px-4 rounded-xl grid grid-cols-2 gap-x-8"
                name="basic"
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                onFinish={createUser}
            >
                {/* <div className=" text-xl font-semibold text-center  py-4 -mx-4 mb-7 bg-colorSecondaryDark/90 text-white">Create user</div> */}
                <div className=' col-span-1'>
                    <p className='  text-lg  ml-2 mb-3 font-semibold'>Username</p>
                    <Form.Item
                        // label="Username"
                        name="username"
                        labelAlign="left"
                        rules={[
                            {
                                message: "Please input username",
                                required: true
                            },
                        ]}
                    >
                        <Input placeholder="Username" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-black rounded-xl py-3 pl-5 text-base'  />
                    </Form.Item>
                </div>
                <div className=' col-span-1'>
                    <p className='text-lg  ml-2 mb-3 font-semibold'>Password</p>
                    <Form.Item
                        // label="Password"
                        name='password'
                        labelAlign="left"
                        rules={[
                            {
                                message: "Please input password",
                                required: true
                            },
                        ]}
                    >
                        <Input placeholder="Password" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-black rounded-xl py-3 pl-5 text-base'   />
                    </Form.Item>
                </div>

                <div className=' col-span-1'>
                <p className='text-lg  ml-2 mb-3 font-semibold'>Name</p>
                    <Form.Item
                        // label="Name"
                        name='name'
                        labelAlign="left"
                        rules={[
                            {
                                message: "Please input name",
                                required: true
                            },
                        ]}
                    >
                        <Input placeholder="Name" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-black rounded-xl py-3 pl-5 text-base'    />
                    </Form.Item>
                </div>

                <div className=' col-span-1'>
                <p className='text-lg  ml-2 mb-3 font-semibold'>Email</p>
                    <Form.Item
                        // label="Email"
                        name='email'
                        labelAlign="left"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                message: "Please input email",
                                required: true
                            },
                        ]}
                    >
                        <Input placeholder="Email" className=' focus:border-colorAccent hover:border-colorAccent top-left-shadow bg-colorGreyish placeholder:text-black rounded-xl py-3 pl-5 text-base'  />
                    </Form.Item>
                </div>

                {/* <div className=' col-span-1'>
                    <Form.Item
                        label="2FA"
                        name='totp'
                        labelAlign="left"
                        rules={[
                            {
                                message: "Please input 2FA",
                                required: true
                            },
                        ]}
                    >
                        <Input placeholder="2FA" />
                    </Form.Item>
                </div> */}

                <div className=' col-span-2'>
                    <Form.Item className=" flex justify-center m-0">
                        <button type="primary" htmlType="submit" className=" bg-[#76c3c5] top-left-shadow text-lg py-3 px-16 rounded-lg m-9 text-white block">
                            Submit
                        </button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}

export default Createuser
