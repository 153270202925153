import { Button, Popconfirm, Switch, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { MdOutlineRestore } from "react-icons/md";

const DeletePackage = () => {


  const [viewPackage, setviewPackage] = useState([])

  const getViewPackage = () => {
    const token = localStorage.getItem('token')
    const response = axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'view_packages',
      data: { jwt: token, work: "delete" }
    }).then(res => {
      setviewPackage(res.data.Success)
    })
      .catch(err => {})
  }

  useEffect(() => {
    getViewPackage()
  }, [])

  // const mapCardPara = [
  //   { para: `Activate ${Live_Bots} live bots` },
  //   { para: `Activate ${Paper_Bots} paper bots` },
  //   { para: `Earn ${100 - Profit_Percent}% of the profits` },
  //   { para: ` Test on ${Paper_API} Paper APIs` },
  //   { para: `Rest Assured for 1 ${Validity}.` },
  //   { para: `Connect ${Live_API} Live API` },
  //   { para: `Trade on ${Exchange} Exchange ` },
  //   { para: `Max fund Allotted  $${formattedAllotted}` },
  // ];


  const changeStatus = (item) => {

    const status = item.Status == "ACTIVE"
    const token = localStorage.getItem('token')

    const postData = {
      jwt: token,
      action: !status,
      ids: item.ID
      // ids: "riu"
    }


    const response = axios({
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      url: process.env.REACT_APP_API + "change_pack_status",
      data: postData
    }).then(res => {
      message.success(res.data.Success)
      getViewPackage()
    }).catch(err => {
      message.error(err?.response?.data?.Error)
    })
  }
  
  const confirm = (e) => {

    const token = localStorage.getItem('token')

    const postData = {
      jwt: token,
      work: e
    }

    const response = axios({
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      url: process.env.REACT_APP_API + "restore_pack",
      data: postData
    }).then(res => {
      message.success(res.data.Success)
      getViewPackage()

    }).catch(err => {
      message.error(err?.response?.data?.Error)
    })


  };
  const cancel = (e) => {
  };
  return (

    <>
      <div className=' grid grid-col-1 sm:grid-cols-2 xl:grid-cols-4 w-[90%] mx-auto'>
        {
          viewPackage.map((item, index) => {
            const formattedAllotted = `${item?.Max_Fund_Allotted.toLocaleString(
              "en-US",
              {
                maximumFractionDigits: 3,
                minimumFractionDigits: 0,
              }
            ).replace(/(\.[0-9]*[1-9])0*$/, "$1")}`;


            const mapCardPara = [
              { para: `Activate ${item?.Live_Bots} live bots` },
              { para: `Activate ${item?.Paper_Bots} paper bots` },
              { para: `Earn ${100 - item?.Profit_Percent}% of the profits` },
              { para: ` Test on ${item?.Paper_API} Paper APIs` },
              { para: `Rest Assured for 1 ${item?.Validity}.` },
              { para: `Connect ${item?.Live_API} Live API` },
              { para: `Trade on ${item?.Exchange} Exchange ` },
              { para: `Max fund Allotted  $${formattedAllotted}` },
            ];

            const packageStatus = item?.Status == "ACTIVE"

            return (
              <>
                <div key={index} className="md:mt-0 md:mb-3 mt-3  mb-7 px-2 sm:px-2 lg:px-2 xl:px-0 w-full  ">
                  <section className="text-gray-600 body-font overflow-hidden">
                    <div className="container py-4 px-4 mx-auto">
                      <div className=" flex justify-end "></div>
                      <div className={`-m-4 items-center justify-center `}>

                        <div className="p-4  w-full md:h-2/3  xl:h-1/2  transform  transition-transform duration-300 hover:-translate-y-2">
                          <div
                            // key={_id}
                            className={`h-full p-6 rounded-lg border-2 shadow-neumorphic bg-colorGreyish  flex flex-col relative overflow-hidden shadow-xl `}
                          >
                            <div className=' flex justify-between'>
                              <h2 className="text-xl tracking-widest title-font mb-1 font-medium">
                                {item?.Name}
                              </h2>
                              <p>
                                <div className="flex">
                                  <Popconfirm
                                    title="Delete the task"
                                    description="Are you sure to delete this task?"
                                    onConfirm={()=>confirm(item.ID)}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                   <MdOutlineRestore
                                      size={23} />
                                  </Popconfirm>
                                  {/* <Button onClick={() => Restore(item.ID)}  >
                                    <MdOutlineRestore
                                      size={23} />
                                  </Button> */}


                                </div>

                              </p>
                            </div>
                            <h1 className="lg:text-4xl text-5xl 2xl:text-5xl text-gray-900 leading-none flex items-center pb-4  ">
                              <span className="text-3xl">$ {item?.Price}</span>
                              <span className="text-lg ml-1 font-normal text-gray-500">
                                /{item?.Validity}
                              </span>
                            </h1>
                            <div
                              className={`w-full h-[2px] mb-2 ${true ? "bg-colorSecondaryDark/30 " : "bg-colorAccent/30 "}`}
                            ></div>
                            {
                              mapCardPara.map((subitem, index) => {
                                return (
                                  <>
                                    <p className="flex items-center text-sm xl:text-md text-gray-600 my-1 " key={index}>
                                      <span className="w-4 h-4 mr-2 inline-flex items-center  justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                        <svg
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2.5"
                                          className="w-3 h-3"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                      </span>
                                      {subitem?.para}
                                    </p>
                                  </>
                                )
                              })
                            }


                            {/* <button >

                            </button> */}

                            {/* <div className=" flex gap-4 mt-auto">
                              <button
                                className={`flex items-center shadow-neumorphic mt-4 justify-center text-white  border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded-full
                       ${(true
                                    ? "bg-colorSecondaryDark "
                                    : "bg-colorAccent cursor-pointer") +
                                  (true
                                    ? "opacity-0 cursor-not-allowed "
                                    : "cursor-not-allowed")
                                  }`}
                              >
                                BUY
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </>
            )
          })
        }
      </div>
    </>
  )
}

export default DeletePackage
