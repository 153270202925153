import React, { useState } from 'react'
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';

function Dashboard() {
  const [isDrawerOpen, setDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <div className=" min-h-screen h-auto bg-colorGreyish">
        <Navbar updated={toggleDrawer} initial={isDrawerOpen} />
        <div
          className={`overflow-auto  md:mr-3  bg-colorGreyish  ${isDrawerOpen ? "md:ml-56" : "md:ml-24"}`}>
          <Sidebar className="mt-24 pt-24 " isDrawerOpen={isDrawerOpen} onDrawerClose={toggleDrawer} />
          <div className="">
            <Outlet className="bottom-12" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;