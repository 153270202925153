import React, { useEffect, useRef, useState } from 'react'
import { Select, Spin } from 'antd'
import axios from 'axios';
import { FaCircle } from "react-icons/fa6";
import { TfiReload } from "react-icons/tfi";
import Papa from 'papaparse'
import { saveAs } from 'file-saver';

const { Option } = Select


const Apioverview = (props) => {
    const { username, option, newOption } = props?.data
    const [option2, setOption2] = useState(option)
    const [Api, setApi] = useState(option[0]?.label)
    const [showOptions, setShowOptions] = useState(false);
    const [clsPostion, setClsPostion] = useState([])
    const [loading, setLoading] = useState(false);
    const [slectedSymbol, setSelectedSymbol] = useState('')
    const [seclSym, setSeclSym] = useState([])
    const [visbleItem, setVisbleItem] = useState(8)

    const showMore = () => {
        setVisbleItem((prevItem) => prevItem + 8)
    }

    console.log("clsPostion", clsPostion)

    const dropdownRef = useRef(null);

    useEffect(() => {
        setClsPostion([])
        setOption2(option)
        const fisrtLabel = option[0]?.label
        setApi(fisrtLabel)
    }, [option])

    const symbol = ['ADA', 'BNB', 'BTC', 'DOGE', 'ETH', 'GALA', 'MANA', 'OCEAN', 'SOL', 'XRP']
    const [selectedSymbols, setSelectedSymbols] = useState(symbol);

    const handleChange = (value) => {
        setApi(value)
    }

    const closePosition = () => {
        setVisbleItem(8)
        setClsPostion([])
        setLoading(true)
        setShowOptions(false)
        const token = localStorage.getItem('token');
        const postData = {
            jwt: token,
            username: username,
            api_name: newOption,
            symbol: [slectedSymbol.replace('USDT', '')]
        }
        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + "per_api_details",
            data: postData
        }).then(res => {
            setClsPostion(res?.data?.Success)
            // setClsPostion(res?.data?.Success.reverse())
            setLoading(false)
        }).catch(err => {
            console.log("err", err)
            setLoading(false)
        })
    }

    useEffect(() => {
        const symbol = slectedSymbol?.length !== 0
        if(symbol){
            closePosition()
        }
    }, [newOption, slectedSymbol])

    const getSymbol = () => {
        const token = localStorage.getItem('token')
        const postData = {
            jwt: token,
            username: username,
            api_name: newOption
        }

        const response = axios({
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + "closed_orders_drop_down",
            data: postData
        }).then(res => {
            const responseData = res?.data?.Success
            setSeclSym(responseData)
            setSelectedSymbol(responseData[0])
        })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getSymbol()
    }, [newOption])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSymbolFilter = (symbol) => {
        const updatedSelectedSymbols = [...selectedSymbols];

        if (updatedSelectedSymbols.includes(symbol)) {
            updatedSelectedSymbols.splice(updatedSelectedSymbols.indexOf(symbol), 1);
        } else {
            updatedSelectedSymbols.push(symbol);
        }
        setSelectedSymbols(updatedSelectedSymbols);
    };

    const handleDownload = () => {
        const csv = Papa.unparse(clsPostion.Data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'closePosition.csv');
    };

    return (
        <div>
            <div className=' flex gap-4 mb-4'>

                {/* <div>
                    <span className=' text-base font-semibold'>API Name : </span>
                    <Select
                        value={Api}
                        className='focus:border-colorAccent hover:border-colorAccent'
                        style={{
                            width: 180,
                        }}
                        placeholder="Select API Name"
                        onChange={handleChange}
                        options={option2}
                    />
                </div> */}

                {/* <div ref={dropdownRef} className="flex flex-col items-center  lg:space-x-4 ">
                    <div className="">
                        <div className=' flex gap-2 items-center'>
                            <span className='text-base font-semibold'>Symbols :</span>
                            <div className="relative">
                                <button
                                    id="selectedOption"
                                    onClick={() => setShowOptions(!showOptions)}
                                    className="text-left flex justify-between items-center px-3 bg-white text-[#d0d0d0] border-[#d9d9d9] border w-[200px] text-sm   py-[5px] rounded-md"
                                >
                                    Select Symbols <span className=' text-xl'>{showOptions ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</span>
                                </button>

                                {showOptions && (
                                    <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                                        {symbol?.map((option, index) => (
                                            <div key={index}>
                                                <label className="block px-2 py-1 text-sm">
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => handleSymbolFilter(option)}
                                                        checked={selectedSymbols.includes(option)}
                                                        className="mr-2"
                                                    />
                                                    {option}
                                                </label>
                                            </div>
                                        ))}
                                        <button onClick={closePosition} className=' block mx-auto menu-item font-semibold rounded-2xl mb-4 bg-colorAccent py-2 top-left-shadow w-[50%]'>submit</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className=' flex justify-between md:items-center flex-col md:flex-row'>

                <div className='flex gap-4'>
                    <Select className='outer_shadow m-1 col-span-2 w-36' value={slectedSymbol} onChange={(value) => setSelectedSymbol(value)}>
                        {
                            seclSym.map(item => {
                                return (
                                    <Option value={item}>{item}</Option>
                                )
                            })
                        }
                    </Select>
                    <button className={`${clsPostion.length === 0 ? "cursor-not-allowed" : "cursor-pointer"} font-semibold`} onClick={handleDownload} disabled={clsPostion.length === 0}>
                        Export to CSV
                    </button>
                </div>
                <div>
                    {clsPostion?.Total_count && (
                        <div className=' font-bold text-sm md:text-base border border-black px-2 flex gap-4'>
                            <span>Total count : {clsPostion?.Total_count.toFixed(2)}</span>
                            <span>Positive Pnl : <span className=' text-green-500'>{clsPostion?.Positive_Pnl}(${clsPostion?.Positive.toFixed(2)})</span> </span>
                            <span>Negative Pnl : <span className=' text-red-500'>{clsPostion?.Negative_Pnl}(${clsPostion?.Negative.toFixed(2)})</span> </span>
                        </div>
                    )
                    }
                </div>
                <div>
                    {clsPostion?.Total_pnl && (
                        <div className=' font-bold text-sm md:text-base border border-black px-2'>
                            <span className=' mr-4'>Total Pnl: <span className={`${clsPostion?.Total_pnl > 0 ? " text-green-500" : " text-red-50"}`}>{clsPostion?.Total_pnl.toFixed(2)}     </span></span>
                            <span>From : {clsPostion?.From} To : {clsPostion?.To}</span>
                        </div>
                    )
                    }
                </div>
            </div>

            <div>
                <div className='my-3 flex flex-col md:flex-row md:items-center gap-2 px-2'>
                    <p>Data may be displayed with a delay of 1-2 minutes, please click Refresh to update the data </p>
                    <button className=' flex items-center gap-2 text-colorSecondaryDark font-medium' onClick={closePosition}><TfiReload /> Refresh</button>
                </div>
            </div>

            <div className='font-semibold pt-4'>
                {
                    loading ?
                        <div className=' '>
                            <Spin className='mx-auto block' />
                        </div>
                        :
                        clsPostion?.Data?.slice(0, visbleItem).map(item => {
                            return (
                                <div className=' px-2'>
                                    <div className='flex items-center gap-2'>
                                        <div className='flex items-center gap-2 text-sm'>
                                            <span className='text-colorSecondaryDark'>{item?.symbol}</span>
                                            <span className={`${item.direction == "SHORT" ? " text-red-500" : " text-green-500"}`}>{item.direction}</span>
                                        </div>
                                        <span className='flex items-center gap-1 px-1 border-l-2 border-r-2 text-colorSecondaryDark border-colorAccent text-sm h-4'><FaCircle size={9} /><span className=' leading-3'> Closed</span></span>
                                    </div>
                                    <div className=' grid grid-cols-2 md:grid-cols-4 mt-2 gap-1 md:gap-0'>
                                        <div className=' text-xs md:text-sm mb-1'><span className=' mr-2'>Opened</span>
                                            <span>
                                                {/* {new Date(item?.open_time).toLocaleDateString('en-GB') + ' ' + new Date(1701540808180).toLocaleTimeString()} */}
                                                {new Date(item?.open_time).toLocaleDateString('en-GB') + ' ' + new Date(item?.open_time).toLocaleTimeString()}
                                            </span>
                                        </div>
                                        <div className=' text-xs md:text-sm mb-1'><span className=' mr-2'>Entry Price</span> <span>{item.weighted_average.toFixed(2)}</span></div>
                                        <div className=' text-xs md:text-sm mb-1'><span className=' mr-2'>Max Open Interest</span> <span>{item.max_quantity.toFixed(2)}</span></div>
                                        <div className=' text-xs md:text-sm mb-1'><span className=' mr-2'>Closing PNL</span>
                                            <span className={`${item.pnl < 0 ? " text-red-500" : " text-green-500"}`}>{item.pnl.toFixed(2)}</span></div>
                                        <div className=' text-xs md:text-sm mb-1'><span className=' mr-2'>Closed</span>
                                            <span>
                                                {/* {new Date(item?.time).toLocaleDateString('en-GB') + ' ' + new Date(1701540808180).toLocaleTimeString()} */}
                                                {new Date(item?.time).toLocaleDateString('en-GB') + ' ' + new Date(item?.time).toLocaleTimeString()}
                                            </span>
                                        </div>
                                        <div className=' text-xs md:text-sm mb-1'><span className=' mr-2'>Avg. Close Price</span> <span>{item.price.toFixed(2)}</span></div>
                                        <div className=' text-xs md:text-sm mb-1'><span className=' mr-2'>Closed Vol.</span> <span>{item.quantity.toFixed(2)}</span></div>
                                    </div>
                                    <hr className=' my-3 border border-colorAccent' />
                                </div>
                            )
                        })
                }
                {clsPostion?.Data?.length > visbleItem && (
                    <div className=' mt-4 mb-8'>
                        <button
                            onClick={showMore}
                            className=" bg-colorAccent text-white px-4 py-2 rounded mt-4  block mx-auto"
                        >
                            Show More
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Apioverview
