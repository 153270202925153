import { Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function Packagetable({ username }) {
  const [subdata, setSubData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const handlepackage = (page, size) => {
    const token = localStorage.getItem('token');
    const postData = {
      jwt: token,
      username: username,
      page_number: page,
      number_of_data: size,
    };

    axios({
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + 'user_package',
      // url: 'http://192.168.0.248:8000/admin/user_package',
      data: postData
    }).then(res => {
      setSubData(res?.data?.Success);
      setTotalDataCount(res?.data?.TotalPages * size);
    }).catch(err => {
    });
  };

  useEffect(() => {
    handlepackage(currentPage, pageSize);
  }, [currentPage, pageSize, username]);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setCurrentPage(current);
    setPageSize(pageSize);
  };

      const subColumns = [
            {
                title: <p className='p-1 text-center rounded-lg table-cell'>DateTime</p>,
                dataIndex: 'DateTime',
                key: 'DateTime',
                width: '20%'
            },
            {
                title: <p className='p-1 text-center rounded-lg table-cell'>Price</p>,
                dataIndex: 'Price',
                key: 'Price',
                width: '20%'
            },
            {
                title: <p className='p-1 text-center rounded-lg table-cell'>Status</p>,
                dataIndex: 'Status',
                key: 'Status',
                width: '20%'
            },
            {
                title: <p className='p-1 text-center rounded-lg table-cell'>Subscription_Name</p>,
                dataIndex: 'Subscription_Name',
                key: 'Subscription_Name',
                width: '20%'
            },
            {
                title: <p className='p-1 text-center rounded-lg table-cell'>TransID</p>,
                dataIndex: 'TransID',
                key: 'TransID',
                width: '20%'
            },
    
        ]
    
        const subDatasou = []
        if (subdata?.length > 0) {
            for (let i = 0; i < subdata.length; i++) {
                subDatasou.push({
                    DateTime: <p className='p-1 rounded-lg text-center'>{subdata[i].DateTime}</p>,
                    Price: <p className='p-1 rounded-lg text-center'>{subdata[i].Price}</p>,
                    Status: <p className='p-1 rounded-lg text-center'>{subdata[i].Status}</p>,
                    Subscription_Name: <p className='p-1 rounded-lg text-center'>{subdata[i].Subscription_Name}</p>,
                    TransID: <p className='p-1 rounded-lg text-center'>{subdata[i].TransID}</p>,
                })
            }
        }

  return (
    <div>
       <Table
        columns={subColumns}
        dataSource={subDatasou}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalDataCount,
          showSizeChanger: true,
          pageSizeOptions: [],
          // showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items | Page ${Math.ceil(range[1] / pageSize)}`,
        }}
        onChange={handleTableChange}
        size='small'
        bordered
        scroll={{
          x: 1000,
        }}
      />     
    
    </div>
  );
}

export default Packagetable;

