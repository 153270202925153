import { Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const ActiveApi = () => {
  const [dataSource, setDataSource] = useState([])

  // const dataSource = []

  const getApi = () => {
    const token = localStorage.getItem('token')
    const response = axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      url: process.env.REACT_APP_API + 'view_all_apis',
      data: { jwt: token }
    }).then((res) => {
      const successData = res?.data?.Success
      const updatedData = successData.map((item, index) => ({
        ...item,
        isActive: item.Status === "ACTIVE",
        key: `${item.APIID}_${index}`,
      }))
      setDataSource(updatedData)
    }).catch((err) => {
    })
  }

  useEffect(() => {
    getApi()
  }, [])


  const expandedRowRender = (record) => {
    const renderNestedTable = (data) => {
      const columns = [
        {
          title: "Asset",
          dataIndex: "asset",
          key: "asset",
        },
        ...Object.keys(data).map((key) => ({
          title: key,
          dataIndex: key,
          key: key,
        })),
      ];

      const dataSource = [
        {
          key: "data",
          asset: "Data",
          ...data,
        },
      ];

      return <Table size="medium" columns={columns} dataSource={dataSource} pagination={false} />;
    };

    const columns = [
      {
        title: "Asset",
        dataIndex: "asset",
        key: "asset",
      },
      ...Object.keys(record.Initial).map((asset) => ({
        title: asset,
        dataIndex: asset,
        key: asset,
      })),
    ];



    const dataSource = [
      {
        key: "Initial",
        asset: "Initial",
        ...record.Initial,
      },
      {
        key: "Invested",
        asset: "Invested",
        ...record.Invested,
      },
      {
        key: "Balance",
        asset: "Balance",
        ...record.Balance,
      },
    ];


    return (
      <Table
        size="medium"
        columns={columns}
        className="newtable py-4 px-2"
        dataSource={dataSource}
        pagination={false}
      />
    );
  };


  const generateUniqueKeys = (data) => {
    return data.map((item, index) => `${item.APIID}_${index}`);
  };
  
  const defaultColumns = [
    {
      title: "Username",
      dataIndex: "Username",
      key: "Username",
      editable: true,
      width: "20%",
      render : (text) => {
         return( <span>{text}</span>)
      }
    },
    {
      title: "API ID",
      dataIndex: "APIID",
      key: "APIID",
      editable: true,
      width: "20%",
    },
    {
      title: "Exchange",
      dataIndex: "Exchange",
      key: "Exchange",
      editable: true,
      width: "20%",
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      width: "10%",
      editable: true,
    },
    {
      title: "Exchange Type",
      dataIndex: "Exchange_Type",
      key: "Exchange_Type",
      editable: true,
      width: "20%",
    },
    {
      title: "API Name",
      dataIndex: "API_Name",
      key: "API_Name",
      editable: true,
      width: "20%",
    },

    // {
    //   title: "Key",
    //   dataIndex: "key",
    //   key: "key",
    //   editable: true,
    //   width: "15%",
    //   render: (text) => (
    //     <span>
    //       {text?.length > 10
    //         ? `${text.slice(0, 4)}......${text.slice(-6)}`
    //         : text}
    //     </span>
    //   ),
    // },

    // {
    //   title: "Status",
    //   dataIndex: "Status",
    //   key: "Status",
    //   width: "10%",
    //   editable: true,
    //   className: "w-7",

    //   render: (text, record) => (
    //     <>
    //       <div className="justify-center items-center ">
    //         <div>
    //           <Switch
    //             checkedChildren="START"
    //             unCheckedChildren="PAUSE"
    //             className={record.Status === "ACTIVE" ? "off" : "on"}
    //             checked={record.Status === "ACTIVE"}
    //             // checked={record.Status === "ACTIVE" ? true : false}
    //             onChange={(checked) => onswitchChange(record, checked)}
    //           />
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    // {
    //   title: "Action",
    //   width: "10%",
    //   key: "action",
    //   render: (_, record) => (
    //     <>
    //       <Button
    //         className="border-none mb-0"
    //         onClick={() => handleDelete(record)}
    //       >
    //         <DeleteOutlined size={18} />
    //       </Button>
    //     </>
    //   ),
    // },
  ];

  const allColumns = [...defaultColumns];

  const columns = allColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        width: 10 ,
        handleSave,
      }),
    };
  });

  const handleSave = () => {

  }

  return (
    <div className='mt-5'>
      <Table dataSource={dataSource} columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: generateUniqueKeys(dataSource),
        }}
      />
    </div>
  )
}

export default ActiveApi
